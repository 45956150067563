import { MenuItem, Select, SelectChangeEvent, Tooltip } from '@mui/material';
import Icon from 'src/modules/General/components/Icon';
import useEmailsRightColumn, { Email } from 'src/pages/Emails/RightColumn/index.services';
import useEmailsLeftColumn from 'src/pages/Emails/LeftColumn/index.services';
import EmailEmpty from '../EmailEmpty';
import { useEmailsContext } from 'src/core/contexts/emails.context';
import Loading from 'src/modules/General/components/Loading';
import { useTranslation } from 'react-i18next';

const EmailsInbox = (props: { emails: Email[]; onEmailContentClick: (email: Email) => void }) => {
  const { nextPageAction } = useEmailsRightColumn();
  const { emailAddress } = useEmailsLeftColumn();
  const { state } = useEmailsContext();
  const { nextPage, count, loadingNextPage } = state || {};
  const { t } = useTranslation();
  return (
    <div className="w-full h-full bg-gray-200">
      {props.emails.length ? (
        <div className="h-full bg-gray-200 overflow-y-auto ">
          <div className="h-calc-70 mb-2.5  overflow-y-auto bg-gray-200 flex items-center justify-start gap-y-4 flex-col w-full">
            {props.emails.map((email) => (
              <div
                key={email.id}
                className="cursor-pointer w-full h-72 px-4 py-6 bg-white rounded-2xl border border-gray-200 flex-row justify-start items-center gap-4 inline-flex max-sm:p-2"
                onClick={() => props.onEmailContentClick(email)}>
                <div className="w-6/12  truncate max-sm:w-7/12 text-neutral-950 text-lg font-medium max-md:text-sm max-sm:text-xs">
                  {email.sender}
                </div>
                <div className="w-6/12 truncate max-sm:w-4/12 text-zinc-600 text-base font-medium max-md:text-sm max-sm:text-xs">
                  {email.subject}
                </div>
                <Tooltip title={email.attachment || 'Empty Attachment'}>
                  <div className="w-1/12 flex items-center justify-center">
                    {!!email.attachment && <Icon name="attachment-dark" />}
                  </div>
                </Tooltip>
              </div>
            ))}
          </div>

          <div className="w-full h-15 flex gap-6 items-center justify-end flex-row p-1.25  rounded-lg">
            <p>
              {props.emails.length} Of {count}
            </p>
            <button
              disabled={!nextPage || loadingNextPage}
              onClick={() => nextPageAction(emailAddress)}
              className="h-11 px-4 py-3 bg-blue-600 rounded-xl shadow justify-center items-center gap-2 inline-flex text-white text-base font-medium font-['Inter'] capitalize leading-tight disabled:opacity-50 disabled:cursor-not-allowed">
              {loadingNextPage && <Loading variant="circle" color="inherit" size={20} />}
              {t('Next Page')}
            </button>
          </div>
        </div>
      ) : (
        <EmailEmpty />
      )}
    </div>
  );
};
export default EmailsInbox;

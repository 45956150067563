import { combineReducers, configureStore, EnhancedStore } from '@reduxjs/toolkit';
import errorReducer from 'src/modules/General/store/error-slice';

const rootReducer = combineReducers({
  error: errorReducer,
});

export default function createStore(): EnhancedStore<RootState> {
  const store = configureStore({ reducer: rootReducer });
  return store;
}

export type RootState = ReturnType<typeof rootReducer>;

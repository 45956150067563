import { InputProps } from './index.types';
import EyeIcon from 'src/assets/images/Auth/eye.svg';
import EyeOffIcon from 'src/assets/images/Auth/eye-off.svg';

const Input = (props: InputProps) => {
  return (
    <div className={`w-full flex flex-col gap-y-6px ${props.containerStyle}`}>
      {props.title && (
        <label htmlFor={props.htmlFor} className={`text-neutral-950 text-sm font-normal ${props.labelStyle}`}>
          {props.title}
        </label>
      )}

      <input
        disabled={props?.disable}
        id={props.id}
        type={props.type}
        onChange={props.onChange ?? props.onChangeInput}
        onKeyDown={props.handleKeyDown}
        value={props.value}
        className={` h-10 px-3.5 py-2.5 bg-white  shadow border border-gray-200 text-base font-normal leading-tight text-black ${props.inputStyle}`}
        placeholder={props.placeholder}
      />
      {props.icon && (
        <button onClick={props.onIconClick} className={`absolute top-2.5 ${props.positionIcon + '-4'}`}>
          {props.icon}
        </button>
      )}
      {props.eyeIcon && (
        <button onClick={props.handleTogglePassword} className="absolute top-38 right-3">
          <img className="w-4 h-4" src={props.type === 'password' ? EyeOffIcon : EyeIcon} alt="Eye" />
        </button>
      )}
    </div>
  );
};
export default Input;

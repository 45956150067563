import Dropdown from 'src/modules/General/components/DropDown';
import NotificationIcon from 'src/assets/images/SideBar/notification.svg';
import Logo from 'src/modules/General/components/Logo';
import { useNavigate } from 'react-router-dom';
const Header = () => {
  const navigate = useNavigate();
  return (
    <div className="w-full h-14 hidden items-center justify-between  shadow-bottom bg-white max-lg:flex  p-2">
      <Logo />
      <div className="flex items-center justify-normal flex-row gap-x-1">
        <Dropdown />
        <button className="border-none bg-transparent" onClick={() => navigate('/my-notifications')}>
          <img src={NotificationIcon} />
        </button>
      </div>
    </div>
  );
};
export default Header;

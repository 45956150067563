import { useNavigate, useParams } from 'react-router-dom';
import List from 'src/modules/General/containers/List';
import { MockWorkspaceList } from './constant';

const LeftColumn: React.FC = () => {
  const navigate = useNavigate();
  const { id } = useParams() || {};

  return (
    <div className="flex justify-normal items-start flex-col gap-4">
      <h3>My Files</h3>
      {/* <List list={MockWorkspaceList} onSelectItem={(id) => navigate(String(id))} selectedItem={id as string} /> */}
    </div>
  );
};

export default LeftColumn;

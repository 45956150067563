import useEmailsRightColumn from './index.services';
import EmailContent from 'src/modules/Emails/containers/EmailContent';
import EmailsInbox from 'src/modules/Emails/containers/EmailsInbox';
import EmailContentMobile from 'src/modules/Emails/containers/EmailContent/mobile';
import Loading from 'src/modules/General/components/Loading';

const LeftColumn: React.FC = () => {
  const { emails, showContent, selectedEmail, onEmailContentClick, onBackClick, loading } = useEmailsRightColumn();

  return (
    <>
      <div className="h-full bg-gray-200 max-sm:p-0">
        {loading ? (
          <div className="w-full h-full flex flex-col items-center justify-center">
            <Loading variant="circle" color="primary" size={44} />
          </div>
        ) : showContent && selectedEmail && !loading ? (
          <div className="h-full">
            <div className="max-sm:hidden h-full">
              <EmailContent email={selectedEmail} onBackClick={onBackClick} />
            </div>
            <div className="hidden h-full overflow-y-auto max-sm:block">
              <EmailContentMobile email={selectedEmail} onBackClick={onBackClick} />
            </div>
          </div>
        ) : (
          <EmailsInbox emails={emails} onEmailContentClick={onEmailContentClick} />
        )}
      </div>
    </>
  );
};

export default LeftColumn;

import { useState, useEffect } from 'react';
import { PreviewModalProps } from './index.types';
import { Button } from '@mui/material';
import Modal from 'src/modules/General/components/Modal';
import { Worker, Viewer } from '@react-pdf-viewer/core';
import { defaultLayoutPlugin } from '@react-pdf-viewer/default-layout';
import '@react-pdf-viewer/core/lib/styles/index.css';
import '@react-pdf-viewer/default-layout/lib/styles/index.css';
import Loading from 'src/modules/General/components/Loading';

const PreviewModal = ({ open, onClose, text, pdfData, buttons, loading, onRetry, buttonsClassName = '' }: PreviewModalProps) => {
  const [error, setError] = useState<string | null>(null); 
  const newPlugin = defaultLayoutPlugin();

  useEffect(() => {
    if (pdfData && pdfData.length === 0) {
      setError("The PDF file is empty or its size is zero bytes.");
    } else {
      setError(null);
    }
  }, [loading, pdfData]);

  const handleRetry = () => {
    setError(null);
    onRetry?.();
  };

  return (
    <Modal open={open} onClose={onClose}>
      <div className="flex items-center justify-center flex-col gap-4">
        <span className="font-bold">{loading ? 'Downloading Document' : text}</span>
        {loading && <Loading variant='circle' size={30} color='info' />}

        {!loading && !error && pdfData && (
          <div className="border h-96 w-200 max-lg:max-w-125 max-md:max-w-sm max-sm:max-w-80">
            <Worker workerUrl="https://unpkg.com/pdfjs-dist@3.11.174/legacy/build/pdf.worker.min.js">
              <Viewer fileUrl={pdfData} plugins={[newPlugin]} />
            </Worker>
          </div>
        )}

        {!loading && error && (
          <div className="text-red-400 flex flex-row items-center gap-2">
            {error}
            <button className='border p-2 rounded text-blue-400' onClick={handleRetry}>Try Again</button>
          </div>
        )}

        {!!buttons.length && (
          <div className={`${buttonsClassName} flex items-center justify-center`}>
            {buttons.map((button, index) => (
              <Button key={index} {...button}>
                {button.children}
              </Button>
            ))}
          </div>
        )}
      </div>
    </Modal>
  );
};

export default PreviewModal;

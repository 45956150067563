import Header from 'src/modules/General/containers/Layout/Header';
import RightColumn from './RightColumn';
import LeftColumn from './LeftColumn';
import { NotificationsProvider } from 'src/core/contexts/notifications.context';
import HeaderMenu from './HeaderMenu';
import useNotificationPage from './useNotificationPage.services';

const Notifications = () => {
  const {
    notificationsList,
    onGetNotificationsByDateRang,
    getStatusNotifications,
    fromDate,
    setFromDate,
    setToDate,
    toDate,
    isLoadingData,
  } = useNotificationPage();
  return (
    <NotificationsProvider>
      <Header />
      <div className="w-sideBar flex  h-full max-lg:h-calc-120 max-lg:w-full max-lg:block max-lg:gap-0 max-sm:p-2">
        <div className="hidden w-full h-15 mb-2 max-lg:block">
          <HeaderMenu
            notificationsList={notificationsList}
            onGetNotificationsByDateRang={onGetNotificationsByDateRang}
            getStatusNotifications={getStatusNotifications}
          />
        </div>
        <div className="w-calc-384 px-6 py-8 bg-gray-200  max-lg:w-full max-lg:h-calc-68 max-sm:p-1">
          <LeftColumn notifications={notificationsList ?? []} isLoading={isLoadingData} />
        </div>

        <div className="w-96 max-lg:hidden">
          <RightColumn
            notificationsList={notificationsList}
            fromDate={fromDate}
            toDate={toDate}
            setFromDate={setFromDate}
            setToDate={setToDate}
            onGetNotificationsByDateRang={onGetNotificationsByDateRang}
            getStatusNotifications={getStatusNotifications}
          />
        </div>
      </div>
    </NotificationsProvider>
  );
};

export default Notifications;

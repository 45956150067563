import Input from 'src/modules/General/components/Input';
import 'react-select-search/style.css';
import Autocomplete from '@mui/material/Autocomplete';
import LockIcon from 'src/assets/images/Enterprise/lock.svg';
import UnlockIcon from 'src/assets/images/Enterprise/unlocked.svg';
import Box from '@mui/material/Box';
import { useState } from 'react';
import useEnterpriseSearch, { Item } from './index.services';
import Divider from '@mui/material/Divider';
import Loading from 'src/modules/General/components/Loading';
import TemporaryVisa from 'src/modules/EnterpriseSearch/containers/TemporaryVisa';
import SalesTax from 'src/modules/EnterpriseSearch/containers/SalesTax';
import { Global } from '@emotion/react';
import { styled } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import { grey } from '@mui/material/colors';
import SwipeableDrawer from '@mui/material/SwipeableDrawer';
import Header from 'src/modules/General/containers/Layout/Header';
import PreviewModal from 'src/modules/Queries/containers/PreviewModal';
import { useTranslation } from 'react-i18next';
import ConfirmationModal from 'src/modules/General/containers/ConfirmationModal';
const drawerBleeding = 0;

interface Props {
  /**
   * Injected by the documentation to work in an iframe.
   * You won't need it on your project.
   */
  window?: () => Window;
}

const Root = styled('div')(({ theme }) => ({
  height: '100%',
  backgroundColor: theme.palette.mode === 'light' ? grey[100] : theme.palette.background.default,
}));

const StyledBox = styled('div')(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'light' ? '#fff' : grey[800],
}));
const items: Item[] = [
  { label: 'Sales Tax', value: '3', lock: false },
  { label: 'Temporary Visa', value: '4', lock: false },
  { label: 'Real Estate', value: '5', lock: true },
  { label: 'Accounting', value: '6', lock: true },
];

const EnterpriseSearch = (props: Props) => {
  const { window } = props;
  const [open, setOpen] = useState(false);

  const toggleDrawer = (newOpen: boolean) => () => {
    setOpen(newOpen);
  };

  const container = window !== undefined ? () => window().document.body : undefined;
  const {
    changeSearchType,
    onNameInputChange,
    onProjectNameInputChange,
    onPassportNumberInputChange,
    onApplicationTypeInputChange,
    onSendQuestion,
    salesAnswer,
    temporaryAnswer,
    itemSelected,
    name,
    passportNumber,
    projectName,
    applicationType,
    errorMessage,
    isLoading,
    isModalOpen,
    onCloseModal,
    files,
    pdfData,
    onPreviewDialog,
    openModal,
    setOpenModal,
    handleOpenModal,
    messageUpgrade
  } = useEnterpriseSearch();
  const { t } = useTranslation();
  return (
    <>
      <Header />
      <div className="w-sideBar overflow-y-auto h-full max-lg:h-calc-120 max-lg:w-full">
        <div className='w-full grid grid-cols-2 max-md:grid-cols-1 gap-4 px-6 py-8'>
      <div className='w-full h-auto p-4 bg-[#f9f9f9] rounded-xl border border-Gray-ED flex-col justify-center items-start gap-2 inline-flex cursor-pointer' onClick={handleOpenModal}>
        <div className="text-neutral-700 text-xl font-bold font-['Arial'] max-sm:text-lg">&#x1F512; Financial Analysis</div>
        <div className="text-neutral-700 text-lg font-normal font-['Arial'] leading-snug max-sm:text-sm">Let Our tool simplify your financial analysis. Just input your data and leave the complex calculations to us.</div>
      </div>
      <div className='w-full h-auto p-4 bg-[#f9f9f9] rounded-xl border border-Gray-ED flex-col justify-center items-start gap-2 inline-flex cursor-pointer' onClick={handleOpenModal}>
        <div className="text-neutral-700 text-xl font-bold font-['Arial'] max-sm:text-lg">&#x1F512; Sales Tax Estimator</div>
        <div className="text-neutral-700 text-lg font-normal font-['Arial'] leading-snug max-sm:text-sm">Effortlessly estimate your project`s sales tax. input your project details and get a comprehensive tax breakdown in return.</div>
      </div>
      <div className='w-full h-auto p-4 bg-[#f9f9f9] rounded-xl border border-Gray-ED flex-col justify-center items-start gap-2 inline-flex cursor-pointer' onClick={handleOpenModal}>
        <div className="text-neutral-700 text-xl font-bold font-['Arial'] max-sm:text-lg">&#x1F512; Temporary Visa Applicant Search</div>
        <div className="text-neutral-700 text-lg font-normal font-['Arial'] leading-snug max-sm:text-sm">Easily search Temporary Visa applicant`s passport and let us do the rest.</div>
      </div>
   
      <ConfirmationModal
        open={openModal}
        onClose={() => setOpenModal(false)}
        text={messageUpgrade}
        buttons={[
          {
            children: t('Ok'),
            variant: 'contained',
            color: 'info',
            onClick: () => setOpenModal(false),
          },
        ]}
      />
   
      
        </div>
        {/* <div className="w-full h-full flex items-center justify-normal flex-col gap-y-4 px-6 py-8 overflow-y-auto">
          <div className="w-full grid grid-cols-3 gap-4 max-md:grid-cols-2 max-sm:flex max-sm:justify-normal max-sm:flex-col max-sm:gap-2">
            <div className="col-span-1 w-full flex items-end">
              <div className="w-full flex flex-col gap-y-6px">
                <label htmlFor="category" className="text-neutral-950 text-sm font-normal">
                  {t('Category')}
                </label>
                <select className="h-10 px-3.5 py-2.5 bg-white rounded-lg shadow border border-gray-200 text-base font-normal leading-tight text-black">
                  <option value="A">Group A</option>
                </select>
              </div>
            </div>
            <div className="col-span-1 w-full flex flex-col gap-y-6px">
              <label htmlFor="item" className="text-neutral-950 text-sm font-normal">
                Item
              </label>
              <Autocomplete
                sx={{
                  display: 'inline-block',
                  '& input': {
                    width: ' 100%',
                    bgcolor: 'background.paper',
                    color: (theme) => theme.palette.getContrastText(theme.palette.background.paper),
                  },
                }}
                onChange={changeSearchType}
                id="custom-input-demo"
                options={items}
                getOptionLabel={(option) => option.label}
                renderOption={(props, option) => (
                  <Box component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props}>
                    <img width={20} src={option.lock ? LockIcon : UnlockIcon} />
                    {option.label}
                  </Box>
                )}
                renderInput={(params) => (
                  <div ref={params.InputProps.ref}>
                    <input
                      type="text"
                      {...params.inputProps}
                      className="w-full h-10 px-3.5 py-2.5 bg-white rounded-lg shadow border border-gray-200 text-base font-normal leading-tight text-black"
                    />
                  </div>
                )}
              />
            </div>
            <div className="col-span-1 w-full flex items-end justify-between gap-4 max-sm:block">
              <Input
                title={t('Expire Date')}
                value={
                  itemSelected
                    ? itemSelected?.lock
                      ? t('You have not purchased this item yet')
                      : '2024/06/25 - 18:45'
                    : t('Please select an item.')
                }
                disable={true}
                inputStyle="rounded-lg"
              />
              {itemSelected?.lock && (
                <button className='h-10 px-4 py-2 bg-blue-600 rounded-xl shadow justify-center items-center gap-2 inline-flex text-white text-base font-medium font-["Inter"]  leading-tigh max-sm:mt-2'>
                  {t('Upgrade')}
                </button>
              )}
          
            </div>
          </div>
          <div className="w-full flex items-end justify-normal gap-4 max-md:grid-cols-2 max-sm:block">
            {itemSelected?.value === '3' && (
              <Input title={t('Project Name')} onChangeInput={onProjectNameInputChange} inputStyle="rounded-lg" />
            )}
            {itemSelected?.value === '4' && (
              <div className="w-full grid grid-cols-3 items-center justify-normal gap-4 max-md:block ">
                <Input title={t('Name')} onChangeInput={onNameInputChange} inputStyle="w-full col-span-1" />
                <Input
                  title={t('Passport Number')}
                  onChangeInput={onPassportNumberInputChange}
                  inputStyle="w-full col-span-1 rounded-lg"
                />
                <Input
                  title={t('Application Type')}
                  onChangeInput={onApplicationTypeInputChange}
                  inputStyle="w-full col-span-1 rounded-lg"
                />
              </div>
            )}
            {itemSelected !== null && !itemSelected.lock && (
              <div className="max-md:flex max-md:items-center max-md:justify-normal max-md:gap-2 max-md:flex-row">
                <button
                  disabled={isLoading}
                  onClick={onSendQuestion}
                  className='h-10 px-4 py-2 bg-blue-600 rounded-xl shadow justify-center items-center gap-2 inline-flex text-white text-base font-medium font-["Inter"]  leading-tigh max-sm:mt-2'>
                  {isLoading && (
                    <div className="hidden max-md:block">
                      {' '}
                      <Loading variant="circle" color="inherit" size={20} />
                    </div>
                  )}
                  {t('Generate')}
                </button>
                {(salesAnswer?.SalesTax?.length > 0 || (temporaryAnswer && temporaryAnswer?.length > 0)) && (
                  <div className="w-full relative mt-2 hidden max-sm:block">
                    <Root>
                      <CssBaseline />
                      <Global
                        styles={{
                          '.MuiDrawer-root > .MuiPaper-root': {
                            height: `100%`,
                            overflow: 'hidden',
                          },
                        }}
                      />
                      <Box sx={{ textAlign: 'center' }}>
                       
                        <button
                          disabled={isLoading}
                          onClick={toggleDrawer(true)}
                          className='w-full h-10 px-4 py-2 bg-blue-600 rounded-xl shadow justify-center items-center gap-2 inline-flex text-white text-base font-medium font-["Inter"]  leading-tigh'>
                          {t('Show Result')}
                        </button>
                      </Box>
                      <SwipeableDrawer
                        container={container}
                        anchor="bottom"
                        open={open}
                        onClose={toggleDrawer(false)}
                        onOpen={toggleDrawer(true)}
                        swipeAreaWidth={drawerBleeding}
                        disableSwipeToOpen={false}
                        ModalProps={{
                          keepMounted: true,
                        }}>
                        <StyledBox
                          sx={{
                            position: 'absolute',
                            top: -drawerBleeding,
                            borderTopLeftRadius: 8,
                            borderTopRightRadius: 8,
                            visibility: 'visible',
                            right: 0,
                            left: 0,
                          }}></StyledBox>
                        <StyledBox
                          className="p-4 bg-gray-300"
                          sx={{
                            height: '100%',
                            overflow: 'auto',
                          }}>
                          <button
                            className="bg-white rounded-lg mb-2 border border-blue-400 w-11 h-11 relative right-0"
                            onClick={toggleDrawer(false)}>
                            X
                          </button>
                          {itemSelected?.value === '3' && salesAnswer?.SalesTax?.length > 0 && (
                            <div className="w-full h-auto">
                              <div className="w-full h-auto bg-white shadow-table rounded mb-2">
                                <SalesTax SalesTax={salesAnswer?.SalesTax} />
                              </div>
                              <div className="w-full grid grid-cols-1 gap-2">
                                {files?.map((file: string, index: number) => (
                                  <div
                                    onClick={() => onPreviewDialog(file)}
                                    className="cursor-pointer min-h-16 px-4 py-6 bg-white rounded-2xl border border-gray-200 flex-col justify-start items-center gap-6 inline-flex text-neutral-700 text-base font-normal leading-tight"
                                    key={index}>
                                    {file}
                                  </div>
                                ))}
                              </div>
                            </div>
                          )}
                          {itemSelected?.value === '4' && temporaryAnswer && (
                            <div className="w-full h-auto">
                              <div className="w-full h-auto pb-2 overflow-auto mb-2">
                                <TemporaryVisa list={temporaryAnswer} />
                              </div>
                              <div className="w-full grid grid-cols-2 gap-2 max-sm:grid-cols-1">
                                {files?.map((file: string, index: number) => (
                                  <div
                                    onClick={() => onPreviewDialog(file)}
                                    className="cursor-pointer min-h-16 px-4 py-6 bg-white rounded-2xl border border-gray-200 flex-col justify-start items-center gap-6 inline-flex text-neutral-700 text-base font-normal leading-tight"
                                    key={index}>
                                    {file}
                                  </div>
                                ))}
                              </div>
                            </div>
                          )}
                        </StyledBox>
                      </SwipeableDrawer>
                    </Root>
                  </div>
                )}
              </div>
            )}
          </div>
          {errorMessage && <p className="text-left font-normal text-sm text-red-600 mt-2">{errorMessage}</p>}
          <div className="w-full h-auto block max-md:hidden">
            <Divider sx={{ width: '100%', borderColor: '#ffffff' }} />
            <p className="w-full text-left text-neutral-950 text-lg font-medium font-['Inter'] max-sm:text-xs">
              {t('Search Results for')}:{' '}
              {itemSelected?.value === '3' ? projectName : name + ' - ' + applicationType + ' - ' + passportNumber}
            </p>
            {isLoading && (
              <div className="w-full h-full flex items-center justify-center">
                <Loading variant="dots" size={30} />
              </div>
            )}
            <div className="w-full block max-sm:hidden">
              {itemSelected?.value === '3' && salesAnswer?.SalesTax?.length > 0 && (
                <div className="w-full h-auto">
                  <div className="w-full h-auto bg-white shadow-table rounded mb-2">
                    <SalesTax SalesTax={salesAnswer?.SalesTax} />
                  </div>
                  <div className="w-full grid grid-cols-2 gap-2">
                    {files?.map((file: string, index: number) => (
                      <div
                        onClick={() => onPreviewDialog(file)}
                        className="cursor-pointer min-h-16 px-4 py-6 bg-white rounded-2xl border border-gray-200 flex-col justify-start items-center gap-6 inline-flex text-neutral-700 text-base font-normal leading-tight"
                        key={index}>
                        {file}
                      </div>
                    ))}
                  </div>
                </div>
              )}
              {itemSelected?.value === '4' && temporaryAnswer && (
                <div className="w-full h-auto">
                  <div className="w-full h-auto pb-2 overflow-auto">
                    <TemporaryVisa list={temporaryAnswer} />
                  </div>
                  <div className="w-full grid grid-cols-2 gap-2">
                    {files?.map((file: string, index: number) => (
                      <div
                        onClick={() => onPreviewDialog(file)}
                        className="cursor-pointer min-h-16 px-4 py-6 bg-white rounded-2xl border border-gray-200 flex-col justify-start items-center gap-6 inline-flex text-neutral-700 text-base font-normal leading-tight"
                        key={index}>
                        {file}
                      </div>
                    ))}
                  </div>
                </div>
              )}
            </div>
          </div>
        </div> */}
        {/* <PreviewModal
          open={isModalOpen}
          onClose={onCloseModal}
          pdfData={pdfData}
          text={t('Preview File')}
          buttons={[{ children: t('Close'), color: 'primary', variant: 'contained', onClick: onCloseModal }]}
        /> */}
      </div>
    </>
  );
};

export default EnterpriseSearch;

export const copyToClipboard = (text: string) => {
  if (navigator && navigator.clipboard && navigator.clipboard.writeText) return navigator.clipboard.writeText(text);
  return Promise.reject('The Clipboard API is not available.');
};

export const getNodeText = (text: string | React.ReactNode): string => {
  if (text == null) return '';
  if (typeof text === 'string') return text;

  switch (typeof text) {
    case 'string':
    case 'number':
      return text.toString();
    case 'boolean':
      return '';
    case 'object': {
      if (text instanceof Array) return text.map(getNodeText).join('');
      if ('props' in text) return getNodeText(text.props.children);
      return '';
    }
    default:
      console.warn('Unresolved `node` of type:', typeof text, text);
      return '';
  }
};
export const trimText = (str: string, replacement?: string) => {
  return str.replace(/\s+/g, replacement ? replacement : '');
};

export const textBeutify = (str: string) => {
  const makeSpaceInStr = str.replace(/([A-Z])/g, (match) => ` ${match.toUpperCase()}`);
  return makeSpaceInStr.charAt(0).toUpperCase() + makeSpaceInStr.slice(1);
};

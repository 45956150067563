import { ToggleButton } from '@mui/material';
import ToggleButtonGroup from 'src/modules/General/components/ToggleButtonGroup';
import Icon from 'src/modules/General/components/Icon';
import { CategoriesQueriesProps } from './index.types';
import { ToggleButtonCss } from './index.styles';
import styles from './index.module.scss';
import cn from 'classnames';
import { useTranslation } from 'react-i18next';

const CategoriesQueries = ({ selectedCategory, onSelectCategory, counts = {} }: CategoriesQueriesProps) => {
  const categoryIsChats = selectedCategory === 'chats';
  const { t } = useTranslation();
  return (
    <ToggleButtonGroup
      className="p-2 bg-Gray-ED rounded-xl justify-start items-center gap-x-1 inline-flex"
      value={selectedCategory}
      onChange={(_, newCategory) => onSelectCategory(newCategory)}>
      <ToggleButton sx={ToggleButtonCss} value="chats" className="w-full capitalize">
        {/* <Icon name={categoryIsChats ? 'chats-filled-active' : 'chats-filled'} /> */}
        {t('Chats')}
        {Number(counts?.chats) >= 0 && (
          <div className="w-6 h-6 p-1 bg-orange-200 rounded-[17px] flex-col justify-center items-center gap-1 inline-flex text-neutral-950 text-xs font-normal">
            {counts.chats}
          </div>
        )}
      </ToggleButton>
      <ToggleButton sx={ToggleButtonCss} value="documents">
        {/* <Icon name={!categoryIsChats ? 'documents-active' : 'documents'} /> */}
        {t('Documents')}
        {Number(counts?.documents) >= 0 && (
          <div className="w-6 h-6 p-1 bg-orange-200 rounded-[17px] flex-col justify-center items-center gap-1 inline-flex text-neutral-950 text-xs font-normal">
            {counts.documents}
          </div>
        )}
      </ToggleButton>
    </ToggleButtonGroup>
  );
};

export default CategoriesQueries;

import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

interface Option {
  label: string;
  value: string;
}

interface MultiSelectProps {
  options: Option[];
  selectedOptions: Option[];
  onChange: (selected: Option[]) => void;
  disabled?: boolean;
}

const MultiSelect: React.FC<MultiSelectProps> = ({ options, selectedOptions, onChange, disabled }) => {
  const [isOpen, setIsOpen] = useState(false);
  const dropdownRef = useRef<HTMLDivElement>(null);
  const { t } = useTranslation();
  const handleToggle = () => {
    if (!disabled) {
      setIsOpen(!isOpen);
    }
  };

  const handleOptionClick = (option: Option) => {
    if (disabled) return;
    if (selectedOptions.find((selected) => selected.value === option.value)) {
      onChange(selectedOptions.filter((selected) => selected.value !== option.value));
    } else {
      onChange([...selectedOptions, option]);
    }
  };
  const handleClickOutside = (event: MouseEvent) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target as Node)) {
      setIsOpen(false);
    }
  };
  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  return (
    <div className="relative w-full" ref={dropdownRef}>
      <label htmlFor="">{t('Tags')}</label>

      <div
        className="flex flex-wrap items-center min-h-10 px-0.5  rounded cursor-pointer bg-white  shadow border border-gray-200 text-base font-normal leading-tight text-black"
        onClick={handleToggle}>
        {selectedOptions.length ? (
          selectedOptions.map((option) => (
            <span
              key={option.value}
              className="px-2 py-1 mr-0.5 mb-0.5 text-xs  bg-white rounded text-black border-primary border">
              {option.label}
            </span>
          ))
        ) : (
          <span className="text-gray-500">{t('Select Tags')}</span>
        )}
      </div>
      {isOpen && (
        <ul className="absolute z-10 w-full mt-1 bg-white border rounded shadow max-h-60 overflow-auto flex items-start flex-col gap-2 p-2">
          {options.map((option) => (
            <li
              key={option.value}
              className={`p-2 cursor-pointer w-full text-neutral-950 text-sm font-normal font-['Inter'] max-h-24   ${
                selectedOptions.find((selected) => selected.value === option.value)
                  ? 'bg-stone-100 text-black rounded-md'
                  : 'hover:bg-gray-100'
              }`}
              onClick={() => handleOptionClick(option)}>
              {option.label}
            </li>
          ))}
        </ul>
      )}
    </div>
  );
};

export default MultiSelect;

import { Divider } from '@mui/material';
import Header from 'src/modules/General/containers/Layout/Header';
import DocumentService, { FileItem } from './index.services';
import PreviewModal from 'src/modules/Queries/containers/PreviewModal';
import Loading from 'src/modules/General/components/Loading';
import ConfirmationModal from 'src/modules/General/containers/ConfirmationModal';
import { formatDate } from 'src/core/utilities/general';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import MultiSelect from 'src/modules/Queries/components/MultiSelect';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import FileCard from 'src/modules/Documents/components/FileCard';
import { useTranslation } from 'react-i18next';
const truncateText = (text: string, maxLength: number) => {
  return text.length > maxLength ? `${text.substring(0, maxLength)}...` : text;
};
const Doucuments = () => {
  const {
    allFiles,
    isFilter,
    loadingFilter,
    loadingReset,
    onCloseModal,
    onPreviewDialog,
    isModalOpen,
    pdfData,
    closeDailog,
    deleteDocument,
    openConfirmationModalDelete,
    isLoadingDelete,
    openDialog,
    loadingFiles,
    items,
    fromDate,
    toDate,
    itemSelected,
    changeTag,
    setFromDate,
    setToDate,
    filtredRecentFiles,
    nextPageAction,
    loadingNextPage,
    nextPage,
    nextPageFilter,
    allFilesFilter,
    filterActive,
    nextPageFilterAction,
    loadingDownload,
    onRetry
  } = DocumentService();
  const { t } = useTranslation();
  return (
    <>
      <Header />
      <div className="w-sideBar h-full max-lg:h-calc-120 max-lg:w-full py-8 px-6">
        <div className="w-full flex flex-row  items-end h-12 gap-2  pb-3 max-sm:h-36 max-sm:grid max-sm:grid-rows-3">
          <div className="w-full">
            <MultiSelect options={items} selectedOptions={itemSelected} onChange={changeTag} />
          </div>
          <div className="w-full grid grid-cols-2 gap-1 max-sm:grid max-sm:grid-cols-2 max-sm:gap-1">
            <div className="w-full">
              <label className="max-sm:hidden" htmlFor="">
                {t('From')}
              </label>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                  className="w-full"
                  value={fromDate}
                  onChange={(newValue) => {
                    setFromDate(newValue);
                  }}
                  slotProps={{
                    textField: {
                      size: 'small',
                      style: { backgroundColor: 'white' },
                    },
                  }}
                />
              </LocalizationProvider>
            </div>
            <div className="w-full">
              <label htmlFor="" className="max-sm:hidden">
                {t('To')}
              </label>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                  className="w-full"
                  value={toDate}
                  minDate={fromDate ?? undefined}
                  onChange={(newValue) => {
                    setToDate(newValue);
                  }}
                  slotProps={{
                    textField: {
                      size: 'small',
                      style: { backgroundColor: 'white' },
                    },
                  }}
                />
              </LocalizationProvider>
            </div>
          </div>
          <div className="flex flex-row gap-1">
            <button
              onClick={() => filtredRecentFiles(false)}
              disabled={loadingFilter || (!fromDate && !toDate && !itemSelected.length)}
              type="submit"
              className="h-11 px-4 py-3 bg-blue-600 rounded-xl shadow justify-center items-center gap-2 inline-flex text-white text-base font-medium font-['Inter'] capitalize leading-tight">
              {loadingFilter && <Loading variant="circle" size={20} color="inherit" />}
              {t('Filter')}
            </button>
            {isFilter && (
              <button
                onClick={() => filtredRecentFiles(true)}
                type="submit"
                className="h-11 px-4 py-3 bg-blue-600 rounded-xl shadow justify-center items-center gap-2 inline-flex text-white text-base font-medium font-['Inter'] capitalize leading-tight">
                {loadingReset && <Loading variant="circle" size={20} color="inherit" />}
                {t('Clear')}
              </button>
            )}
          </div>
        </div>
        <Divider sx={{ borderColor: '#ffffff', width: '100%' }} />
        <div className="w-full flex flex-row gap-6 h-15 items-center justify-end px-4 ">
          {filterActive ? (
            <>
              <p>{t('Total number of files')} : {allFilesFilter?.length}</p>
              <button
                disabled={!nextPageFilter || loadingNextPage}
                onClick={nextPageFilterAction}
                className="bg-blue-600 flex items-center text-white py-2 px-4 rounded-lg shadow-lg  focus:outline-none focus:ring-2 focus:ring-blue-300 disabled:opacity-50 disabled:cursor-not-allowed">
                {loadingNextPage && <Loading variant="circle" color="inherit" size={20} />}
                {t('Next Page')}
              </button>
            </>
          ) : (
            <>
              <p>{t('Total number of files ')}: {allFiles?.length}</p>
              <button
                disabled={!nextPage || loadingNextPage}
                onClick={() => nextPageAction(true)}
                className="bg-blue-600 flex items-center text-white py-2 px-4 rounded-lg shadow-lg  focus:outline-none focus:ring-2 focus:ring-blue-300 disabled:opacity-50 disabled:cursor-not-allowed">
                {loadingNextPage && <Loading variant="circle" color="inherit" size={20} />}
                {t('Next Page')}
              </button>
            </>
          )}
        </div>
        <div className="w-full h-calc-108 overflow-y-auto py-4 max-sm:h-calc-204">
          {loadingFiles && <Loading variant="circle" size={40} />}

          {!loadingFiles &&
            (filterActive ? (
              <div className="w-full grid  grid-cols-2 max-md:grid-cols-1 gap-4">
                {allFilesFilter.map((file: FileItem, index: number) => (
                  <FileCard
                    key={index}
                    file={file}
                    onPreviewDialog={onPreviewDialog}
                    openDialog={openDialog}
                    truncateText={truncateText}
                    formatDate={formatDate}
                  />
                ))}
              </div>
            ) : (
              <div className="w-full grid  grid-cols-2 max-md:grid-cols-1 gap-4">
                {allFiles.map((file: FileItem, index: number) => (
                  <FileCard
                    key={index}
                    file={file}
                    onPreviewDialog={onPreviewDialog}
                    openDialog={openDialog}
                    truncateText={truncateText}
                    formatDate={formatDate}
                  />
                ))}
              </div>
            ))}
        </div>
        
        <PreviewModal
          open={isModalOpen}
          onClose={onCloseModal}
          pdfData={pdfData}
          text={t('Preview File')}
          loading={loadingDownload}
          onRetry={onRetry}
          buttons={[{ children: t('Close'), color: 'primary', variant: 'contained', onClick: onCloseModal }]}
        />

        <ConfirmationModal
          open={openConfirmationModalDelete}
          onClose={closeDailog}
          text={t('Are you sure to delete this file?')}
          buttons={[
            {
              children: t('Remove'),
              variant: 'contained',
              color: 'error',
              onClick: deleteDocument,
              endIcon: isLoadingDelete && <Loading variant="circle" size={20} color="inherit" />,
            },
            {
              children: t('Cancel'),
              variant: 'contained',
              color: 'success',
              onClick: closeDailog,
            },
          ]}
        />
      </div>
    </>
  );
};

export default Doucuments;

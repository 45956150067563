import * as React from 'react';
import Drawer from '@mui/material/Drawer';
import RightColumn from 'src/pages/Queries/RightColumn';
import Fab from '@mui/material/Fab';
import Icon from 'src/modules/General/components/Icon';

const DrawerLeft = () => {
  const [open, setOpen] = React.useState(false);

  const toggleDrawer = (newOpen: boolean) => () => {
    setOpen(newOpen);
  };

  const DrawerList = (
    <div className="w-80 max-h-full overflow-auto p-4 relative" role="presentation">
      <div className="absolute right-1 top-1.5">
        <Fab
          className="flex items-center justify-center"
          onClick={toggleDrawer(false)}
          size="small"
          color="primary"
          aria-label="add">
          <Icon name="close" className="relative -left-1 " />
        </Fab>
      </div>

      <RightColumn />
    </div>
  );

  return (
    <div className="">
      <Fab
        className=" flex items-center justify-center "
        onClick={toggleDrawer(true)}
        size="small"
        color="primary"
        aria-label="add">
        <Icon name="three-dots" className="relative " />
      </Fab>
      <Drawer open={open} onClose={toggleDrawer(false)}>
        {DrawerList}
      </Drawer>
    </div>
  );
};

export default DrawerLeft;

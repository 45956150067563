import { useState, useEffect } from 'react';
import backgroundAuth1 from 'src/assets/images/Auth/bgA.png';
import backgroundAuth2 from 'src/assets/images/Auth/bgB.png';

const Right: React.FC = () => {
  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const [fadeProp, setFadeProp] = useState('fade-in');

  const images = [backgroundAuth1, backgroundAuth2];

  useEffect(() => {
    const intervalId = setInterval(() => {
      setFadeProp('fade-out');

      setTimeout(() => {
        setCurrentImageIndex((prevIndex) => (prevIndex + 1) % images.length);
        setFadeProp('fade-in');
      }, 300);
    }, 10000);

    return () => clearInterval(intervalId);
  }, [images.length]);

  return (
    <div
      className={`relative w-full h-full bg-no-repeat bg-full rounded-l-80 flex items-center justify-center px-16 bg-center ${fadeProp}`}
      style={{ backgroundImage: `url(${images[currentImageIndex]})` }}>
      <div className="absolute inset-0 bg-gradient-to-b from-transparent to-[#00265f] opacity-80 z-10 rounded-l-80"></div>
    </div>
  );
};

export default Right;

import { CSSProperties, useEffect } from 'react';
import html2canvas from 'html2canvas';
import useClickToSelect from 'src/core/hooks/useClickToSelect';
import { useAppContext } from 'src/core/contexts/_app.context';
import { ScreenshotProps } from './index.types';
import styles from './index.module.scss';

const Screenshot = ({ lastScreenshotNumber, onTakeScreenshot }: ScreenshotProps) => {
  const { state, dispatch } = useAppContext();
  const { screenshotMode } = state || {};
  const { startX, startY, endX, endY, isSelecting } = useClickToSelect();
  const showSelection = isSelecting && screenshotMode && endX > 0 && endY > 0;

  const takeScreenshot = () => {
    if (endX > 0 && endY > 0 && startX !== endX && startY !== endY) {
      const width = Math.abs(endX - startX);
      const height = Math.abs(endY - startY);

      html2canvas(document.querySelector('.app__container') as HTMLElement, {
        x: startX,
        y: startY,
        width,
        height,
        scale: 1.2,
      }).then(c => {
        const imgData = c.toDataURL('image/png');
        const link = document.createElement('a');
        link.href = imgData;
        link.download = `screenshot-${lastScreenshotNumber + 1}.pdf`;
        onTakeScreenshot(link);
      });
      dispatch({ type: 'screenshotMode', value: false });
    }
  };

  useEffect(() => {
    if (!isSelecting && screenshotMode) {
      takeScreenshot();
    }
  }, [isSelecting, screenshotMode]);

  const selectionStyle: CSSProperties = {
    left: Math.min(startX, endX),
    top: Math.min(startY, endY),
    width: Math.abs(endX - startX),
    height: Math.abs(endY - startY),
  };

  return showSelection ? <div className={styles['container']} style={selectionStyle} /> : null;
};

export default Screenshot;

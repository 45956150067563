import { Snackbar, SnackbarProps } from '@mui/material';
import variables from 'src/styles/constants/_exports.module.scss';

const CustomSnackbar = ({
  color = 'success',
  anchorOrigin = { vertical: 'top', horizontal: 'center' },
  ContentProps,
  autoHideDuration = 6000,
  message = '',
  ...props
}: SnackbarProps) => {
  return (
    <Snackbar
      anchorOrigin={anchorOrigin}
      ContentProps={{
        sx: {
          background: variables[`color_${color}`],
        },
        ...ContentProps,
      }}
      autoHideDuration={autoHideDuration}
      message={message}
      {...props}
    />
  );
};

export default CustomSnackbar;

import { ToggleButtonGroup } from '@mui/material';
import { CustomToggleButtonGroupProps } from './index.types';
import { ToggleButtonGroupCss } from './index.styles';

const CustomToggleButtonGroup = ({ children, customToggleButtonGroupCss, ...props }: CustomToggleButtonGroupProps) => {
  return (
    <ToggleButtonGroup exclusive sx={{ ...ToggleButtonGroupCss, ...customToggleButtonGroupCss }} {...props}>
      {children}
    </ToggleButtonGroup>
  );
};

export default CustomToggleButtonGroup;

import { Link } from '@mui/material';
import errorImage from 'src/assets/images/error.webp';

const DefaultErrorPage = () => {
  return (
    <div className="h-screen flex items-center justify-center flex-col gap-4">
      <img src={errorImage} />
      <h1>An Unexpected Error Occurred.</h1>
      <p>We apologize for the inconvenience.</p>
      <Link href="/">Click here to try again</Link>
    </div>
  );
};

export default DefaultErrorPage;

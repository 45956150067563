import { useEffect, useState } from 'react';

import { useEmailsContext } from 'src/core/contexts/emails.context';
import {
  AddEmailsDataType,
  EmailAccount,
  RecentEmail,
  addEmails,
  processEmail,
  removeEmail,
  retriveAllEmails,
} from 'src/modules/Emails/api';
import { EmailProps } from 'src/modules/Emails/containers/EmailsList/index.types';

//TYPES

interface Snackbar {
  display: boolean;
  color: string;
  message: string;
}

export interface Email {
  id: number;
  sender: string;
  subject: string;
  date: string;
  content: string;
  contentRow: string;
  attachment: string;
}

//Convertors
const convertToEmailsList = (list: Array<EmailAccount>) => {
  return list.length
    ? list?.map((email: EmailAccount) => ({
        id: email.uid,
        email: email.address,
        host: email.host,
        count: email.processed_emails_count,
        active: email.activate,
      }))
    : [];
};



const useEmailsPage = () => {
  const unknownError = 'An unknown error occurred.';
  const { dispatch } = useEmailsContext();

  const [emailsList, setEmailsList] = useState<EmailProps[]>([]);
  const [emailName, setEmailName] = useState('');
  const [openEmailModal, setOpenEmailModal] = useState(false);
  const [showSnackbar, setShowSnackbar] = useState<Snackbar>({ display: false, color: '', message: '' });
  const [isLoading, setIsLoading] = useState(false);
  const [openConfirmationModal, setOpenConfirmationModal] = useState<{ name: 'remove' | 'deactivate'; open: boolean }>({
    name: 'remove',
    open: false,
  });
  const updateEmailsList = async (call: boolean) => {
    const process = await processEmail();
    setShowSnackbar({ display: true, color: 'info', message: process.message });
    if (call) {
      const data = await retriveAllEmails();
      if (typeof data !== 'string') {
        setEmailsList(convertToEmailsList(data.data));
      }
    } else {
      setTimeout(async () => {
        const addedEmails = await retriveAllEmails();
        if (typeof addedEmails !== 'string') {
          setEmailsList(convertToEmailsList(addedEmails.data));
        }
      }, 50000);
    }
  };
  const getEmailsAccount = async () => {
    setIsLoading(true);
    const data = await retriveAllEmails();
    if (typeof data !== 'string') {
      setEmailsList(convertToEmailsList(data.data));
    }

    setIsLoading(false);
  };
  useEffect(() => {
    getEmailsAccount();
  }, []);

  const onAddEmail = async (formData: AddEmailsDataType) => {
    setIsLoading(true);
    try {
      const response = await addEmails(formData);
      setIsLoading(false);
     

      if (response.error_code === 0) {
        setOpenEmailModal(false);
        setIsLoading(false);
        updateEmailsList(true);
        setShowSnackbar({ display: true, color: 'success', message: response?.message });
      } else {
        setShowSnackbar({ display: true, color: 'error', message: response?.message });
      }
    } catch (error: unknown) {
      setIsLoading(false);
      if (error instanceof Error) {
        setShowSnackbar({ display: true, color: 'error', message: error.message });
      } else {
        console.error('An unknown error occurred.');
        setShowSnackbar({ display: true, color: 'error', message: unknownError });
      }
    }
  };
  const onRemoveEmailClick = (email: string) => {
    setEmailName(email);
    setOpenConfirmationModal({ name: 'remove', open: true });
  };

  const onRemoveEmail = async () => {
    setIsLoading(true);
    try {
      const response = await removeEmail(emailName);

      if (response?.error_code == 0) {
        updateEmailsList(true);
        setShowSnackbar({ display: true, color: 'success', message: response?.message });
        dispatch({ type: 'emails', value: [] });
        dispatch({ type: 'showContent', value: false });
        dispatch({ type: 'emailAddress', value: '' });
        dispatch({ type: 'count', value: 0 });
      } else {
        setShowSnackbar({ display: true, color: 'error', message: response?.message });
      }
    } catch (error: unknown) {
      if (error instanceof Error) {
        setShowSnackbar({ display: true, color: 'error', message: error.message });
      } else {
        console.error('An unknown error occurred.');
        setShowSnackbar({ display: true, color: 'error', message: unknownError });
      }
    }
    setIsLoading(false);
    closeConfirmationModal();
  };
  const handleCloseSnackbar = () => setShowSnackbar({ display: false, message: '', color: '' });

  const closeConfirmationModal = () => {
    setIsLoading(false);
    setOpenConfirmationModal({ ...openConfirmationModal, open: false });
  };
  return {
    emailsList,
    isLoading,
    onAddEmail,
    openEmailModal,
    setOpenEmailModal,
    handleCloseSnackbar,
    openConfirmationModal,
    closeConfirmationModal,
    onRemoveEmailClick,
    onRemoveEmail,
    showSnackbar,
    updateEmailsList,
    setOpenConfirmationModal,
  };
};

export default useEmailsPage;

import { Button } from '@mui/material';
import Modal from 'src/modules/General/components/Modal';
import { ConfirmationModalProps } from './index.types';

const ConfirmationModal = ({
  open,
  onClose,
  text,
  previewElement = null,
  buttons,
  buttonsClassName = '',
}: ConfirmationModalProps) => {
  return (
    <Modal open={open} onClose={onClose} customBoxCss="max-w-lg min-w-80">
      <div className="flex items-center justify-center flex-col gap-4 w-full">
        <span className="font-bold">{text}</span>
        {previewElement}
        {!!buttons.length && (
          <div className={`flex items-center justify-center flex-row gap-2 ${buttonsClassName}`}>
            {buttons.map((button, index) => (
              <Button key={index} {...button}>
                {button.children}
              </Button>
            ))}
          </div>
        )}
      </div>
    </Modal>
  );
};

export default ConfirmationModal;

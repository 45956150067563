import { Modal } from '@mui/material';
import Icon from '../Icon';
import { CustomModalProps } from './index.types';

const CustomModal = ({ open, onClose, hasCloseIcon = true, children, customBoxCss, ...props }: CustomModalProps) => {
  return (
    <Modal open={open} onClose={(_, reason) => onClose(reason)} {...props}>
      <div className="fixed inset-0 bg-gray-600 bg-opacity-50 overflow-y-auto h-full w-full z-50 flex items-center justify-center">
        <div className={`relative p-5 border  shadow-lg rounded-md bg-white ${customBoxCss}`}>
          {hasCloseIcon && (
            <Icon name="close" className="absolute top-1 right-1" onClick={() => onClose('closeButtonClick')} />
          )}
          {children}
        </div>
      </div>
    </Modal>
  );
};

export default CustomModal;

import { Divider } from '@mui/material';
import Loading from 'src/modules/General/components/Loading';

import Screenshot from 'src/modules/General/containers/Screenshot';

import SearchInput from 'src/modules/Queries/containers/SearchInput';

import useDocumentRetrieval from './index.services';
import PreviewModal from '../../../../modules/Queries/containers/PreviewModal/index';

import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';

import MultiSelect from 'src/modules/Queries/components/MultiSelect';
import { useTranslation } from 'react-i18next';
const DocumentRetrieval = () => {
  const {
    
    searchValue,
    searchResultOf,
    onSearchInputChange,
    onSendQuestion,
   
    lastScreenshotNumber,
    onTakeScreenshot,
    onConfirmAttachment,

    relatedDocuments,
    showMessage,
    isLoadingQuestion,
 
    isModalOpen,
    onCloseModal,
    onPreviewDialog,
    pdfData,
    changeTag,
    itemSelected,
    changeMethodSearch,
    methodSearch,
    reset,
    fromDate,
    setFromDate,
    toDate,
    setToDate,
    items,
    fromDateSelected,
    toDateSelected,
    tagsSelected,
    loadingDaownload,
    onRetry
  } = useDocumentRetrieval();
  const { t } = useTranslation();
  function reverseAndExtractAfterSeparator(input: string, separator: string): string | null {
    const reversedString = input.split('').reverse().join('');
    const parts = reversedString.split(separator);

    return parts.length > 1 ? parts[0].split('').reverse().join('') : null;
  }
  const documentsList = () => {
    return !showMessage ? (
      <div className="w-full grid grid-cols-2 gap-2">
        {relatedDocuments.map((document: { name: string; link: string }) => (
          <button
            className="min-h-16 px-4 py-6 bg-white rounded-2xl border border-gray-200 flex-col justify-start items-center gap-6 inline-flex text-neutral-700 text-base font-normal leading-tight"
            onClick={() => onPreviewDialog(document.link)}
            key={document.link}>
            {document.name}
          </button>
        ))}
      </div>
    ) : (
      <div className="p-3 border rounded-lg border-orange-500 self-start font-bold text-lg">{showMessage}</div>
    );
  };

  return (
    <>
      <div className="flex items-center justify-center gap-4">
        <div className="w-full flex items-start flex-col gap-2">
          <div className="w-full grid grid-cols-2 gap-2 items-end justify-start">
            <div className="grid grid-cols-2 gap-2">
              <div>
                <label htmlFor="">{t('From')}</label>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DatePicker
                    className="w-full"
                    value={fromDate}
                    onChange={(newValue) => {
                      setFromDate(newValue);
                    }}
                    slotProps={{
                      textField: {
                        size: 'small',
                        style: { backgroundColor: 'white' },
                      },
                    }}
                  />
                </LocalizationProvider>
              </div>
              <div>
                <label htmlFor="">{t('To')}</label>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DatePicker
                    className="w-full"
                    minDate={fromDate ?? undefined}
                    value={toDate}
                    onChange={(newValue) => {
                      setToDate(newValue);
                    }}
                    slotProps={{
                      textField: {
                        size: 'small',
                        style: { backgroundColor: 'white' },
                      },
                    }}
                  />
                </LocalizationProvider>
              </div>
            </div>
            <div>
              <MultiSelect options={items} selectedOptions={itemSelected} onChange={changeTag} />
            </div>
          </div>
          <SearchInput
            disabledSearch={methodSearch === '' && fromDate === null && toDate === null && !itemSelected.length}
            value={searchValue}
            onChangeInput={onSearchInputChange}
            onIconClick={onSendQuestion}
            onMethodSearch={changeMethodSearch}
            resetDropDown={reset}
          />
          <div className="w-full flex flex-row items-center justify-end">
            <button
              onClick={onSendQuestion}
              disabled={
                (methodSearch === '' && fromDate === null && toDate === null && !itemSelected.length) ||
                searchValue === ''
              }
              className={`h-10 px-6 py-3   rounded-xl shadow justify-center items-center flex flex-row gap-x-1  text-base font-medium capitalize leading-tight bg-primary text-white disabled:bg-stone-100 disabled:text-black `}>
              {t('Search')}
            </button>
          </div>
        </div>

       
      </div>

      <Divider sx={{ borderColor: '#ffffff', width: '100%' }} />

      {searchResultOf && (
        <div>
          <div className="text-neutral-950 text-lg font-medium">Search Results for: {searchResultOf} </div>
          <div className="w-full flex flex-col items-center justify-start gap-1">
            {fromDateSelected && toDateSelected && (
              <p className="w-full">
                {t('Date')} : {fromDateSelected} - {toDateSelected}
              </p>
            )}
            {tagsSelected.length > 0 && (
              <p className="w-full flex flex-row justify-start gap-1"> {t('Tags')} : {tagsSelected.map((tag) => tag)},</p>
            )}
          </div>
        </div>
      )}
      <div className="flex items-start justify-center flex-row">
        {isLoadingQuestion ? <Loading variant="circle" size={45} /> : documentsList()}
      </div>
      <Screenshot lastScreenshotNumber={lastScreenshotNumber} onTakeScreenshot={onTakeScreenshot} />
      <PreviewModal
      loading={loadingDaownload}
        open={isModalOpen}
        onClose={onCloseModal}
        pdfData={pdfData}
        onRetry={onRetry}
        text={t('Preview File')}
        buttons={[{ children: t('Close'), color: 'primary', variant: 'contained', onClick: onConfirmAttachment }]}
      />
    </>
  );
};

export default DocumentRetrieval;

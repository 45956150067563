import React, { createContext, useState, ReactNode } from 'react';

interface UserToken {
  accessToken?: string;
  refreshToken?: string;
  tokenType?: string;
}

interface UserData {
  isActive: boolean;
  userID: number;
  email: string;
  firstName: string;
  lastName: string;
  username: string;
  userRole: string;
  expirationDate: number;
}

interface AuthState {
  userToken?: UserToken;
  userData?: UserData;
}

interface AuthContextType {
  auth: AuthState;
  setAuth: React.Dispatch<React.SetStateAction<AuthState>>;
}

const defaultAuthContext: AuthContextType = {
  auth: {},
  setAuth: () => null,
};

const AuthContext = createContext<AuthContextType>(defaultAuthContext);

interface AuthProviderProps {
  children: ReactNode;
}

export const AuthProvider = ({ children }: AuthProviderProps) => {
  const [auth, setAuth] = useState<AuthState>({});

  return <AuthContext.Provider value={{ auth, setAuth }}>{children}</AuthContext.Provider>;
};

export default AuthContext;

import { Provider } from 'react-redux';
import { ThemeProvider } from '@emotion/react';
import CssBaseline from '@mui/material/CssBaseline';
import Page from './modules/General/containers/Page';
import configureStore from 'src/core/store/_app.store';
import { AppProvider } from './core/contexts/_app.context';
import { theme } from './_app.theme';

export const store = configureStore();

function App() {
  return (
    <Provider store={store}>
      <AppProvider>
        <ThemeProvider theme={theme}>
          <CssBaseline />
          <Page />
        </ThemeProvider>
      </AppProvider>
    </Provider>
  );
}

export default App;

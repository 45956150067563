import React, { useEffect, useRef, useState } from 'react';
import { DropdownProps } from './index.types';
import { useTranslation } from 'react-i18next';

const DropDown = ({ onClickOption, reset }: DropdownProps) => {
  const { t } = useTranslation();
  const [selectedOption, setSelectedOption] = useState<string>(t('All my files'));
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const dropdownRef = useRef<HTMLDivElement>(null);

  const clickOption = (method: string | null, methodName: string) => {
    setSelectedOption(methodName);
    setIsOpen(false);
    onClickOption?.(method ?? 'all');
  };

  useEffect(() => {
    setSelectedOption(t('All my files'))
    setIsOpen(false);
    onClickOption?.('all');
  }, [reset]);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target as Node)) {
        setIsOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  return (
    <div ref={dropdownRef} className="relative inline-block text-left w-full">
      <div className="w-full">
        <button
          type="button"
          className="h-10 px-3.5 py-2.5 truncate bg-white rounded-r-lg shadow border border-gray-200 w-full text-xs font-medium text-gray-700 hover:bg-gray-50 focus:outline-none max-sm:text-xs border-t border-b border-r border-l"
          onClick={() => setIsOpen(!isOpen)}
        >
          {selectedOption}
        </button>
      </div>
      {isOpen && (
        <div
          className="bg-white origin-top-right absolute right-0 mt-0.5 w-full p-2 rounded-xl shadow border border-gray-200 justify-start items-start inline-flex ring-1 ring-black ring-opacity-5 z-10"
          role="menu"
          aria-orientation="vertical"
          aria-labelledby="options-menu"
        >
          <div className="flex flex-col items-start">
            <div
              onClick={() => clickOption('all', t('All my files'))}
              className="cursor-pointer text-neutral-950 text-xs font-normal font-['Inter'] h-11 px-1.5 py-0.5 justify-start items-center inline-flex"
            >
              {t('All my files')}
            </div>
            <div
              onClick={() => clickOption('email', t('Active Email'))}
              className="cursor-pointer text-neutral-950 text-xs font-normal font-['Inter'] h-11 px-1.5 py-0.5 justify-start items-center inline-flex"
            >
              {t('Active Email')}
            </div>
            <div
              onClick={() => clickOption('doc', t('Uploaded File'))}
              className="cursor-pointer text-neutral-950 text-xs font-normal font-['Inter'] h-11 px-1.5 py-0.5 justify-start items-center inline-flex"
            >
              {t('Uploaded File')}
            </div>
            <div
              onClick={() => clickOption('doc_attachment', t('Uploaded Documents and Emails Attachments'))}
              className="cursor-pointer text-neutral-950 text-xs font-normal font-['Inter'] h-11 px-1.5 py-0.5 justify-start items-center inline-flex"
            >
              {t('Uploaded Documents and Emails Attachments')}
            </div>
            <div
              onClick={() => clickOption('email_attachment', t('Emails and Their Attachment'))}
              className="cursor-pointer text-neutral-950 text-xs font-normal font-['Inter'] h-11 px-1.5 py-0.5 justify-start items-center inline-flex"
            >
              {t('Emails and Their Attachment')}
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default DropDown;

import { createTheme } from '@mui/material';
import variables from 'src/styles/constants/_exports.module.scss';

export const theme = createTheme({
  palette: {
    // primary: {
    //   main: '#0066FF',
    // },
    // secondary: {
    //   main: variables.color_secondary,
    // },
    //FIXME: switch theme later
    mode: 'light',
    background: {
      default: variables.color_light_blue_4,
    },
    text: {
      // primary: variables.color_primary,
      // secondary: variables.color_secondary,
    },
  },
});

import { textTransform } from 'html2canvas/dist/types/css/property-descriptors/text-transform';
import variables from 'src/styles/constants/_exports.module.scss';

export const ToggleButtonCss = {
  '&.MuiToggleButton-root': {
    fontSize: '16px',
    fontWeight: '600',
    display: 'flex',
    gap: '0.25rem',
    padding: '0.5rem',
    textTransform: 'capitalize',
  },
  '&.Mui-selected': {
    backgroundColor: '#0066FF',
    color: 'white',
  },
};

import { createSlice } from '@reduxjs/toolkit';

type StateType = {
  message: string | null;
};

const initialState: StateType = {
  message: null,
};

const errorSlice = createSlice({
  name: 'error',
  initialState,
  reducers: {
    setError(state, action) {
      const { message } = action.payload;
      state.message = message;
    },
    removeError(state) {
      state.message = null;
    },
  },
});

export const { setError, removeError } = errorSlice.actions;

export default errorSlice.reducer;

import Avatar from 'src/modules/General/components/Avatar';
import Icon from 'src/modules/General/components/Icon';
import UserIcon from 'src/assets/images/Queries/user.svg';
import NxdIcon from 'src/assets/images/Queries/NXD.svg';
import CopyIcon from 'src/assets/images/Queries/copy.svg';
import Loading from 'src/modules/General/components/Loading';
import { copyToClipboard, getNodeText } from 'src/core/utilities/texts';
import { MessageProps } from './index.types';
import styles from './index.module.scss';
import cn from 'classnames';
import { useTranslation } from 'react-i18next';

const Message = ({ type, img, text, loading = false }: MessageProps): JSX.Element => {
  const isSender = type === 'user';
  const { t } = useTranslation();
  // const senderActions = () => <Icon name="edit" />;

  const recieverActions = () =>
    text && (
      <div className="w-full flex items-center justify-between gap-4 max-sm:block">
        <div className="flex items-center justify-center flex-row gap-x-2 pr-2 max-sm:justify-start max-sm:pb-1">
          <Icon name="emoji-happy" />
          <Icon name="emoji-sad" />
        </div>

        <button
          onClick={() => copyToClipboard(getNodeText(text))}
          className=" h-10 px-4 py-2 rounded-lg border border-zinc-600 justify-start items-center gap-2 inline-flex">
          <img src={CopyIcon} alt="copy" />

          <span className="text-zinc-600 text-base font-medium">{t('Copy')}</span>
        </button>
      </div>
    );

  const showResposeMessage = () => (loading ? <Loading variant="dots" className={styles['loading']} /> : text);

  return (
    <div className="flex justify-start gap-4 gap-x-2 max-sm:w-full">
      <div
        className={`${isSender ? 'bg-orange-500' : 'bg-primary'} w-10 h-10 p-2.5  rounded-full flex-col justify-center items-center gap-2.5 inline-flex`}>
        <img src={isSender ? UserIcon : NxdIcon} alt="you" />
      </div>
      <div className="flex flex-col justify-start gap-y-1 w-calc-40">
        <p>{isSender ? t('You') : 'DeedDex'}</p>
        <div
          className={`${isSender ? 'bg-white' : 'bg-blue-200'}   px-3.5 py-2.5  rounded-tr-lg rounded-bl-lg rounded-br-lg flex-col justify-center items-start gap-2 inline-flex text-neutral-950 text-base font-normal leading-tight max-sm:text-xs`}>
          {isSender ? text : showResposeMessage()}
          {!isSender && recieverActions()}
        </div>
      </div>
    </div>
  );
};

export default Message;

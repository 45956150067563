import { useState } from 'react';
import { useNotificationsContext } from 'src/core/contexts/notifications.context';
import { feedbackNotification } from '../../api';

const notificationService = () => {
  const { state, dispatch } = useNotificationsContext();
  const { notifications } = state || {};
  const [open, setOpen] = useState<boolean>(false);
  const seenNotification = async (datetimes: string) => {
    const body = {
      datetimes: [datetimes, datetimes],
      method: 0,
    };
    const seen = await feedbackNotification(body);

    const data = [...notifications];
    for (let i = 0; i < data.length; i++) {
      if (data[i].DateTime === datetimes) {
        data[i].Seen = true;

        dispatch({ type: 'notifications', value: data });
        break;
      }
    }
  };
  const openDialogNotification = (dateTimes: string, seen: boolean) => {
    setOpen(true);
    if (!seen) {
      seenNotification(dateTimes);
    }
  };

  return {
    openDialog: open,
    openDialogNotification,
    setOpen,
  };
};

export default notificationService;

import { useState } from 'react';
import { useEmailsContext } from 'src/core/contexts/emails.context';

import { Email } from '../RightColumn/index.services';

const useEmailsLeftColumn = () => {
  const { state, dispatch } = useEmailsContext();
  const { emails, showContent, emailAddress, count, pageSize, loading } = state || {};
  const [selectedEmail, setSelectedEmail] = useState<Email>();

  const onEmailContentClick = (email: Email) => {
    setSelectedEmail(email);

    dispatch({ type: 'showContent', value: true });
  };
  const onBackClick = () => {
    dispatch({ type: 'showContent', value: false });
  };

  return {
    emails,
    emailAddress,
    count,
    pageSize,
    showContent,
    selectedEmail,
    onEmailContentClick,
    onBackClick,
    loading,
  };
};

export default useEmailsLeftColumn;

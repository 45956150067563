import React from 'react';
import { trimText } from 'src/core/utilities/texts';
import { TabsProps } from './index.types';
import styles from './index.module.scss';
import cn from 'classnames';

const Tabs = ({ tabs, selectedTab, onSelectTab }: TabsProps) => {
  const isActiveTab = (current: string, currentIndex: number) => {
    return selectedTab === '#' + trimText(current, '_').toLowerCase() || (!selectedTab && currentIndex === 0);
  };

  return (
    <div className="flex flex-row items-center justify-start p-2 gap-x-4 h-14 bg-white rounded-xl max-sm:gap-0 max-sm:p-0 max-sm:gap-x-0.5">
      {tabs.map((tab, index) => (
        <div
          className={cn(
            isActiveTab(tab, index)
              ? 'bg-primary text-white text-base font-medium'
              : 'bg-white shadow-none text-neutral-700 text-base font-medium',
            'w-full h-10 px-3.5 py-2.5 cursor-pointer rounded-lg shadow justify-center items-center gap-2 inline-flex hover:bg-primary hover:text-white max-sm:text-xxs max-sm:p-0.5'
          )}
          key={index}
          onClick={() => onSelectTab(trimText(tab, '_').toLowerCase())}>
          {tab}
        </div>
      ))}
    </div>
  );
};

export default Tabs;

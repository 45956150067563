const Instruction = () => {
  return (
    <div className="max-w-3xl mx-auto p-6 bg-white rounded-lg shadow-md overflow-auto">
      <h1 className="text-2xl font-bold mb-4"><strong>Step 1:</strong> Generating App Password</h1>

      <div className="mb-6">
        <h2 className="text-xl font-semibold">For Gmail Account:</h2>
        <ul className="list-disc pl-6 mt-2">
          <li>
            <strong>Sign In to Your Google Account: </strong>
             Visit <strong>https://myaccount.google.com/</strong>  and sign in if you&apos;re not logged in. You can also
            access this by clicking your profile picture in Gmail.
          </li>
          <li><strong>Navigate to Security Settings:</strong> Go to the &quot;Security&ldquo; section in the left-hand sidebar.</li>
          <li><strong>Enable 2-Step Verification:</strong> If not already enabled, set up 2-Step Verification.</li>
          <li>
            <strong>Access App Passwords:</strong> In the search bar of your Google Account settings, type &quot;app passwords.&ldquo;
          </li>
          <li><strong>Generate the App Password:</strong>  Click &quot;Generate&ldquo; to create a new app password.</li>
          <li>
           <strong>Use the App Password:</strong> Copy the 16-digit password (without space) and use it in the DeedDex app where you need to sign in.
          </li>
          <li>
            <strong>Host:</strong> imap.gmail.com
          </li>
          <li>
            <strong>Email:</strong> [your_email@gmail.com]
          </li>
          <li>
            <strong>Password:</strong> [your_generated_app_password]
          </li>
        </ul>
      </div>

      <div className="mb-6">
        <h2 className="text-xl font-semibold">For Outlook/Hotmail Accounts:</h2>
        <ul className="list-disc pl-6 mt-2">
          <li>
            <strong>Outlook:</strong> Use &apos;outlook.office365.com&lsquo; as the host
          </li>
          <li>
            <strong>Hotmail:</strong> Use &apos;imap-mail.outlook.com&lsquo; as the host.
          </li>
          <li>
            <strong>Email:</strong> [your_email@outlook.com or your_email@hotmail.com]
          </li>
          <li>
            <strong>Password:</strong> [your_email_password]
          </li>
        </ul>
      </div>

      <h1 className="text-2xl font-bold mb-4">Step 2: Enabling POP3 and IMAP</h1>

      <div className="mb-6">
        <h2 className="text-xl font-semibold">Gmail</h2>
        <ul className="list-disc pl-6 mt-2">
          <li><strong>Sign in</strong> to your Gmail account.</li>
          <li>Click the <strong>gear icon</strong> (⚙️) in the top right corner and select <strong>See all settings.</strong></li>
          <li>Go to the <strong>Forwarding and POP/IMAP</strong> tab.</li>
          <li>
            In the <strong>POP Download</strong> section, select <strong>Enable POP for all mail</strong> or <strong>Enable POP for mail that arrives from now on.</strong>
          </li>
          <li>In the <strong>IMAP Access</strong> section, select <strong>Enable IMAP.</strong></li>
          <li>Click <strong>Save Changes</strong> at the bottom.</li>
        </ul>
      </div>

      <div className="mb-6">
        <h2 className="text-xl font-semibold">Outlook (Microsoft 365/Outlook.com)</h2>
        <ul className="list-disc pl-6 mt-2">
          <li><strong>Sign in </strong> to your Outlook.com account.</li>
          <li>Click the <strong>gear icon</strong> (⚙️) in the top right corner and select <strong>View all Outlook settings.</strong></li>
          <li>Go to <strong>Mail &gt; Sync email.</strong></li>
          <li>Under <strong>IMAP and POP </strong>, select <strong>Enable</strong> for both POP and IMAP.</li>
          <li>Click <strong>Save.</strong></li>
        </ul>
      </div>

      <div className="mb-6">
        <h2 className="text-xl font-semibold">Yahoo Mail</h2>
        <ul className="list-disc pl-6 mt-2">
          <li><strong>Sign in</strong> to your Yahoo Mail account.</li>
          <li>Click your profile icon and select <strong>Account Info.</strong></li>
          <li>Go to <strong>Account Security.</strong></li>
          <li>Click <strong>Allow apps that use less secure sign in</strong> and toggle it on.</li>
          <li>Go to the <strong>Mail</strong> app settings and find the <strong>POP and IMAP</strong> section.</li>
          <li>Ensure that both POP and IMAP are enabled.</li>
        </ul>
      </div>

      <div className="mb-6">
        <h2 className="text-xl font-semibold">Apple Mail (iCloud)</h2>
        <ul className="list-disc pl-6 mt-2">
          <li><strong>Sign in</strong> to your iCloud account on the Apple ID website.</li>
          <li>Go to Account Settings.</li>
          <li>
            Under <strong>Mail</strong>, ensure that <strong>IMAP</strong> is enabled. POP is not available for iCloud mail, as it only supports IMAP.
          </li>
        </ul>
      </div>

      <div className="mb-6">
        <h2 className="text-xl font-semibold">Other Email Providers</h2>
        <ul className="list-disc pl-6 mt-2">
          <li><strong>Log in</strong> to your email account on your provider’s website.</li>
          <li>Look for settings related to <strong>Account, Mail Settings</strong>, or <strong>Forwarding.</strong></li>
          <li>Find the sections for <strong>POP</strong> and <strong>IMAP.</strong></li>
          <li>Enable both protocols as needed and save your changes.</li>
        </ul>
      </div>
    </div>
  );
};

export default Instruction;

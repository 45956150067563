import DropDown from 'src/modules/Emails/containers/DropDown';
import Icon from 'src/modules/General/components/Icon';
import useEmailsLeftColumn from '../RightColumn/index.services';
import ConfirmationModal from 'src/modules/General/containers/ConfirmationModal';
import Loading from 'src/modules/General/components/Loading';
import EmailAuthModal from 'src/modules/Emails/containers/EmailAuthModal';
import Snackbar from 'src/modules/General/components/Snackbar';
import { EmailProps } from 'src/modules/Emails/containers/EmailsList/index.types';
import { AddEmailsDataType } from 'src/modules/Emails/api';
import { useTranslation } from 'react-i18next';
export interface SnackbarProps {
  display: boolean;
  color: string;
  message: string;
}
interface HeaderMenuProps {
  emailsList: EmailProps[];
  isLoading: boolean;
  onRemoveEmailClick: (email: string) => void;
  onRemoveEmail: () => Promise<void>;
  updateEmailsList: (call: boolean) => Promise<void>;
  onAddEmail: (formData: AddEmailsDataType) => Promise<void>;
  setOpenEmailModal: React.Dispatch<React.SetStateAction<boolean>>;
  openConfirmationModal: {
    name: 'remove' | 'deactivate';
    open: boolean;
  };
  closeConfirmationModal: () => void;
  showSnackbar: SnackbarProps;
  handleCloseSnackbar: () => void;
  openEmailModal: boolean;
}

const HeaderMenu = ({
  emailsList,
  onAddEmail,
  isLoading,
  onRemoveEmail,
  onRemoveEmailClick,
  showSnackbar,
  updateEmailsList,
  handleCloseSnackbar,
  openConfirmationModal,
  closeConfirmationModal,
  openEmailModal,
}: HeaderMenuProps) => {
  const { setOpenEmailModal, onEmailClick, onDeactivateEmail } = useEmailsLeftColumn();
  const { t } = useTranslation();
  const isOpenConfirmationModal = openConfirmationModal.open;
  const isRemoveAction = openConfirmationModal.name === 'remove';
  const messageConfirmationModal = `${t('Are you sure to')} ${openConfirmationModal.name} ${t('this Email Account')}?`;
 
  return (
    <div className="w-full h-15 bg-white rounded-md flex items-center justify-between p-1">
      <div className="h-full flex items-center justify-start gap-1">
        <Icon
          onClick={() => setOpenEmailModal(true)}
          name="add"
          className="w-full flex items-center justify-center flex-row bg-primary p-2 rounded-lg cursor-pointer hover:bg-dark-blue-3"
        />
        <Icon
          onClick={() => updateEmailsList(false)}
          name="refresh"
          className="w-full flex items-center justify-center flex-row bg-primary p-2 rounded-lg cursor-pointer hover:bg-dark-blue-3"
          width={20}
          height={20}
        />
      </div>
      <div className="h-full flex items-center justify-start gap-x-2">
        <p className="max-sm:text-sm">{t('Emails')} :</p>
        <DropDown onEmailClick={onEmailClick} onRemoveEmail={onRemoveEmailClick} emails={emailsList} />
      </div>
      <EmailAuthModal
        open={openEmailModal}
        onClose={() => setOpenEmailModal(false)}
        onSubmitAddEmail={onAddEmail}
        isLoading={isLoading}
      />
      {showSnackbar.display && (
        <Snackbar
          color={showSnackbar.color}
          open={showSnackbar.display}
          onClose={handleCloseSnackbar}
          message={showSnackbar?.message}
        />
      )}
      <ConfirmationModal
        open={isOpenConfirmationModal}
        onClose={closeConfirmationModal}
        text={messageConfirmationModal}
        buttons={[
          {
            children:t('Remove'),
            variant: 'contained',
            color: 'error',
            onClick:  onRemoveEmail ,
            endIcon: isLoading && <Loading variant="circle" size={20} color="inherit" />,
          },
          {
            children: t('Cancel'),
            variant: 'contained',
            color: 'success',
            onClick: closeConfirmationModal,
          },
        ]}
      />
    </div>
  );
};
export default HeaderMenu;

import CopyIcon from 'src/assets/images/Queries/copy.svg';
import SaveIcon from 'src/assets/images/General/save.svg';
import MoreIcon from 'src/assets/images/Note/more.svg';
import EditIcon from 'src/assets/images/Note/edit.svg';
import VoiceIcon from 'src/assets/images/Note/voice.svg';
import TrashIcon from 'src/assets/images/Note/trash.svg';
import StopIcon from 'src/assets/images/Note/stop.svg';
import keyUpIcon from 'src/assets/images/Note/keyUp.svg';
import keyDownIcon from 'src/assets/images/Note/keyDown.svg';
import {
  Autocomplete,
  Box,
  Collapse,
  Divider,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import NoteServices, { NoteDataTable } from './insex.services';
import { copyToClipboard, getNodeText } from 'src/core/utilities/texts';
import { formatDate } from 'src/core/utilities/general';
import MultiSelect from 'src/modules/Queries/components/MultiSelect';
import Header from 'src/modules/General/containers/Layout/Header';
import { useState } from 'react';
import { Fragment } from 'react/jsx-runtime';
import { Note } from './insex.services';
import Loading from 'src/modules/General/components/Loading';
import Snackbar from 'src/modules/General/components/Snackbar';
import ConfirmationModal from 'src/modules/General/containers/ConfirmationModal';
import ShowMoreModal from 'src/modules/Note/components/ShowMoreModal';
import { useTranslation } from 'react-i18next';

// function Row(props: { row: NoteDataTable; onEdit: (selected: Note, label: string) => void }) {
//   const { row } = props;
//   const [open, setOpen] = useState(false);
//   return (
//     <Fragment>
//       <TableRow sx={{ '& > *': { borderBottom: 'unset' } }}>
//         <TableCell>
//           <button className="rounded-none flex flex-row items-center gap-2" onClick={() => setOpen(!open)}>
//             {open ? <img src={keyUpIcon} alt="Up" /> : <img src={keyDownIcon} alt="Down" />}
//             {row.label.label}
//           </button>
//         </TableCell>

//         <TableCell align="right">
//           <button className="w-7 h-7 border-none">
//             <img src={TrashIcon} alt="Delete" />
//           </button>
//         </TableCell>
//       </TableRow>
//       <TableRow>
//         <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={5}>
//           <Collapse in={open} timeout="auto" unmountOnExit>
//             <Box sx={{ margin: 1 }}>
//               <Typography variant="h6" gutterBottom component="div">
//                 Notes
//               </Typography>
//               <Table size="small" aria-label="purchases">
//                 <TableHead>
//                   <TableRow>
//                     <TableCell align="left">
//                       <p className="font-extrabold">Content</p>
//                     </TableCell>
//                     <TableCell align="left">
//                       <p className="font-extrabold">Created Date</p>
//                     </TableCell>
//                     <TableCell align="left"></TableCell>
//                     <TableCell align="left"></TableCell>
//                     <TableCell align="left"></TableCell>
//                   </TableRow>
//                 </TableHead>
//                 <TableBody>
//                   {row.notes.map((noteData: Note) => (
//                     <TableRow key={noteData.id}>
//                       <TableCell component="th" scope="row">
//                         <p>
//                           {noteData.content.length > 80 ? noteData.content.substring(0, 81) + '...' : noteData.content}
//                         </p>
//                       </TableCell>
//                       <TableCell>{formatDate(noteData.createDate)}</TableCell>
//                       <TableCell align="right">
//                         <button onClick={() => props.onEdit(noteData, row.label.label)} className="w-7 h-7 border-none">
//                           <img src={EditIcon} alt="edit" />
//                         </button>
//                       </TableCell>
//                       <TableCell align="right">
//                         <button className="w-7 h-7 border-none">
//                           <img src={TrashIcon} alt="Delete" />
//                         </button>
//                       </TableCell>
//                       <TableCell align="right">
//                         <button className="w-7 h-7 border-none">
//                           <img src={MoreIcon} alt="More" />
//                         </button>
//                       </TableCell>
//                     </TableRow>
//                   ))}
//                 </TableBody>
//               </Table>
//             </Box>
//           </Collapse>
//         </TableCell>
//       </TableRow>
//     </Fragment>
//   );
// }
const Notes = () => {
  const {
    note,
    setNote,
    onEdit,
    onListening,
    listening,
    deleteNoteMsg,
    items,
    onChangeLabel,
    noteList,
    itemSelected,
    addNewNote,
    updateNote,
    noteId,
    loading,
    loadingFetch,
    displaySnackbar,
    handleCloseSnackbar,
    openDeleteNoteModal,
    onDelete,
    closeDeleteNoteDialog,
    removeNote,
    openModalShowMore,
    openShowMoreModal,
    closeModalShowMore,
    noteContent,
    noteDate,
    supported,
  } = NoteServices();
  const { t } = useTranslation();
  return (
    <>
      <Header />
      <div className="w-sideBar h-full px-4 py-8  flex flex-col gap-4  bg-Gray-ED overflow-hidden max-lg:h-calc-120 max-lg:w-full">
        {displaySnackbar.display && (
          <Snackbar
            color={displaySnackbar.color}
            open={displaySnackbar.display}
            onClose={handleCloseSnackbar}
            message={displaySnackbar?.message}
          />
        )}
        <div className="w-full flex flex-col gap-4">
          <div className="w-full">
            <div className="w-full flex flex-col relative">
              {listening && !loading && supported && (
                <button
                  disabled={loading}
                  className="p-2 bg-gray-200 rounded-full absolute top-7 right-2"
                  onClick={onListening}>
                  <img src={StopIcon} alt="Stop" className="w-5 h-5" />
                </button>
              )}
              {!listening && (!note || loading) && supported && (
                <button
                  disabled={loading}
                  className="p-2 bg-gray-200 rounded-full absolute top-7 right-2"
                  onClick={onListening}>
                  <img src={VoiceIcon} alt="Voice" className="w-5 h-5" />
                </button>
              )}
              {!listening && note && (
                <button
                  disabled={loading}
                  className="p-2 bg-gray-200 rounded-full absolute top-7 right-2"
                  onClick={deleteNoteMsg}>
                  <img src={TrashIcon} alt="Delete" className="w-5 h-5" />
                </button>
              )}

              <label className="text-neutral-950 text-sm font-normal">Note</label>
              <textarea
                onChange={(e) => setNote(e.target.value)}
                value={note}
                placeholder={t('Type here ...')}
                className="px-6 py-2.5 pr-12 bg-white rounded-lg  resize-none text-base font-normal leading-tight border-none outline-none"
                rows={6}></textarea>
              <button
                onClick={() => copyToClipboard(getNodeText(note))}
                className="p-2 bg-gray-200 rounded-full absolute bottom-2 right-2 z-30">
                <img src={CopyIcon} alt="Copy" />
              </button>
            </div>
          </div>
          <div className="w-full flex flex-row items-end justify-between max-sm:block">
            <div className="flex flex-col gap-1 w-80 max-sm:mb-2 max-sm:w-full">
              <label htmlFor="item" className="text-neutral-950 text-sm font-normal">
                {t('Label')}
              </label>
              <Autocomplete
                disabled={true}
                sx={{
                  display: 'inline-block',
                  '& input': {
                    width: ' 100%',
                    bgcolor: 'background.paper',
                    color: (theme) => theme.palette.getContrastText(theme.palette.background.paper),
                  },
                }}
                onChange={onChangeLabel}
                value={itemSelected}
                id="custom-input-demo"
                options={items}
                getOptionLabel={(option) => option.label}
                renderOption={(props, option) => (
                  <Box component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props}>
                    {option.label}
                  </Box>
                )}
                renderInput={(params) => (
                  <div ref={params.InputProps.ref}>
                    <input
                      type="text"
                      {...params.inputProps}
                      className="w-full h-10 px-3.5 py-2.5 bg-white rounded-lg shadow border border-gray-200 text-base font-normal leading-tight text-black"
                    />
                  </div>
                )}
              />
            </div>
            <button
              onClick={noteId ? updateNote : addNewNote}
              disabled={loading || note == ''}
              className=" h-11 px-4 py-3 bg-blue-600 rounded-xl shadow justify-center items-center gap-2 inline-flex text-white text-base font-medium capitalize leading-tight max-sm:w-full disabled:bg-gray-200">
              {loading && note && <Loading color="primary" size={20} variant="circle" />}
              <img src={SaveIcon} alt="Save" />
              {noteId && note ? t('Update Note') : t('Add Note')}
            </button>
          </div>
        </div>
        <Divider></Divider>
        <div className="w-full rounded-lg overflow-y-auto">
          {loadingFetch && <Loading size={40} variant="circle" color="primary" />}
          {noteList?.notes && noteList.notes.length > 0 && (
            <TableContainer className="bg-white rounded-lg shadow">
              {/* <Table aria-label="collapsible table">
              <TableHead>
                <TableRow>
                  <TableCell>
                    {' '}
                    <p className="pl-8 font-extrabold">Label</p>
                  </TableCell>
                  <TableCell></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {noteList.map((noteData, index: number) => (
                  <Row key={index} row={noteData} onEdit={onEdit} />
                ))}
              </TableBody>
            </Table> */}
              <Table aria-label="collapsible table">
                <TableHead>
                  <TableRow>
                    <TableCell>
                      {' '}
                      <p className="font-extrabold">{t('Note')}</p>
                    </TableCell>
                    <TableCell>{t('Created Date')}</TableCell>
                    <TableCell></TableCell>
                    <TableCell></TableCell>
                    <TableCell></TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {noteList?.notes.map((note) => (
                    <TableRow key={note.NoteID}>
                      <TableCell component="th" scope="row">
                        <p>{note.Note.length > 80 ? note.Note.substring(0, 81) + '...' : note.Note}</p>
                      </TableCell>
                      <TableCell>{formatDate(note.CreatedDateTime)}</TableCell>
                      <TableCell align="right">
                        <button onClick={() => onEdit(note, '')} className="w-7 h-7 border-none">
                          <img src={EditIcon} alt="edit" />
                        </button>
                      </TableCell>
                      <TableCell align="right">
                        <button onClick={() => onDelete(note.NoteID)} className="w-7 h-7 border-none">
                          <img src={TrashIcon} alt="Delete" />
                        </button>
                      </TableCell>
                      <TableCell align="right">
                        <button onClick={() => openModalShowMore(note)} className="w-7 h-7 border-none">
                          <img src={MoreIcon} alt="More" />
                        </button>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          )}
        </div>
        <ConfirmationModal
          open={openDeleteNoteModal}
          onClose={closeDeleteNoteDialog}
          text={t('Are you sure to delete this note?')}
          buttons={[
            {
              children: t('Remove'),
              variant: 'contained',
              color: 'error',
              onClick: removeNote,
              endIcon: loading && <Loading variant="circle" size={20} color="inherit" />,
            },
            {
              children: t('Cancel'),
              variant: 'contained',
              color: 'success',
              onClick: closeDeleteNoteDialog,
            },
          ]}
        />
        <ShowMoreModal open={openShowMoreModal} onClose={closeModalShowMore} note={noteContent} noteDate={noteDate} />
      </div>
    </>
  );
};

export default Notes;

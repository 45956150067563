import { useEffect, useState } from 'react';
import Icon from 'src/modules/General/components/Icon';
import { textBeutify } from 'src/core/utilities/texts';
import { sortAlphabeticallyViaProperty, sortViaProperty } from 'src/core/utilities/general';
import { FilesListProps, HeaderType } from './index.types';
import styles from './index.module.scss';

const FilesList = ({ items, onUploadFiles }: FilesListProps) => {
  const [sortedFiles, setSortedFiles] = useState(items);
  const [sortedField, setSortedField] = useState<HeaderType | null>(null);
  const headers = items.length ? Object.keys(items[0]).filter(item => item !== 'id' && item !== 'icon') : [];

  const onSortFiles = (header: HeaderType, sort: 'up' | 'down') => {
    if (header === 'shareDate') {
      setSortedFiles(sortViaProperty([...sortedFiles], 'shareDate', sort));
    } else {
      setSortedFiles(sortAlphabeticallyViaProperty([...sortedFiles], header, sort));
    }
    setSortedField(sort === 'up' ? header : null);
  };

  useEffect(() => {
    setSortedFiles(items);
  }, [items]);

  return (
    <div className={styles['container']}>
      <div className={styles['container__title']}>
        <h5>Files</h5>
        <label htmlFor="file" className={styles['container__add']}>
          <Icon name="add" />
        </label>
        <input id="file" type="file" onChange={onUploadFiles} />
      </div>

      <div className={styles['headers']}>
        {!!headers?.length &&
          headers.map(
            header =>
              header !== 'id' && (
                <div key={header} className={styles['headers__title']}>
                  {textBeutify(header)}
                  <Icon
                    name={sortedField === header ? 'arrow-down' : 'arrow-up'}
                    onClick={() => onSortFiles(header as HeaderType, sortedField === header ? 'down' : 'up')}
                  />
                </div>
              ),
          )}
      </div>
      <div className={styles['container__files']}>
        <div className={styles['list']}>
          {!!sortedFiles?.length &&
            sortedFiles.map(file => (
              <div key={file.id} className={styles['list__items']}>
                <div className={styles['list__item']}>{file.name}</div>
                <div className={styles['list__item']}>{file?.sharedBy || 'Me'}</div>
                <div className={styles['list__item']}>
                  {file?.shareDate?.toLocaleString().split(',')[0].split(',')[0]}
                </div>
              </div>
            ))}
        </div>
      </div>
    </div>
  );
};

export default FilesList;

// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Screenshot_container__cJ2xo {
  position: absolute;
  border: 2.5px dashed #0e1d54;
  background-color: rgba(14, 29, 84, 0.3);
  z-index: 1000;
}`, "",{"version":3,"sources":["webpack://./src/modules/General/containers/Screenshot/index.module.scss"],"names":[],"mappings":"AAEA;EACI,kBAAA;EACA,4BAAA;EACA,uCAAA;EACA,aAAA;AADJ","sourcesContent":["@import 'src/styles/constants/variables';\n\n.container {\n    position: absolute;\n    border: 2.5px dashed $color-primary;\n    background-color: rgba($color: $color-primary, $alpha: 0.3);\n    z-index: 1000;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `Screenshot_container__cJ2xo`
};
export default ___CSS_LOADER_EXPORT___;

import Notification from 'src/modules/Notifications/components/Notification';
import useNotificationsLeftColumn from './index.services';
import { NotificationData } from 'src/modules/Notifications/api';
import Loading from 'src/modules/General/components/Loading';
import NotificationEmpty from 'src/modules/Notifications/components/NotificationEmpty';

const LeftColumn = (props: { isLoading: boolean; notifications: NotificationData[] }) => {
  // const { notifications, loading } = useNotificationsLeftColumn();

  return (
    <div className="w-full h-full overflow-auto  flex justify-start flex-col gap-y-2">
      {props?.isLoading ? (
        <div className="w-full h-full flex flex-col items-center justify-center">
          <Loading variant="circle" size={44} color="primary" />
        </div>
      ) : props?.notifications?.length > 0 ? (
        props?.notifications?.map((notification: NotificationData, index: number) => (
          <Notification notification={notification} notifications={props?.notifications} key={index} />
        ))
      ) : (
        <NotificationEmpty />
      )}
    </div>
  );
};

export default LeftColumn;

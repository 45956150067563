import axios, { AxiosRequestConfig, AxiosResponse } from 'axios';
import { detectService } from '../utilities/apis';
import { setError } from 'src/modules/General/store/error-slice';
import { store } from 'src/_app';
import { refreshToken } from 'src/modules/General/api';
import useAuth from '../hooks/useAuth';

const client = axios.create({
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json',
    env: process.env.REACT_APP_ENVIORMENT,
    Source: 'web',
    // 'Accept-Language': localStorage.getItem('language'),
  },
});

client.defaults.headers.common['Authorization'] = `Bearer ${localStorage.getItem('access_token')}`;

export function setAuthToken(tokenType: string, accessToken: string) {
  client.defaults.headers.common['Authorization'] = `${tokenType} ${accessToken}`;
}

let isRefreshing = false;
let failedQueue: any[] = [];

const processQueue = (error: any, token: string | null = null) => {
  failedQueue.forEach((prom) => {
    if (token) {
      prom.resolve(token);
    } else {
      prom.reject(error);
    }
  });

  failedQueue = [];
};


//Before Request
client.interceptors.request.use(
  function (config) {
    return config;
  },
  function (error) {
    console.error('Request error:');
    if (error?.response?.data?.detail) {
      store.dispatch(setError({ message: error?.response?.data?.detail }));
    } else {
      store.dispatch(setError({ message: error.message }));
    }
    console.log(error)
    return Promise.reject(error);
  }
);

client.interceptors.response.use(
  (response) => response,
  async (error) => {
    if(error?.response?.request?.responseURL == detectService('/refresh-token') && error?.response?.status === 401){
      localStorage.removeItem('access_token');
      localStorage.removeItem('refresh_token');
      localStorage.removeItem('userData');
      localStorage.removeItem('language');
      localStorage.removeItem('i18nextLng');
      window.location.reload();
      return
    }
    if (error?.response?.request?.responseURL == detectService('/login')){
      store.dispatch(setError({ message: error?.response?.data?.detail }));
      return
    }
    const originalRequest = error.config;

    if (error.response.status === 401 && !originalRequest._retry) {
      if (!isRefreshing) {
        isRefreshing = true;
        originalRequest._retry = true;

        try {
          const newTokens = await getAccessToken(localStorage.getItem('refresh_token') ?? '');
          console.log(newTokens)
          client.defaults.headers.common['Authorization'] = `Bearer ${newTokens}`
          setAuthToken('Bearer', newTokens.access_token);
          processQueue(null, newTokens.access_token);
          return client(originalRequest);
        } catch (err) {
          processQueue(err, null);
          return Promise.reject(err);
        } finally {
          isRefreshing = false;
        }
      } else {
        return new Promise((resolve, reject) => {
          failedQueue.push({
            resolve: (token: string) => {
              console.log(token)
              originalRequest.headers['Authorization'] = 'Bearer ' + token;
              resolve(client(originalRequest));
            },
            reject: (err: any) => {
              reject(err);
            },
          });
        });
      }
    }

    return Promise.reject(error);
  }
);

const getAccessToken = async (refresh_token: string) => {
  const loginRespond = await refreshToken(refresh_token);
  if (loginRespond === 'Invalid refresh token') {
    localStorage.removeItem('access_token');
    localStorage.removeItem('refresh_token');
    localStorage.removeItem('userData');
    window.location.reload();
    return;
  }

  localStorage.setItem('access_token', loginRespond.access_token);
  localStorage.setItem('refresh_token', loginRespond.refresh_token);

  setAuthToken('Bearer', loginRespond.access_token);
  return loginRespond;
};

const request = {
  get: <T>(endpoint: string, options?: AxiosRequestConfig): Promise<AxiosResponse<T>> => {
    return client.get(detectService(endpoint), options);
  },
  post: <T>(endpoint: string, data: unknown, options?: AxiosRequestConfig): Promise<AxiosResponse<T>> => {
    return client.post(detectService(endpoint), data, options);
  },
  put: (endpoint: string, data: unknown, options?: AxiosRequestConfig): Promise<AxiosResponse> => {
    return client.put(detectService(endpoint), data, options);
  },
  patch: (endpoint: string, options?: AxiosRequestConfig): Promise<AxiosResponse> => {
    return client.patch(detectService(endpoint), options);
  },
  delete: (endpoint: string, options?: AxiosRequestConfig): Promise<AxiosResponse> => {
    return client.delete(detectService(endpoint), options);
  },
  options: (endpoint: string, options?: AxiosRequestConfig): Promise<AxiosResponse> => {
    return client.options(detectService(endpoint), options);
  },
  head: (endpoint: string, options?: AxiosRequestConfig): Promise<AxiosResponse> => {
    return client.head(detectService(endpoint), options);
  },
};

export default request;

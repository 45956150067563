import BottomNavigation from '../BottomNavigation';
import Sidebar from '../Sidebar';

const Navigation = () => {
  return (
    <>
      <div className="max-lg:hidden">
        <Sidebar />
      </div>
      <div className="hidden w-full h-16 max-lg:block">
        <BottomNavigation />
      </div>
    </>
  );
};
export default Navigation;

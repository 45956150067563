import Folders from 'src/modules/Files/containers/Folders';
import FilesList from 'src/modules/Files/containers/FilesList';
import useFilesRightColumn from './index.services';

const RightColumn: React.FC = () => {
  const { folderItems, onUploadFolders, fileItems, onUploadFiles } = useFilesRightColumn();

  return (
    <div className="flex flex-col py-8 px-4 gap-6">
      <Folders items={folderItems} onUploadFolders={onUploadFolders} />
      <FilesList items={fileItems} onUploadFiles={onUploadFiles} />
    </div>
  );
};

export default RightColumn;

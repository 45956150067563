import Header from 'src/modules/General/containers/Layout/Header';
import LeftColumn from './LeftColumn';
import RightColumn from './RightColumn';

const Files: React.FC = () => {
  return (
    <>
      <Header />
      <div className="w-sideBar flex gap-4 py-8 px-4 h-full max-lg:h-calc-120 max-lg:w-full">
        <div className="w-1/4">
          <LeftColumn />
        </div>
        <div className="w-3/4 bg-light-blue-2 rounded-lg">
          <RightColumn />
        </div>
      </div>
    </>
  );
};

export default Files;

import Modal from 'src/modules/General/components/Modal';
import { TextModalProps } from './index.types';
import { formatDate } from 'src/core/utilities/general';
import { useTranslation } from 'react-i18next';

const ShowMoreModal = ({ onClose, open, note, noteDate }: TextModalProps) => {
  const { t } = useTranslation();
  return (
    <Modal open={open} onClose={onClose} customBoxCss="max-w-4xl">
      <div className="h-96 w-200 max-lg:max-w-125  max-md:max-w-sm max-sm:max-w-80 bg-white flex-col justify-start items-start gap-4 inline-flex overflow-y-auto">
        <div className="w-full  flex flex-col items-center justify-start gap-y-2">
          <span className="w-full text-neutral-950 text-lg font-extrabold">{t('Note')}</span>
          <span className="w-full text-neutral-950 text-lg font-extrabold">{t('Created Date')}: {formatDate(noteDate)}</span>
        </div>
        <div className="w-full">
          <p>{note}</p>
        </div>
      </div>
    </Modal>
  );
};

export default ShowMoreModal;

import { useAppContext } from 'src/core/contexts/_app.context';
import { Router } from 'src/core/router';
import cn from 'classnames';
import Snackbar from '@mui/material/Snackbar';

const Page = () => {
  const { dispatch, state } = useAppContext();
  const { screenshotMode, statusUpload } = state || {};
  const handleClose = () => {
    dispatch({ type: 'statusUpload', value: '' });
  };

  return (
    <div className={cn('app__container', 'h-full', screenshotMode && 'app__container--selected')}>
      {statusUpload !== '' && (
        <Snackbar
          anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
          open={statusUpload !== ''}
          autoHideDuration={8000}
          onClose={handleClose}
          message={statusUpload}
          color="info"
        />
      )}
      <Router />
    </div>
  );
};

export default Page;

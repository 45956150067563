import { Avatar } from '@mui/material';
import { CustomAvatarProps } from './index.types';

const CustomAvatar = ({ src, name = '', pro = false, sx, ...props }: CustomAvatarProps) => {
  const fontSize = `${Math.min(Math.max(0.5, 1.4 - name.length * 0.1), 0.7)}rem`;
  const badgeStyle = { fontSize };

  return (
    <div className="relative" style={badgeStyle}>
      <Avatar alt={name || 'user'} src={src} {...props} sx={{ borderRadius: '30%', ...sx }} />
      {/* {pro && <span className={cn(styles['badge'], styles['badge--pro'])}>pro</span>} */}
      {name && (
        <span className="absolute p-1 left-1/2 bottom-[-15%] font-bold text-center rounded bg-white text-dark-blue-3">
          {name}
        </span>
      )}
    </div>
  );
};

export default CustomAvatar;

import { ListProps, ItemProps } from './index.types';
import TrashIcon from 'src/assets/images/Queries/trash.svg';
import useList from './index.services';
import ConfirmationModal from '../ConfirmationModal';
import Loading from '../../components/Loading';
import { useTranslation } from 'react-i18next';

const List = ({ list, onSelectItem, className = '' }: ListProps) => {
  const { closeDailog, openConfirmationModal, openDialog, deleteDocument, isLoading } = useList();
  function reverseAndExtractAfterSeparator(input: string, separator: string): string | null {
    const reversedString = input.split('').reverse().join('');
    const parts = reversedString.split(separator);

    return parts.length > 1 ? parts[0].split('').reverse().join('') : null;
  }

  const { t } = useTranslation();

  return (
    <div
      className={`${className} w-full h-calc-80 bg-white pr-1 flex items-center justify-start gap-y-4 flex-col overflow-y-auto`}>
      {!!list.length &&
        list.map((item: ItemProps) => (
          <div
            key={item.file_id}
            className="w-full h-72 px-4 py-6 bg-stone-50 rounded-2xl border border-gray-200 flex flex-row justify-normal items-center">
            <div
              className="w-calc-28 flex items-center justify-normal text-left cursor-pointer"
              onClick={() => onSelectItem?.(String(item.file_link), Number(item?.file_id))}>
              <p className="text-left w-full text-neutral-700 text-base font-normal leading-tight truncate">
                {item.content}
              </p>
            </div>
            <div
              onClick={() =>
                openDialog(
                  item?.content ?? '',
                  reverseAndExtractAfterSeparator(item.file_link, '_') ?? '',
                  Number(item.file_id),
                  item.file_link
                )
              }
              className="w-7 h-7 cursor-pointer flex items-center justify-end">
              <img src={TrashIcon} className="w-6 h-6" alt="trash" />
            </div>
          </div>
        ))}

      <ConfirmationModal
        open={openConfirmationModal}
        onClose={closeDailog}
        text={'Are you sure to delete this file?'}
        buttons={[
          {
            children: t('Remove'),
            variant: 'contained',
            color: 'error',
            onClick: deleteDocument,
            endIcon: isLoading && <Loading variant="circle" size={20} color="inherit" />,
          },
          {
            children: t('Cancel'),
            variant: 'contained',
            color: 'success',
            onClick: closeDailog,
          },
        ]}
      />
    </div>
  );
};

export default List;

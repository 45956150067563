import { useState } from 'react';
import { FilterModalProps } from './index.types';
import Loading from 'src/modules/General/components/Loading';
import Input from 'src/modules/General/components/Input';
import Modal from 'src/modules/General/components/Modal';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { Dayjs } from 'dayjs';
import MultiSelect from 'src/modules/Queries/components/MultiSelect';
import { RecentDataType } from '../../api';
import useQueriesRightColumn from 'src/pages/Queries/RightColumn/index.services';
import { useTranslation } from 'react-i18next';

export interface Item {
  label: string;
  value: string;
}

const FilterModal = ({ isLoading, isLoadingFilter, onClose, open, onSubmitFilter, isFilter }: FilterModalProps) => {
  const { items } = useQueriesRightColumn();
  const [fromDate, setFromDate] = useState<Dayjs | null>(null);
  const [toDate, setToDate] = useState<Dayjs | null>(null);
  const [itemSelected, setItemSelected] = useState<Item[]>([]);
  const [tagSelected, setTagSelected] = useState<string[]>([]);
  const { t } = useTranslation();
  const changeTag = (value: Item[]) => {
    setItemSelected(value);
    setTagSelected(value.map((item) => item.value));
  };

  const handleFilter = (isReset: boolean) => {
    const body: RecentDataType = {
      number_of_records: 400,
      page_size: 400,
    };
    if (isReset) {
      setFromDate(null);
      setToDate(null);
      changeTag([]);
      onSubmitFilter(body);

      return;
    }

    if (fromDate) {
      body.start_date = fromDate.format('YYYY-MM-DD');
    }
    if (toDate) {
      body.end_date = toDate.format('YYYY-MM-DD');
    }
    if (tagSelected.length > 0) {
      body.tag = tagSelected;
    }
    onSubmitFilter(body);
  };

  return (
    <Modal open={open} onClose={onClose} customBoxCss="max-w-2xl">
      <div className=" bg-white flex-col justify-start items-start gap-4 inline-flex">
        <div className="w-full  flex flex-col items-center justify-start gap-y-2">
          <span className="w-full text-neutral-950 text-lg font-medium font-['Inter']">{t('Filter')}</span>
        </div>
        <div className="w-full flex flex-col items-center justify-normal gap-4">
          <div className="w-full">
            <MultiSelect options={items} selectedOptions={itemSelected} onChange={changeTag} />
          </div>
          <div className="w-full">
            <label htmlFor="">{t('From')}</label>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DatePicker
                className="w-full"
                value={fromDate}
                onChange={(newValue) => {
                  setFromDate(newValue);
                }}
                slotProps={{
                  textField: {
                    size: 'small',
                    style: { backgroundColor: 'white' },
                  },
                }}
              />
            </LocalizationProvider>
          </div>
          <div className="w-full">
            <label htmlFor="">{t('To')}</label>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DatePicker
                className="w-full"
                value={toDate}
                onChange={(newValue) => {
                  setToDate(newValue);
                }}
                slotProps={{
                  textField: {
                    size: 'small',
                    style: { backgroundColor: 'white' },
                  },
                }}
              />
            </LocalizationProvider>
          </div>
        </div>
        <div className="grid grid-cols-3 flex-row items-center justify-center  gap-3 max-sm:grid-col-s-1">
          <button
            onClick={() => onClose('closeButtonClick')}
            className="w-full h-11 px-4 py-3 bg-white rounded-xl shadow border border-blue-600 justify-center items-center gap-2 inline-flex text-blue-600 text-base font-medium font-['Inter'] capitalize leading-tight">
            {t('Cancel')}
          </button>
          <button
            onClick={() => handleFilter(false)}
            disabled={isLoading}
            type="submit"
            className="h-11 px-4 py-3 bg-blue-600 rounded-xl shadow justify-center items-center gap-2 inline-flex text-white text-base font-medium font-['Inter'] capitalize leading-tight">
            {isLoading && <Loading variant="circle" size={20} color="inherit" />}
            {t('Submit')}
          </button>
          {isFilter && (
            <button
              onClick={() => handleFilter(true)}
              disabled={isLoading}
              type="submit"
              className="h-11 px-4 py-3 bg-blue-600 rounded-xl shadow justify-center items-center gap-2 inline-flex text-white text-base font-medium font-['Inter'] capitalize leading-tight">
              {isLoadingFilter && <Loading variant="circle" size={20} color="inherit" />}
              {t('Clear Filter')}
            </button>
          )}
        </div>
      </div>
    </Modal>
  );
};

export default FilterModal;

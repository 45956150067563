import Input from 'src/modules/General/components/Input';
import SearchIcon from 'src/assets/images/Queries/search.svg';
import { CustomInputProps } from './index.types';
import DropDown from '../../components/DropDown';
import { useTranslation } from 'react-i18next';

const SearchInput = ({
  onIconClick,
  onChangeInput,
  onMethodSearch,
  value,
  isLoading,
  disabledSearch,
  resetDropDown,
}: CustomInputProps) => {
  const onKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key !== 'Enter') return;
    if (onIconClick) onIconClick();
  };
  const { t } = useTranslation();

  return (
    <div className="w-full flex">
      <div className="w-calc-208">
        <Input
          disable={isLoading || disabledSearch}
          value={value}
          positionIcon="left"
          icon={<img src={SearchIcon} alt="search" />}
          placeholder={t('Type here to search...')}
          containerStyle="relative"
          handleKeyDown={onKeyDown}
          onIconClick={onIconClick}
          onChangeInput={onChangeInput}
          inputStyle="pl-10 rounded-l-lg"
        />
      </div>
      <div className="w-52">
        <DropDown reset={resetDropDown ?? false} onClickOption={onMethodSearch} />
      </div>
    </div>
  );
};

export default SearchInput;

import { useTranslation } from 'react-i18next';
import InboxIcon from 'src/assets/images/Emails/Mailbox.svg';
const EmailEmpty = () => {
  const { t } = useTranslation();
  return (
    <div className="w-full h-full flex-col justify-center items-center inline-flex gap-y-0.5">
      <img src={InboxIcon} alt="Inbox" />
      <span className="text-neutral-950 text-xl font-bold">{t('Please select an email account')}.</span>
    </div>
  );
};
export default EmailEmpty;

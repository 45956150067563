import { useState } from 'react';
import { InviteLinkModalProps } from './index.types';
import Loading from 'src/modules/General/components/Loading';
import Input from 'src/modules/General/components/Input';
import Modal from 'src/modules/General/components/Modal';
const InviteLinkModal = ({ isLoading, onClose, open, onSubmitEmail }: InviteLinkModalProps) => {
  const [email, setEmail] = useState<string>('');
  const [firstName, setFirstName] = useState<string>('');
  const [lastName, setLastName] = useState<string>('');
  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    const body = {
      Email: email,
      FirstName: firstName,
      LastName: lastName,
    };
    onSubmitEmail(body);
    setEmail('');
    setFirstName('');
    setLastName('');
  };
  return (
    <Modal open={open} onClose={onClose} customBoxCss="max-w-4xl">
      <div className=" bg-white flex-col justify-start items-start gap-4 inline-flex">
        <div className="w-full  flex flex-col items-center justify-start gap-y-2">
          <span className="w-full text-neutral-950 text-lg font-medium font-['Inter']">Add Email</span>
          <span className="font-bold w-full text-left">Enter your email Address:</span>
          <span className="w-full text-blue-600 text-xs font-normal font-['Inter']">
            Note: After registering your email, we will send an invitation link to your email.
          </span>
        </div>

        <form className="w-full flex items-center justify-normal flex-col gap-y-10 mt-4 " onSubmit={handleSubmit}>
          <div className="w-full flex items-center justify-center flex-col gap-y-2">
            <Input
              disable={isLoading}
              title="FirstName"
              type="text"
              onChange={(e) => setFirstName(e.currentTarget.value)}
              value={firstName}
              placeholder="First Name"
              containerStyle="gap-y-2"
              inputStyle="rounded-lg"
            />
            <Input
              disable={isLoading}
              title="LastName"
              type="text"
              onChange={(e) => setLastName(e.currentTarget.value)}
              value={lastName}
              placeholder="Last Name"
              containerStyle="gap-y-2"
              inputStyle="rounded-lg"
            />
            <Input
              disable={isLoading}
              title="Email"
              type="email"
              onChange={(e) => setEmail(e.currentTarget.value)}
              value={email}
              placeholder="Enter Your Email"
              containerStyle="gap-y-2"
              inputStyle="rounded-lg"
            />
          </div>
          <div className="grid grid-cols-2 flex-row items-center justify-center  gap-3 max-sm:grid-col-s-1">
            <button
              onClick={() => onClose('closeButtonClick')}
              className="w-full h-11 px-4 py-3 bg-white rounded-xl shadow border border-blue-600 justify-center items-center gap-2 inline-flex text-blue-600 text-base font-medium font-['Inter'] capitalize leading-tight">
              Cancel
            </button>
            <button
              disabled={isLoading}
              type="submit"
              className="h-11 px-4 py-3 bg-blue-600 rounded-xl shadow justify-center items-center gap-2 inline-flex text-white text-base font-medium font-['Inter'] capitalize leading-tight">
              {isLoading && <Loading variant="circle" size={20} color="inherit" />}
              Submit
            </button>
          </div>
        </form>
      </div>
    </Modal>
  );
};

export default InviteLinkModal;

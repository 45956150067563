export const detectService = (endpoint: string) => {
  //Later maybe will be added different services
  const port = '';
  // if (endpoint.includes('get_email_accounts') || endpoint.includes('get_recent_emails')) {
  //   port = ':8004';
  // } else if (endpoint.includes('login') || endpoint.includes('register') || endpoint.includes('introspect')) {
  //   port = ':8006';
  // }

  return process.env.REACT_APP_API_URL + port + endpoint;
};

import Chip from 'src/modules/General/components/Chip';
import { Button, Checkbox, FormControlLabel } from '@mui/material';
import Loading from 'src/modules/General/components/Loading';
import styles from './index.module.scss';
import cn from 'classnames';
import Input from 'src/modules/General/components/Input';
import { addImportant } from 'src/core/utilities/general';
import { useTranslation } from 'react-i18next';

interface AddedFilesProps {
  
  files: { id: number; name: string; type: 'screenshot' | 'file' }[];
  onDeleteAddedFile: (fileId: number) => void;
  onChangeNote: (note: string) => void;
  onConcatFiles: (checked: boolean) => void;
  onNotifNeeded: (checked: boolean) => void;
  checked?: boolean;
  onSubmitFiles: () => void;
  loading?: boolean;
  className?: string;
  actionsClassName?: string;
}

const AddedFiles = ({
  files,
  onDeleteAddedFile,
  onChangeNote,
  onConcatFiles,
  onNotifNeeded,
  onSubmitFiles,
  checked,
  loading = false,
  className = '',
  actionsClassName = '',
}: AddedFilesProps) => {
  const { t } = useTranslation();
  return (
    <div
      className={`${className} relative h-auto text-dark-blue-3 p-6 bg-white rounded-2xl flex-col justify-start items-start gap-y-6 inline-flex`}>
      {!!files.length && (
        <div className="w-full min-h-12 flex items-center justify-start gap-4 flex-wrap  overflow-y-auto max-sm:mb-11">
          {files.map((file) => (
            <Chip
              className="px-4 py-2 bg-Orange-25 rounded-2xl justify-start items-center gap-1 inline-flex text-center text-Gray-20 text-sm font-normal max-sm:text-xs"
              key={file.id}
              label={file.name}
              onDelete={() => onDeleteAddedFile(file.id)}
            />
          ))}
        </div>
      )}
      <div className="w-11/12 max-h-11 bottom-12 left-4 overflow-x-auto overflow-y-hidden flex items-center justify-start flex-row">
        <FormControlLabel
          control={<Checkbox disabled={files.length < 2} onChange={(e) => onConcatFiles(e.target.checked)} />}
          label={
            <span className="text-neutral-950 text-base font-normal leading-tight max-sm:text-xs">{t('Group all files')}</span>
          }
        />
        <FormControlLabel
          control={
            <Checkbox
              className="border-Orange-25"
              disabled={files.length === 0}
              checked={checked}
              onChange={(e) => onNotifNeeded(e.target.checked)}
            />
          }
          label={
            files.length > 1 ? (
              <span className="text-neutral-950 text-base font-normal leading-tight max-sm:text-xs">
                {t('Enable notification for all files')}
              </span>
            ) : (
              <span className="text-neutral-950 text-base font-normal leading-tight max-sm:text-xs">
                {t('Enable notification for this file')}
              </span>
            )
          }
          className="max-sm:text-xs"
        />
      </div>
      <div className={`${actionsClassName} w-full flex items-center justify-start flex-row gap-x-4 max-sm:block`}>
        <Input
          id="note"
          placeholder={t('Add a note...')}
          onChangeInput={(e) => {
            onChangeNote(e.target.value);
          }}
          inputStyle="rounded-lg"
        />
        <button
          className="h-11 px-4 py-3 rounded-xl bg-blue-600 rounded- shadow justify-center items-center gap-2 inline-flex text-white text-base font-medium capitalize leading-tight max-sm:text-xs max-sm:w-full max-sm:mt-2"
          disabled={!files.length || loading}
          onClick={onSubmitFiles}>
          {loading && <Loading variant="circle" size={20} color="inherit" />}
          {t('Submit')}
        </button>
      </div>
    </div>
  );
};

export default AddedFiles;

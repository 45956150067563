import { Outlet } from 'react-router-dom';
import { useSelector } from 'react-redux';
import Snackbar from 'src/modules/General/components/Snackbar';
import useExcludePaths from 'src/core/hooks/useExcludePaths';
import { store } from 'src/_app';
import { removeError } from 'src/modules/General/store/error-slice';
import { RootState } from 'src/core/store/_app.store';
import Navigation from './Navigation';

const Layout = () => {
  const isExludedPath = useExcludePaths();
  const { message } = useSelector((state: RootState) => state.error);

  return (
    <div className="w-full h-full flex flex-row-reverse max-lg:block">
      <Outlet />
      {!isExludedPath && <Navigation />}
      {!!message && (
        <Snackbar color="error" open={!!message} onClose={() => store.dispatch(removeError())} message={message} />
      )}
    </div>
  );
};

export default Layout;

import { MessageRespond } from '../../../../pages/Queries/LeftColumn/AddDocument/index.services';
import CheckIcon from 'src/assets/images/Queries/check.svg';
const FileUploaded = (data: MessageRespond) => {
  return (
    <div
      className={`${data.errorCode === 0 ? 'bg-white border-gray-200' : 'bg-rose-100 border-red-70'} w-full min-h-94 p-4  rounded-xl border  justify-start items-center gap-1 inline-flex map-sm:p-2`}>
      <div className="w-calc-24 flex items-center flex-col justify-start gap-1">
        {data.errorCode === 0 && (
          <p className="w-full text-neutral-700 text-sm font-bold max-sm:text-xs">{data.docName}</p>
        )}
        {data.errorCode !== 0 && (
          <>
            <p className="w-full text-left text-red-700 text-sm font-bold max-sm:text-xs">{data.message}</p>
            <p className="w-full text-left text-red-700 text-xs font-normal max-sm:text-xs">{data.docName}</p>
          </>
        )}
      </div>
      <div className="w-6 flex items-center justify-end flex-row">
        <div
          className={`${data.errorCode === 0 ? 'bg-emerald-600 border-emerald-600' : 'bg-red-600 border-red-600'} w-5 h-5 p-1 rounded-[10px] border  justify-center items-center inline-flex`}>
          {data.errorCode === 0 && <img src={CheckIcon} alt="check" />}
          {data.errorCode !== 0 && <span className="text-white">!</span>}
        </div>
      </div>
    </div>
  );
};

export default FileUploaded;

import { PreviewElementProps } from './index.types';
import styles from './index.module.scss';

const PreviewElement = ({ preview }: PreviewElementProps) => {
  if (preview) {
    return preview.includes('data:image') ? (
      <div className="flex rounded-lg max-w-3xl max-h-150 overflow-auto">
        <div className="w-full flex mb-1">
          <img src={preview as string} />
        </div>
      </div>
    ) : (
      <div className="w-full flex items-center justify-normal flex-row flex-nowrap gap-4 overflow-x-auto pb-1">
        {!!preview?.length &&
          (preview as string[]).map((p, i) => (
            <div key={i} className={styles['file']}>
              {p}
            </div>
          ))}
      </div>
    );
  } else {
    return null;
  }
};

export default PreviewElement;

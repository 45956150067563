import request from 'src/core/api/axios';

export interface BodyNote {
  text: string;
  label?: string;
}
export interface RespondNote {
  id: number;
  text: string;
}

export interface NoteData {
  NoteID: number;
  CreatedDateTime: string;
  Note: string;
}
export interface ListNotes {
  notes: NoteData[];
}

export interface NoteParams {
  number_of_records?: number;
  start_date?: string;
  end_date?: string;
}

export async function addNote(body: BodyNote): Promise<RespondNote | string> {
  try {
    const { data } = await request.post<RespondNote>(`/notes`, body);
    return data;
  } catch (error) {
    return 'Error Add Note';
  }
}
export async function deleteNote(note_id: number): Promise<RespondNote | string> {
  try {
    const { data } = await request.delete(`/notes/${note_id}`);
    return data;
  } catch (error) {
    return 'Error Delete Note';
  }
}
export async function editNote(note_id: number, text: string): Promise<RespondNote | string> {
  try {
    const { data } = await request.put(`/notes/${note_id}`, { text });
    return data;
  } catch (error) {
    return 'Error Update Note';
  }
}
export async function fetchNotes(params?: NoteParams): Promise<ListNotes | string> {
  try {
    let url = `/notes/`;
    const number_of_records = params?.number_of_records;
    const start_date = params?.start_date;
    const end_date = params?.end_date;
    if (number_of_records && start_date && end_date) {
      url = url.concat(`?number_of_records=${number_of_records} & star_date=${start_date} & end_date=${end_date}`);
    } else {
      if (number_of_records) {
        url = url.concat(`number_of_records=${number_of_records}`);
      } else if (start_date && end_date) {
        url = url.concat(`?star_date=${start_date}&end_date=${end_date}`);
      }
    }
    const { data } = await request.get<ListNotes>(url);
    return data;
  } catch (error) {
    return 'Error Fetch Notes';
  }
}

export const sortAlphabeticallyViaProperty = (list: any, property: string, sort: 'up' | 'down') => {
  if (sort === 'up') {
    return list.sort((a: { [x: string]: string }, b: { [x: string]: string }) =>
      a[property]?.localeCompare(b[property])
    );
  } else {
    return list.sort((a: { [x: string]: string }, b: { [x: string]: string }) =>
      b[property]?.localeCompare(a[property])
    );
  }
};

export const sortViaProperty = (list: any, property: string, sort: 'up' | 'down') => {
  if (sort === 'up') {
    return list.sort((a: { [x: string]: number }, b: { [x: string]: number }) => a[property] - b[property]);
  } else {
    return list.sort((a: { [x: string]: number }, b: { [x: string]: number }) => b[property] - a[property]);
  }
};

export const formatDate = (dateString: string): string => {
  const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;

  const options: Intl.DateTimeFormatOptions = {
    timeZone: timeZone,
    year: 'numeric',
    month: 'long',
    day: 'numeric',
    hour: '2-digit',
    minute: '2-digit',
    second: '2-digit',
    hour12: false,
  };

  const date = new Date(dateString);
  return date.toLocaleDateString('en', options);
};
export const addImportant = (classes: string): string => {
  return classes
    .split(' ')
    .map((className) => `${className}!important`)
    .join(' ');
};
export const getCurrentFormattedTime = (): string => {
  const currentDate = new Date();

  const year = currentDate.getFullYear();
  const month = String(currentDate.getMonth() + 1).padStart(2, '0'); // months are zero-indexed
  const day = String(currentDate.getDate()).padStart(2, '0');

  const hours = String(currentDate.getHours()).padStart(2, '0');
  const minutes = String(currentDate.getMinutes()).padStart(2, '0');

  return `${year}-${month}-${day} ${hours}:${minutes}`;
};
export const calcDiffTimes = (time: string): number => {
  const targetTime = new Date(time + 'Z');
  const userTime = new Date();
  const userTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;

  const targetTimeInUserTimezone = new Date(targetTime.toLocaleString('en-US', { timeZone: userTimezone }));

  const diffInSeconds = Math.floor((userTime.getTime() - targetTimeInUserTimezone.getTime()) / 1000);

  return diffInSeconds;
};

import { IconProps } from './index.types';

const Icon = ({ name, width, height, onClick, className = '' }: IconProps) => {
  return (
    <div className={`${className} flex cursor-pointer`} onClick={onClick}>
      <img src={`/images/icons/${name}.svg`} width={width} height={height} />
    </div>
  );
};

export default Icon;

import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { EnterpriseSearchType, enterpriseSearch } from 'src/modules/EnterpriseSearch/api';
import { downloadFile } from 'src/modules/Queries/api';
// import { EnterpriseSearchType, enterpriseSearch } from 'src/modules/Queries/api';
import { SalesListProps } from 'src/modules/Queries/containers/SalesTax/index.types';
import { TemporaryData } from 'src/modules/Queries/containers/TemporaryVisa/index.types';
export interface Item {
  label: string;
  value: string;
  lock: boolean;
}
const useEnterpriseSearch = () => {
  const { t } = useTranslation();
  const messageUpgrade = t('This feature is not available for free trial users. Please contact DeedDex Support for assistance.')
  const [openModal, setOpenModal] = useState(false)
  const [projectName, setProjectName] = useState('');
  const [name, setName] = useState('');
  const [passportNumber, setPassportNumber] = useState('');
  const [applicationType, settApplicationType] = useState('');
  const [itemSelected, setItemSelected] = useState<Item | null>(null);
  const [blob, setBlob] = useState<Blob>(new Blob());
  const [pdfData, setPdfData] = useState<Uint8Array>(new Uint8Array());

  const [errorMessage, setErrorMessage] = useState('');
  const [salesAnswer, setSalesAnswer] = useState<SalesListProps>({ SalesTax: [] });
  const [temporaryAnswer, setTemporaryAnswer] = useState<TemporaryData[]>();
  const [files, setFiles] = useState<string[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [openPreviewModal, setOpenPreviewModal] = useState<boolean>(false);
  const handleOpenModal = () =>{
    setOpenModal(true)
  }
  const onCloseModal = () => {
    setBlob(new Blob());
    setPdfData(new Uint8Array());
    setOpenPreviewModal(false);
  };
  const onProjectNameInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setProjectName(e.target.value);
  };
  const onNameInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setName(e.target.value);
  };
  const onPassportNumberInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setPassportNumber(e.target.value);
  };
  const onApplicationTypeInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    settApplicationType(e.target.value);
  };
  const changeSearchType = (event: React.SyntheticEvent<Element, Event>, value: Item | null) => {
    setItemSelected(value);
    setErrorMessage('');
  };

  const convertToArray = (data: any) => {
    return Object?.keys(data)?.map((section) => ({
      section,
      data: Object?.keys(data[section])?.map((key) => ({
        key,
        value: data[section][key],
      })),
    }));
  };
  const onSendQuestion = async () => {
    const body: EnterpriseSearchType = {
      method: Number(itemSelected?.value),
      search_data: {},
    };
    if (itemSelected?.value === '3') {
      if (!projectName) {
        setErrorMessage('Please enter the Name of the project!');
        return;
      }
      setSalesAnswer({ SalesTax: [] });
      body.search_data = { project_name: projectName };
    } else {
      if (!name) {
        setErrorMessage('Please enter the Name!');
        return;
      }
      setTemporaryAnswer([]);
      body.search_data = {
        name: name,
        passport_number: passportNumber,
        application_type: 'Temporary Visa',
      };
    }
    setIsLoading(true);
    setErrorMessage('');

    setFiles([]);
    await enterpriseSearch(body)
      .then((res: any) => {
        if (res == '400') {
          console.log('Error');
        } else {
          setFiles(res?.url_to_download);
          if (itemSelected?.value === '3') {
            setSalesAnswer({ SalesTax: res.SalesTax });
          } else {
            const formDataArray = convertToArray(res.TemporaryVisa);
            setTemporaryAnswer(formDataArray);
          }
        }
      })
      .finally(() => setIsLoading(false));
  };
  const onPreviewDialog = (link: string) => {
    setOpenPreviewModal(true);
    downloadDataFile(link);
  };
  const downloadDataFile = (downloadLink: string) => {
    downloadFile(downloadLink).then((result) => {
      if (typeof result === 'object') {
        setBlob(result);
        const reader = new FileReader();
        reader.onload = () => {
          if (reader.result instanceof ArrayBuffer) {
            const uint8Array = new Uint8Array(reader.result);
            if (uint8Array.length === 0) {
              return;
            }
            setPdfData(new Uint8Array(uint8Array));
          }
        };
        reader.readAsArrayBuffer(result);
      }
    });
  };

  return {
    salesAnswer,
    changeSearchType,
    itemSelected,
    projectName,
    name,
    applicationType,
    passportNumber,
    onProjectNameInputChange,
    onNameInputChange,
    onPassportNumberInputChange,
    onApplicationTypeInputChange,
    onSendQuestion,
    isLoading,
    temporaryAnswer,
    errorMessage,
    files,
    isModalOpen: openPreviewModal,
    onCloseModal,
    pdfData,
    onPreviewDialog,
    openModal,
    setOpenModal,
    handleOpenModal,
    messageUpgrade
  };
};

export default useEnterpriseSearch;

// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ``, "",{"version":3,"sources":[],"names":[],"mappings":"","sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"color_light_gray_5": `#79747e`,
	"color_light_gray_6": `#e6e0e9`,
	"color_dark_blue_3": `#0b1436`,
	"color_light_blue_2": `#c4cdd9`,
	"color_light_blue_3": `#a4b4cb`,
	"color_light_blue_4": `#e8eaed`,
	"color_primary": `#0e1d54`,
	"color_primary_opacity": `rgba(14, 29, 84, 0.3764705882)`,
	"color_secondary": `#597aaf`,
	"color_secondary_opacity": `rgba(89, 122, 175, 0.502)`,
	"color_info": `#03a9f4`,
	"color_error": `#ef5350`,
	"color_warning": `#ff9800`,
	"color_success": `#4caf50`
};
export default ___CSS_LOADER_EXPORT___;

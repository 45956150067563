// import { Avatar } from '@mui/material';
import React, { useEffect, useState } from 'react';
import Avatar from '../Avatar';
import useAuth from 'src/core/hooks/useAuth';
import { useNavigate } from 'react-router-dom';
import Icon from '../Icon';
import { useTranslation } from 'react-i18next';

const Dropdown = () => {
  const [isOpen, setIsOpen] = useState(false);
  const navigate = useNavigate();
  const { auth, setAuth } = useAuth();
  const [userInfo, setUserInfo] = useState<any>();

  const handleToggle = () => {
    setIsOpen(!isOpen);
  };
  useEffect(() => {
    if (localStorage?.getItem('userData')) {
      setUserInfo(JSON.parse(localStorage?.getItem('userData') ?? ''));
    }
  }, []);

  const onLogout = (confirmLogout = true) => {
    setIsOpen(false);
    const proceedWithLogout = confirmLogout ? window.confirm('Are you sure you want to logout?') : true;
    if (proceedWithLogout) {
      setAuth({ userToken: undefined, userData: undefined });
      localStorage.removeItem('access_token');
      localStorage.removeItem('refresh_token');
      localStorage.removeItem('userData');
      localStorage.removeItem('language');
      localStorage.removeItem('i18nextLng');
      navigate('/user-login');
    }
  };
  const { t } = useTranslation();
  return (
    <div className="relative inline-block text-left">
      <div>
        <button
          type="button"
          className="inline-flex justify-center w-full rounded-md  shadow-sm px-4 py-2 bg-white text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none"
          onClick={handleToggle}>
          <Avatar />
        </button>
      </div>

      {isOpen && (
        <div className="origin-top-right absolute right-0 mt-2 min-w-56 rounded-md shadow-lg p-3 bg-white ring-1 ring-black ring-opacity-5 z-10 focus:outline-none max-sm:p-2">
          <div className="py-1" role="menu" aria-orientation="vertical" aria-labelledby="options-menu">
            <div className="w-full pb-2 border-b">
              <p className="text-lg text-blue-300 max-sm:text-sm">
                {userInfo?.firstName + ' ' + userInfo?.lastName || 'User'}
              </p>
              <p className="text-sm text-gray-500 max-sm:text-xs">{userInfo?.email}</p>
            </div>
            <button
              className="w-full h-9 flex items-center justify-start gap-x-1 pt-2 max-sm:text-xs"
              onClick={() => onLogout(true)}>
              <Icon
                name="signout"
                className="w-8 h-8 flex items-center justify-center rounded-lg bg-light-blue-3 max-sm:w-6 max-sm:h-6"
              />
              {t('Exit')}
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default Dropdown;

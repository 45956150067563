import { Divider } from '@mui/material';
import SearchInput from 'src/modules/Queries/containers/SearchInput';
import Chats from 'src/modules/Queries/containers/Chats';
import Screenshot from 'src/modules/General/containers/Screenshot';
import ConfirmationModal from 'src/modules/General/containers/ConfirmationModal';
import PreviewElement from 'src/modules/Queries/containers/PreviewElement';
import useInteractiveSearch from './index.services';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { Dayjs } from 'dayjs';
import { useState } from 'react';
import MultiSelect from 'src/modules/Queries/components/MultiSelect';
import { useTranslation } from 'react-i18next';

const InteractiveSearch = () => {
  const {
    searchValue,
    onSearchInputChange,
    onSendQuestion,
    lastScreenshotNumber,
    onTakeScreenshot,
    preview,
    onConfirmAttachment,
    chats,
    isLoading,
    isModalOpen,
    onCloseModal,
    changeMethodSearch,
    methodSearch,
    changeTag,
    itemSelected,
    fromDate,
    setFromDate,
    toDate,
    setToDate,
    items,
    autoScroll,
  } = useInteractiveSearch();
  const { t } = useTranslation();
  return (
    <div className="w-full h-full flex gap-4 justify-normal items-start flex-col">
      <div className="w-full flex flex-col gap-2 items-center justify-center">
        <div className="w-full flex items-end justify-start gap-2">
          <div className="w-full grid grid-cols-2 gap-2 items-end justify-start">
            <div className="grid grid-cols-2 gap-2">
              <div>
                <label htmlFor="">{t('From')}</label>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DatePicker
                    className="w-full"
                    value={fromDate}
                    onChange={(newValue) => setFromDate(newValue)}
                    slotProps={{
                      textField: {
                        size: 'small',
                        style: { backgroundColor: 'white' },
                      },
                    }}
                  />
                </LocalizationProvider>
              </div>
              <div>
                <label htmlFor="">{t('To')}</label>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DatePicker
                    className="w-full"
                    minDate={fromDate ?? undefined}
                    value={toDate}
                    onChange={(newValue) => setToDate(newValue)}
                    slotProps={{
                      textField: {
                        size: 'small',
                        style: { backgroundColor: 'white' },
                      },
                    }}
                  />
                </LocalizationProvider>
              </div>
            </div>
            <div>
              <MultiSelect options={items} selectedOptions={itemSelected} onChange={changeTag} />
            </div>
          </div>
        </div>
        <SearchInput
          disabledSearch={methodSearch === ''}
          value={searchValue}
          onChangeInput={onSearchInputChange}
          onIconClick={onSendQuestion}
          isLoading={isLoading}
          onMethodSearch={changeMethodSearch}
        />
        <div className="w-full flex flex-row items-center justify-end">
          <button
            onClick={onSendQuestion}
            disabled={
              (methodSearch === '' && fromDate === null && toDate === null && !itemSelected.length) ||
              searchValue === ''
            }
            className={`h-10 px-6 py-3 rounded-xl shadow justify-center items-center flex flex-row gap-x-1 text-base font-medium capitalize leading-tight bg-primary text-white disabled:bg-stone-100 disabled:text-black`}>
            {t('Search')}
          </button>
        </div>
      </div>

      <Divider sx={{ borderColor: '#ffffff', width: '100%' }} />

      <Chats list={chats} autoScroll={autoScroll} />
      <Screenshot lastScreenshotNumber={lastScreenshotNumber} onTakeScreenshot={onTakeScreenshot} />
      <ConfirmationModal
        open={isModalOpen}
        onClose={onCloseModal}
        previewElement={<PreviewElement preview={preview} />}
        text={t('Do you confirm to attach this file?')}
        buttons={[{ children: t('Confirm'), color: 'primary', variant: 'contained', onClick: onConfirmAttachment }]}
      />
    </div>
  );
};

export default InteractiveSearch;

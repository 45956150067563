import { ButtonProps } from './index.types';

const ButtonNavigation = ({ label, onClick, children, disabled, classname = '' }: ButtonProps) => {
  return (
    <button disabled={disabled} className={classname} onClick={onClick}>
      {children}
      {label}
    </button>
  );
};
export default ButtonNavigation;

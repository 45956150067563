const Terms = () => {
  return (
    <div className="w-full h-full overflow-y-auto p-6">
      <div className="w-full  flex flex-col items-center justify-start gap-y-2">
        <span className="w-full text-neutral-950 text-lg font-extrabold">DeedDex Terms of Use </span>
      </div>

      <div className="flex flex-col justify-start gap-4 ">
        <h2 className="font-extrabold text-base">1.Introduction, Acceptance and Definitions </h2>
        <p className="text-sm text-black">
          Welcome to DeedDex, a solution providing AI-driven solutions for storing, categorizing, and processing
          documents (the “Services”). By using the Services or purchasing the Services (as defined hereinbelow) in any
          capacity, you agree to be bound by the terms of the present agreement known as the Terms of Use (the “TOU”).
          If you do not agree with the TOU or any applicable policies, please do not use the Services.
        </p>
        <p className="text-sm text-black">
          The Services are owned and operated by NexaDeeds Inc., a duly incorporated company based in Canada (the
          “Company”). Where the present TOU refer to “DeedDex” and “we,” they may refer to the Company and/or its
          officers, directors, employees, agents, or representatives, depending on the context.
        </p>
        <p className="text-sm text-black">
          In these TOU, a user of the Services may be referred to as “you.” The TOU should be read in conjunction with
          the Privacy Policy (the “Privacy Policy”) as both these documents govern your use of the Services.
        </p>
        <p className="text-sm text-black">
          Where you (or you acting on behalf of a company or other organization) are in the process of purchasing any
          DeedDex products or if you subscribe to any of the DeedDex services, you will be subject to the respective
          terms of use.
        </p>
        <p className="text-sm text-black">
          DeedDex reserves the right at any time and without prior notice to modify or replace any of the TOU. Any
          changes to the TOU can be found at this URL. It is your responsibility to check the TOU periodically for
          changes. Your use of the Services following the posting of any changes to the TOU constitutes acceptance of
          those changes.
        </p>
        <p className="text-sm text-black">
          If you have any questions about the TOU, please contact: <br />
          DeedDex Customer Service
          <br />
          policy@nexadeeds.com <br />
          220-3900, boul de la Côte-Vertu,Saint-Laurent,QCH4R 1V4
        </p>
      </div>
      <div className="flex flex-col justify-start gap-4 ">
        <ul className="font-extrabold text-base">General Code of Conduct for Use of the Services</ul>
        <p className="text-sm text-black">
          By using the Services, you agree to do so in good faith and in compliance with all applicable local,
          provincial, national, and international laws and not, under any circumstance:
        </p>
        <ul className="">
          <li className="pl-3">&#x2022; &nbsp; use the Services in any manner that in any way violates these TOU;</li>
          <li className="pl-3">
            &#x2022; &nbsp; use the Services in any manner that violates any intellectual property rights of DeedDex or
            any third party;
          </li>
          <li className="pl-3">
            &#x2022; &nbsp; use the Services in any manner to propagate spam, including but not limited to unsolicited
            advertising or bulk electronic mail or messages, including to link to a spam or phishing website;
          </li>
          <li className="pl-3">
            &#x2022; &nbsp; use the Services in any manner to propagate software viruses, Trojan horses, worms, or any
            other malicious or non-malicious computer code, files, or programs that are designed or intended to disrupt,
            damage, limit, or interfere with the proper function of any software, hardware, or telecommunications
            equipment in any form, whether belonging to DeedDex or a third party, or to damage or obtain unauthorized
            access to any system, data, or other information (whether Personal Information or not) of DeedDex, other
            users of the Services, or any other third party;
          </li>
          <li className="pl-3">
            &#x2022; &nbsp; take any action that imposes or may impose (as determined by DeedDex in its sole discretion)
            an unreasonable or disproportionately large load on DeedDex’s (or its third-party providers’)
            infrastructures;{' '}
          </li>
          <li className="pl-3">
            &#x2022; &nbsp; interfere or attempt to interfere with the proper functioning of the Services or any
            activities conducted via the Services;{' '}
          </li>
          <li className="pl-3">
            &#x2022; &nbsp; bypass any measures DeedDex may use to prevent or restrict access to the Services or any
            element thereof;
          </li>
          <li className="pl-3">
            &#x2022; &nbsp; use manual or automated software, devices, or other processes to “crawl” or “spider” any
            part of the Services;
          </li>
          <li className="pl-3">
            &#x2022; &nbsp; harvest or scrape any content from the Services in an unreasonable manner;
          </li>
          <li className="pl-3">
            &#x2022; &nbsp; harass in any manner or form via the Services, notably the use of obscene or abusive
            language in chat or emails to DeedDex technical support or customer service. Identity theft, including of
            our employees, hosts, representatives, or other members of or users of the Services, is prohibited. You are
            not permitted to upload, disseminate, share, transmit, or otherwise publish documents or messages via the
            Services that are of a defamatory, obscene, threatening, or abusive nature, which could undermine the
            privacy or damage the integrity or reputation of others, or that are otherwise inadmissible or constitute or
            encourage a criminal offense.
          </li>
        </ul>
      </div>
      <div className="flex flex-col justify-start gap-4 ">
        <h2 className="font-extrabold text-base">External Links</h2>
        <p>
          From time to time, DeedDex may provide links to other websites or services. Links from the Services may take
          you to websites or services not covered by these TOU. When you access third-party resources on the Internet in
          this manner, you do so at your own risk. DeedDex provides those links as a convenience to you and DeedDex
          takes no responsibility for your use of those other websites or services or protection of your privacy
          (including collection of your personal information) on those other websites or services. We encourage you to
          check the terms of use and/or privacy policy of any website or service you visit. DeedDex does not make any
          claim or warranty whatsoever about the content of those websites or services to which we link or any products
          or services available through those websites or the third parties operating those websites.
        </p>
        <p>
          In no way will DeedDex be held responsible for any damages, direct or indirect, pecuniary or non-pecuniary:
          (1) for your use of websites or other services that may be linked to from the Services or the information
          thereon; (2) for any virus, Trojan horse, worm, or other similar destructive file received as a result of your
          use of those websites or services; (3) caused by or in connection with use of or reliance on any content or
          products or services (whether free or for purchase) available on or through any linked-to website or service;
          or (4) for the actions of the operators of any such website or service.
        </p>
      </div>
      <div className="flex flex-col justify-start gap-4 ">
        <h2 className="font-extrabold text-base">Opinions and Content</h2>
        <p className="text-sm text-black">
          Unless otherwise indicated in writing, any opinions expressed via the Services are those of the authors only
          and not those of DeedDex or any third-party providers. Some content displayed via the Services may be content
          embedded from third-party services and therefore may be subject to the terms of service of these services as
          applicable.
        </p>
      </div>
      <div className="flex flex-col justify-start gap-4 ">
        <h2 className="font-extrabold text-base">Copyright and Intellectual Property Rights</h2>
        <p className="text-sm text-black">
          The content, arrangement, and layout of the Services, including but not limited to the trademarks, photos,
          logos, videos, audio, images, text (in the form of plain text, HTML, or PDFs), and computer code, are
          proprietary to DeedDex, either owned or under license, and may not be copied, imitated, reproduced, displayed,
          distributed, transmitted, decompiled, or otherwise used without the express permission of DeedDex or as
          permitted by the functionality of the Services or these TOU. Any unauthorized use of the content, arrangement,
          or layout of the Services, computer code, images, logos, videos, audio, or trademarks found in the Services,
          or any derivative works thereof may violate civil or criminal laws, including but not limited to intellectual
          property laws, and DeedDex may take action accordingly.
        </p>
        <p className="text-sm text-black">
          The above paragraph further applies to third-party property used as part of the Services, including but not
          limited to third-party computer code. For the purposes of the present section, “computer code” includes source
          code, frameworks, CSS or JavaScript files, templates, modules, or any similar files.
        </p>
        <p className="text-sm text-black">
          If you choose to communicate to DeedDex suggestions for improvements to the Services or any product or service
          mentioned via the Services (collectively “Feedback”), DeedDex shall own all right, title, and interest in and
          to the Feedback and will be entitled to use the Feedback without restriction. You hereby irrevocably assign
          all right, title, and interest in and to the Feedback to DeedDex and waive in favor of DeedDex, its
          successors, and assigns all your moral rights in the Feedback and agree to provide DeedDex such assistance as
          DeedDex may require documenting, perfect, and maintain DeedDex’s rights to the Feedback. You acknowledge and
          agree that by providing any Feedback to DeedDex, you are not entitled to any compensation or reimbursement of
          any kind from DeedDex under any circumstances.
        </p>
      </div>
      <div className="flex flex-col justify-start gap-4 ">
        <h2 className="font-extrabold text-base">Interruption of Service</h2>
        <p className="text-sm text-black">
          From time to time, the Services may be unavailable for brief periods of time for maintenance and/or
          modifications. While we will endeavor to make this unavailability as brief as possible, DeedDex shall not be
          held liable for any losses or damages, pecuniary or non-pecuniary, resulting from the interruption of the
          normal functioning of the Services and disclaims any responsibility thereto.
        </p>
      </div>
      <div className="flex flex-col justify-start gap-4 ">
        <h2 className="font-extrabold text-base">Termination of the Services and the TOU</h2>
        <p className="text-sm text-black">
          You agree that DeedDex, in its sole discretion, with or without cause, has the right (but not the obligation)
          to block your IP address or otherwise terminate your access to or use of the Services (or any part thereof)
          immediately and without notice for any reason, including without limitation if DeedDex believes that you have
          acted inconsistently with the letter or spirit of the TOU or the Privacy Policy.
        </p>
        <p className="text-sm text-black">
          DeedDex may also, in its sole discretion and at any time, discontinue providing the Services or any part
          thereof with or without notice. You agree that DeedDex shall not be liable to you or any third party for any
          losses or damages, pecuniary or non-pecuniary, resulting from termination of your access to the Services or
          from DeedDex’s termination of the Services or any part thereof.
        </p>
        <p className="text-sm text-black">
          Termination of the Services or your access to the Services shall terminate the present TOU as between you and
          DeedDex. All provisions of these TOU which by their nature should survive termination of these TOU shall
          survive termination, including without limitation intellectual property provisions, disclaimers, indemnity,
          and limitations of liability.
        </p>
      </div>
      <div className="flex flex-col justify-start gap-4 ">
        <h2 className="font-extrabold text-base">Disclaimer of Warranties</h2>
        <p className="text-sm text-black">
          You expressly understand and agree that your use of the Services, the information thereon (whether provided by
          DeedDex or third parties), or any activity arising from your use of the Services, the information thereon, or
          the materials downloaded therefrom is at your sole risk. The Services, any materials downloaded therefrom, or
          any third-party materials are provided on an “as is” and “as available” basis, and you will be solely
          responsible for any damage to your computer system or loss of data that results from the download, stream, or
          access of any material obtained through the use of the Services or any other functionalities of the Services,
          or losses or damages (financial or otherwise) resulting from (i) your use of the Services, the information
          thereon, any materials downloaded therefrom, or (ii) any activity arising from the use of the Services, the
          information thereon, or any materials downloaded therefrom.
        </p>
        <p className="text-sm text-black">
          The information or resources provided through the Services, written or produced by DeedDex staff, freelance
          writers, or other subcontractors, are known to be as accurate as possible at the time of writing or
          production, and every effort has been made to ensure that the information from the Services is as accurate and
          up-to-date as possible. However, certain information may change, and errors or omissions may occur, and
          DeedDex shall not be responsible for any loss or damage, financial or otherwise, resulting from changes or
          errors in information or any omission in the Services.
        </p>
        <p className="text-sm text-black">
          DeedDex expressly disclaims all warranties of any kind, whether express or implied, including but not limited
          to: warranties of title and non-infringement; warranties that the Services, the information thereon, or any
          materials downloaded therefrom and any third-party materials will be uninterrupted, error-free, accurate,
          reliable, and free from virus and other harmful components; and the implied warranties of merchantability and
          fitness for a particular purpose. DeedDex and its directors, officers, employees, agents, suppliers, partners,
          and subcontractors do not warrant that: (i) the Services will be secure or available at any particular time or
          location; (ii) any defects or errors or omissions will be corrected; (iii) any content or software available
          at or through the Services is free of viruses or other harmful components; or (iv) the results of using the
          Services or any content downloaded therefrom will meet your requirements.
        </p>
      </div>
      <div className="flex flex-col justify-start gap-4 ">
        <h2 className="font-extrabold text-base">Limitation of Liability</h2>
        <p className="text-sm text-black">
          DeedDex shall not be liable and assumes no responsibility for any loss or damages arising from or in relation
          to the information on the Services or downloaded therefrom, the use of the Services, activities arising from
          your use of the Services, or any third-party materials on the Services.
        </p>
        <p className="text-sm text-black">
          To the maximum extent permitted by law, in no event will DeedDex, its shareholders, officers, directors,
          employees, licensors, suppliers, subcontractors, or agents be liable for any indirect, incidental,
          extraordinary, consequential, special, punitive, or exemplary damages (including without limitation loss of
          revenue or profits, lost or damaged data, loss of use, business interruption, or any other pecuniary loss)
          arising out of or relating to the Services or your use, misuse, or inability to use the Services, even if
          DeedDex has been advised of the possibility of such damages. This limitation of liability will apply
          regardless of the form of action, whether in contract, warranty, tort, negligence, strict liability, or under
          any other legal theory.
        </p>
        <p className="text-sm text-black">
          In no event will DeedDex’s aggregate liability for any claims in connection with your use of the Services, if
          any, exceed the higher of (i) CAD$100 or (ii) the fees paid by you to DeedDex during the 12-month period
          preceding the event from which the liability arises.
        </p>
      </div>
      <div className="flex flex-col justify-start gap-4 ">
        <h2 className="font-extrabold text-base">Indemnity</h2>
        <p className="text-sm text-black">
          Notwithstanding any other term of the TOU or any act or failure to act by DeedDex or its agents or
          subcontractors, you agree to indemnify, defend, and hold harmless DeedDex and its officers, directors,
          shareholders, owners, partners, partnerships, principals, employees, affiliates, and other related entities,
          servants, agents, representatives, successors, assigns, and subcontractors where applicable from and against
          any damages, liabilities, costs, and expenses (including attorneys’ fees), claims, or demands arising out of
          (i) your use of or connection to the Services or the information thereon or information downloaded therefrom;
          (ii) your participation in any activities arising from the Services or the information thereon or information
          downloaded therefrom; (iii) your violation of or failure to perform your obligations under the TOU or the
          Privacy Policy; or (iv) your violation of any rights of a third party, including without limitation your
          violation of third-party intellectual property rights.
        </p>
      </div>
      <div className="flex flex-col justify-start gap-4 ">
        <h2 className="font-extrabold text-base">Governing Laws and Jurisdiction</h2>
        <p className="text-sm text-black">
          Use of the Services shall be governed by and construed in accordance with the laws of the Province of Ontario,
          Canada and the federal laws of Canada applicable in the Province of Ontario without regard to conflict of law
          provisions. The foregoing choice of jurisdiction and venue shall not prevent DeedDex from seeking injunctive
          relief with respect to a violation of intellectual property rights, enforcement, or recognition of any award
          or order in any appropriate jurisdiction. You and DeedDex expressly disclaim applicability of the terms of the
          United Nations Convention of Contracts for the International Sale of Goods, and any legislation implementing
          such Convention will not apply to these TOU nor to any dispute arising therefrom.
        </p>
      </div>
      <div className="flex flex-col justify-start gap-4 ">
        <h2 className="font-extrabold text-base">Miscellaneous Provisions</h2>
        <p className="text-sm text-black">
          The TOU, in conjunction with the Privacy Policy, constitute the entire agreement between you and DeedDex with
          respect to your use of the Services, superseding any prior agreements between you and DeedDex.
        </p>
        <p className="text-sm text-black">
          DeedDex shall not be liable for any failure to perform its obligations under the present TOU where such
          failure results from any cause beyond DeedDex’s reasonable control, including but not limited to mechanical,
          electronic, or communications failure or degradation.
        </p>
        <p className="text-sm text-black">
          If any provision of the TOU or Privacy Policy is found by a court of competent jurisdiction to be invalid, the
          parties nevertheless agree that the court should endeavor to give effect to the parties’ intentions as
          reflected in the provision, and the other provisions of the TOU or Privacy Policy, as the case may be, shall
          remain in full force and effect.
        </p>
        <p className="text-sm text-black">
          The section titles in the TOU and Privacy Policy are for convenience only and have no legal or contractual
          effect.
        </p>
        <p>© 2024 DeedDex Inc. All rights reserved.</p>
      </div>
    </div>
  );
};
export default Terms;

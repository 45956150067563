// import { useNavigate, useParams } from 'react-router-dom';

import List from 'src/modules/General/containers/List';
import CategoriesQueries from 'src/modules/Queries/containers/CategoriesQueries';
import ChatsList from 'src/modules/Queries/containers/ChatsList';
import PreviewModal from 'src/modules/Queries/containers/PreviewModal';
import useQueriesRightColumn from './index.services';
import Input from 'src/modules/General/components/Input';
import FilterIcon from 'src/assets/images/Queries/filter.svg';
import FilterModal from 'src/modules/Queries/components/FilterModal';
import Loading from 'src/modules/General/components/Loading';
import { useTranslation } from 'react-i18next';

const RightColumn: React.FC = () => {
  const {
    category,
    setCategory,
    chatsCategory,
    header,
    chatsList,
    isInteractiveSearchTab,
    isDocumentRetrievalTab,
    isAddDocumentTab,
    documentsList,
    isModalOpen,
    onCloseModal,
    onPreviewDialog,
    pdfData,
    recent_files,
    openFilterModal,
    setOpenFilterModal,
    toggleFilterModal,
    filtredRecentFiles,
    loadingFilter,
    loadingReset,
    isFiltered,
    loadingFiles,
    loadingDownload,
    onRetry
  } = useQueriesRightColumn();
  const { t } = useTranslation();

  const renderLeftColumn = () => {
    if (
      isDocumentRetrievalTab ||
      isAddDocumentTab ||
      (!isAddDocumentTab && !isDocumentRetrievalTab && !isInteractiveSearchTab)
    ) {
      return recent_files?.length ? (
        <List
          list={recent_files}
          onSelectItem={(link, timestamp) => onPreviewDialog(String(link), timestamp)}
          className="max-h-recent-files"
        />
      ) : (
        <div className="mt-4 font-bold text-lg">{t('No Recent Files')}</div>
      );
    } else if (!isAddDocumentTab && (!isInteractiveSearchTab || chatsCategory)) {
      return chatsList?.length ? (
        <ChatsList
          list={chatsList}
          // onSelectItem={id => navigate(id)}
          // selectedItem={id as string}
          // className={cn(isInteractiveSearchTab && styles['chats__list'])}
          className={`${isInteractiveSearchTab ? 'max-h-chats-list' : ''}`}
        />
      ) : (
        <div className="mt-4 font-bold text-lg">{t('No Chat History')}</div>
      );
    } else if (isInteractiveSearchTab && !chatsCategory) {
      return documentsList?.length ? (
        <List list={documentsList} onSelectItem={(id) => onPreviewDialog(String(id), 0)} className="max-h-chats-list" />
      ) : (
        <div className="mt-4 font-bold text-lg">{t('No Related Documents')}</div>
      );
    } else {
      return recent_files?.length ? (
        <List
          list={recent_files}
          onSelectItem={(fileName, documentId) => onPreviewDialog(String(fileName), documentId)}
          className="max-h-recent-files"
        />
      ) : (
        <div className="mt-4 font-bold text-lg">{t('No Recent Files')}</div>
      );
    }
  };

  return (
    <div className="flex justify-start items-start flex-col gap-4 h-full overflow-auto py-8 px-4 bg-white max-sm:p-2">
      <PreviewModal
        open={isModalOpen}
        onClose={onCloseModal}
        pdfData={pdfData}
        loading={loadingDownload}
        text={('Preview File')}
        onRetry={onRetry}
        buttons={[{ children: t('Close'), color: 'primary', variant: 'contained', onClick: onCloseModal }]}
      />

      <FilterModal
        open={openFilterModal}
        onClose={() => setOpenFilterModal(false)}
        onSubmitFilter={filtredRecentFiles}
        isLoading={loadingFilter}
        isLoadingFilter={loadingReset}
        isFilter={isFiltered}
      />

      {isInteractiveSearchTab && (
        <CategoriesQueries
          selectedCategory={category}
          onSelectCategory={(newCategory) => setCategory(newCategory)}
          counts={{ chats: chatsList.length, documents: documentsList?.length || 0 }}
        />
      )}
      <h3>{header}</h3>

      <div className="flex justify-center items-center gap-2 w-full ">
        {/* <Input iconName="search" /> */}
        <div className="w-calc-48">
          <Input placeholder={t('Search')} />
        </div>

        <div className="w-12 h-11 flex flex-row items-center justify-end">
          {header === 'Recent Files' ? (
            <button
              onClick={toggleFilterModal}
              className="w-11 h-11 p-2.5 rounded-xl border border-gray-200 justify-start items-start gap-2.5 inline-flex">
              <img src={FilterIcon} alt="Filter" />
            </button>
          ) : (
            <button className="w-11 h-11 p-2.5 rounded-xl border border-gray-200 justify-start items-start gap-2.5 inline-flex">
              <img src={FilterIcon} alt="Filter" />
            </button>
          )}
        </div>
      </div>
      {loadingFiles ? (
        <div className="w-full h-full flex items-start justify-center">
          <Loading variant="circle" size={30} />
        </div>
      ) : (
        renderLeftColumn()
      )}
    </div>
  );
};

export default RightColumn;

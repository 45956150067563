import { useRef, useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { register } from 'src/modules/General/api';
import { Link } from '@mui/material';
import { isValidEmail, isStrongPassword } from 'src/core/utilities/validations';
import Loading from 'src/modules/General/components/Loading';
import Right from 'src/modules/Authentication/components/Right';
import Logo from 'src/modules/General/components/Logo';
import Title from 'src/modules/Authentication/components/Title';
import Input from 'src/modules/General/components/Input';
import TermsModal from 'src/modules/Authentication/components/TermsModal';
import PrivacyModal from 'src/modules/Authentication/components/Privacy';

const Register: React.FC = () => {
  const navigate = useNavigate();
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [username, setUsername] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [token, setToken] = useState<string | null>(null);
  const [errorMessage, setErrorMessage] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [check, setCheck] = useState<boolean>(false);
  const [openTermsModal, setOpenTermsModal] = useState(false);
  const [openPrivacyModal, setOpenPrivacyModal] = useState(false);
  const [showPassword, setShowPassword] = useState<boolean>(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState<boolean>(false);
  const scrollRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const token = urlParams.get('token');
    setToken(token);
  }, []);

  useEffect(() => {
    if (!token) {
      setErrorMessage('Please use an invite link to register.');
    } else {
      setErrorMessage('');
    }
  }, [token, email, password, confirmPassword]);
  const handleToggleConfirmPassword = (e: React.FormEvent) => {
    e.preventDefault();
    setShowConfirmPassword(!showConfirmPassword);
  };
  const handleTogglePassword = (e: React.FormEvent) => {
    e.preventDefault();
    setShowPassword(!showPassword);
  };
  const handleSubmit = async (e: React.FormEvent) => {
    if (scrollRef.current) {
      scrollRef.current.scrollIntoView({ behavior: 'smooth' });
    }
    e.preventDefault();
    if (!isValidEmail(email)) {
      setErrorMessage('Invalid email.');
    }
    // else if (!isStrongPassword(password)) {
    //   setErrorMessage('Password needs 8+ chars, mix of letters & numbers.');
    // }
    else if (password !== confirmPassword) {
      setErrorMessage('Passwords mismatch.');
    } else {
      setIsLoading(true);

      try {
        const formData = {
          user: {
            Username: username,
            FirstName: firstName,
            LastName: lastName,
            Email: email,
            Password: password,
          },
          token: token!,
        };

        const registerResponse = await register(formData);

        if (registerResponse.Message === 'User registered successfully') {
          setFirstName('');
          setLastName('');
          setUsername('');
          setEmail('');
          setPassword('');
          setConfirmPassword('');
          setErrorMessage('');
          navigate('/user-login', {
            state: { successMessage: 'Registration successful! You can log in now.', email: email },
          });
        } else {
          setErrorMessage(registerResponse.Message);
          if (scrollRef.current) {
            scrollRef.current.scrollIntoView({ behavior: 'smooth' });
          }
        }
      } catch {
        if (scrollRef.current) {
          scrollRef.current.scrollIntoView({ behavior: 'smooth' });
        }
        // setErrorMessage('An error occurred while trying to register. Please try again.');
      }
      setIsLoading(false);
    }
  };

  return (
    <div className="w-full h-full flex flex-row bg-white">
      <div className="w-5/12 grid items-center justify-center p-8 overflow-y-auto max-md:w-full max-sm:px-2">
        <div className="max-w-640 h-auto flex  flex-col gap-y-8 max-xs:w-full">
          <Logo />
          <Title title="Welcome back to DeedDex" />
          <form onSubmit={handleSubmit} className="min-w-80  flex flex-col justify-start gap-y-4 max-xs:w-full">
            <Input
              title="First Name"
              type="text"
              id="firstName"
              value={firstName}
              onChange={(e) => setFirstName(e.currentTarget.value)}
              placeholder="First Name"
              inputStyle="rounded-lg"
            />
            <Input
              title="Last Name"
              type="text"
              id="lastName"
              value={lastName}
              onChange={(e) => setLastName(e.currentTarget.value)}
              placeholder="Last Name"
              inputStyle="rounded-lg"
            />
            <Input
              title="UserName"
              type="text"
              id="userName"
              value={username}
              onChange={(e) => setUsername(e.currentTarget.value)}
              placeholder="UserName"
              inputStyle="rounded-lg"
            />
            <Input
              title="Email Address"
              type="text"
              id="Email"
              value={email}
              onChange={(e) => setEmail(e.currentTarget.value)}
              placeholder="Email"
              inputStyle="rounded-lg"
            />
            <Input
              eyeIcon={true}
              title="Password"
              type={showPassword ? 'text' : 'password'}
              id="password"
              value={password}
              containerStyle="relative"
              onChange={(e) => setPassword(e.currentTarget.value)}
              handleTogglePassword={handleTogglePassword}
              placeholder="Enter your password (8+ chars, mix of letters & numbers)"
              inputStyle="rounded-lg"
            />
            <Input
              eyeIcon={true}
              title="Confirm Password"
              type={showConfirmPassword ? 'text' : 'password'}
              id="confirmPassword"
              containerStyle="relative"
              value={confirmPassword}
              onChange={(e) => setConfirmPassword(e.currentTarget.value)}
              handleTogglePassword={handleToggleConfirmPassword}
              placeholder="Confirm Password"
              inputStyle="rounded-lg"
            />
            {errorMessage && <p className="text-red-600 text-center">{errorMessage}</p>}

            <div className="w-full  flex items-center justify-normal gap-x-1">
              <input
                type="checkbox"
                checked={check}
                id="terms"
                name="terms"
                onChange={(e) => {
                  {
                    setCheck(e.target.checked);
                  }
                }}
                value="terms"
              />

              <p className="text-base">
                I Agree These{' '}
                <button className="bg-transparent border-none text-primary" onClick={() => setOpenTermsModal(true)}>
                  {' '}
                  Terms and Conditions
                </button>{' '}
                and{' '}
                <button onClick={() => setOpenPrivacyModal(true)} className="bg-transparent border-none text-primary">
                  {' '}
                  Privacy Policy
                </button>{' '}
              </p>
            </div>
            <button
              disabled={isLoading || !token || !check}
              type="submit"
              className={`w-full h-11 px-4 py-3 bg-primary rounded-xl shadow justify-center items-center flex flex-row gap-x-1 text-white text-base font-medium capitalize leading-tight disabled:bg-slate-300`}>
              {isLoading && <Loading variant="circle" size={20} color="inherit" />}
              Register
            </button>
          </form>

          <div ref={scrollRef} className="w-full flex items-center justify-center flex-row gap-x-1">
            <p>Already have an account?</p>
            <Link
              href="/user-login"
              className="text-primary text-base font-medium capitalize leading-tight no-underline">
              Login
            </Link>
          </div>
        </div>
        <TermsModal open={openTermsModal} onClose={() => setOpenTermsModal(false)} />
        <PrivacyModal open={openPrivacyModal} onClose={() => setOpenPrivacyModal(false)} />
      </div>
      <div className="w-7/12 block max-md:hidden">
        <Right />
      </div>
    </div>
  );
};

export default Register;

import PropTypes from 'prop-types';
import { useLocation, Navigate } from 'react-router-dom';
import useAuth from 'src/core/hooks/useAuth';
import { ReactNode, useEffect } from 'react';
import { setAuthToken } from 'src/core/api/axios';

interface RequireAuthProps {
  children: ReactNode;
}

const RequireAuth: React.FC<RequireAuthProps> = ({ children }: RequireAuthProps) => {
  const { auth, setAuth } = useAuth();
  useEffect(() => {
    if (localStorage.getItem('access_token')) {
      const userToken = {
        accessToken: localStorage.getItem('access_token') ?? '',
        refreshToken: localStorage.getItem('refresh_token') ?? '',
        tokenType: 'Bearer',
      };
      setAuth({ userToken });
      setAuthToken('Bearer', localStorage.getItem('access_token') ?? '');
    }
  }, []);

  const location = useLocation();

  return localStorage.getItem('access_token') !== null ? (
    <>{children}</>
  ) : (
    <Navigate to="/user-login" state={{ from: location }} replace />
  );
};

RequireAuth.propTypes = {
  children: PropTypes.node.isRequired,
};

export default RequireAuth;

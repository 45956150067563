import { formatDate } from 'src/core/utilities/general';
import { EmailContentProps } from '../index.types';
import Button from '@mui/material/Button';
import { useTranslation } from 'react-i18next';

const EmailContentMobile = ({ email, onBackClick }: EmailContentProps) => {
  const { t } = useTranslation();
  return (
    <div className="w-full h-full overflow-y-auto flex justify-start flex-col bg-white p-2 rounded-lg gap-2">
      <Button onClick={onBackClick} variant="contained" color="primary" className="w-12">
        {t('Back')}
      </Button>
      <p className="font-extrabold text-base max-sm:text-sm">
        {t('Sender')} : <span className="font-normal text-base max-sm:text-xs">{email.sender}</span>
      </p>
      <p className="font-extrabold text-base max-sm:text-sm">
        {t('Date')} : <span className="font-normal text-base max-sm:text-xs">{formatDate(email.date)}</span>
      </p>
      <p className="font-extrabold text-base max-sm:text-sm">
        {('Subject')} : <span className="font-normal text-base max-sm:text-xs">{email.subject}</span>
      </p>

      <div className="border-t-4 border-dashed pt-2">
        {email.contentRow ? (
          <div dangerouslySetInnerHTML={{ __html: email.contentRow }} className="w-full max-sm:text-sm"></div>
        ) : (
          <div>
            <div>
              {email.content.split('\n').map((line, index) => (
                <p key={index} className="leading-loose max-sm:text-sm">
                  {line}
                </p>
              ))}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};
export default EmailContentMobile;

import { createContext, useContext, useReducer } from 'react';

type StateType = {
  documents: { name: string; link: string; file_id: number }[];
  recent_files: any[];
  searches: { id: number; title: string; content: string; time: string }[];
  loading: boolean;
};

type ActionType =
  | { type: 'documents'; value: StateType['documents'] }
  | { type: 'recent_files'; value: StateType['recent_files'] }
  | { type: 'searches'; value: StateType['searches'] }
  | { type: 'loading'; value: StateType['loading'] };

const initialState = {
  documents: [],
  recent_files: [],
  searches: [],

  loading: false,
};

const QueriesContext = createContext<{
  state: StateType;
  dispatch: React.Dispatch<ActionType>;
}>({
  state: initialState,
  dispatch: () => null,
});

export const QueriesProvider = ({ children }: { children: React.ReactNode }) => {
  const [state, dispatch] = useReducer(mainReducer, initialState);

  return <QueriesContext.Provider value={{ state, dispatch }}>{children}</QueriesContext.Provider>;
};

export const useQuereisContext = () => useContext(QueriesContext);

const mainReducer = (state: StateType, action: ActionType) => {
  switch (action.type) {
    case 'documents': {
      return { ...state, documents: action.value };
    }
    case 'recent_files': {
      return { ...state, recent_files: action.value };
    }
    case 'searches': {
      return { ...state, searches: action.value };
    }

    case 'loading': {
      return { ...state, loading: action.value };
    }

    default: {
      throw Error('Unknown action: ' + action);
    }
  }
};

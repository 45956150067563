import { useEffect, useState } from 'react';
import { addNote, BodyNote, deleteNote, editNote, fetchNotes, NoteData, NoteParams } from 'src/modules/Note/api';

export interface Note {
  NoteID: number;
  Note: string;
  CreatedDateTime: string;
  Label?: string;
}

export interface NoteDataTable {
  id?: number;
  label?: Item;
  notes: Note[];
}
export interface Label {
  id: number;
  value: string;
}
export interface Item {
  label: string;
  value: string;
}
const items: Item[] = [
  { label: 'Tax Documents', value: 'Tax Documents' },
  { label: 'Government Letters', value: 'Government Letters' },
  { label: 'Utility bills', value: 'Utility bills' },
  { label: 'Receipt and Invoices', value: 'Receipt and Invoices' },
  { label: 'Bank Statements', value: 'Bank Statements' },
  { label: 'Insurance Documents', value: 'Insurance Documents' },
  { label: 'Legal Documents', value: 'Legal Documents' },
  { label: 'Healthcare Documents', value: 'Healthcare Documents' },
  { label: 'Education Documents', value: 'Education Documents' },
  { label: 'Travel Documents', value: 'Travel Documents' },
  { label: 'Property Documents', value: 'Property Documents' },
  { label: 'Personal Correspondence', value: 'Personal Correspondence' },
  { label: 'Personal Identification', value: 'Personal Identification' },
  { label: 'Events', value: 'Events' },
  { label: 'Empty Document', value: 'Empty Document' },
];
// const Notes: NoteDataTable[] = [
//   {
//     id: 1,
//     label: { label: 'Government Letters', value: 'Government Letters' },
//     notes: [
//       {
//         id: 1,
//         content:
//           'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Faucibus a pellentesque sit amet porttitor. Libero enim sed faucibus turpis in.',
//         createDate: '2024-04-19T13:04:02',
//       },
//       {
//         id: 2,
//         content:
//           'Id semper risus in hendrerit gravida rutrum quisque non tellus. Enim eu turpis egestas pretium aenean pharetra magna ac placerat. Porta lorem mollis aliquam ut porttitor leo a.',
//         createDate: '2024-04-21T13:04:02',
//       },
//       {
//         id: 3,
//         content:
//           'Est lorem ipsum dolor sit. Vitae et leo duis ut diam quam. Sed arcu non odio euismod lacinia. Ipsum faucibus vitae aliquet nec. At lectus urna duis convallis.',
//         createDate: '2024-04-21T13:04:02',
//       },
//     ],
//   },

// ];

const NoteServices = () => {
  const [openDeleteNoteModal, setOpenDeleteNoteModal] = useState<boolean>(false);
  const [openShowMoreModal, setOpenShowMoreModal] = useState<boolean>(false);
  const [note, setNote] = useState<string>('');
  const [noteContent, setNoteContent] = useState<string>('');
  const [noteDate, setNoteDate] = useState<string>('');
  const [noteId, setNoteId] = useState<number | null>(null);
  const [noteList, setNoteList] = useState<NoteDataTable>();
  const [listening, setListening] = useState(false);
  const [transcript, setTranscript] = useState('');
  const [interimTranscript, setInterimTranscript] = useState('');
  const [itemSelected, setItemSelected] = useState<Item | null>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const [supported, setSupported] = useState<boolean>(true);
  const [loadingFetch, setLoadingFetch] = useState<boolean>(false);
  const [displaySnackbar, setDispalySnackbar] = useState<{ display: boolean; color: string; message: string }>({
    display: false,
    color: '',
    message: '',
  });
  const [messageResponse, setMessageResponse] = useState<string>('');

  const deleteNoteMsg = () => {
    setNote('');
    setNoteId(null);
  };

  const onListening = () => {
    if (!listening) {
      setNote('');
      setListening(true);
    } else {
      setListening(false);
    }
  };

  const onEdit = (selected: Note, label: string) => {
    setNoteId(selected.NoteID);
    setNote(selected.Note);
    setItemSelected({ label: label, value: label });
    setListening(false);
  };
  const onDelete = (noteID: number) => {
    setOpenDeleteNoteModal(true);
    setNoteId(noteID);
    setListening(false);
  };
  const handleListen = () => {
    setListening((prevListening) => !prevListening);
  };
  const onChangeLabel = (event: React.SyntheticEvent<Element, Event>, value: Item | null) => {
    setItemSelected(value);
  };

  const getNotes = async (params?: NoteParams) => {
    setLoadingFetch(true);
    const paramsNote: NoteParams = {
      number_of_records: 200,
    };
    const data = await fetchNotes();
    if (typeof data !== 'string') {
      setNoteList(data);
    } else {
      const dataEmpty: NoteDataTable = {
        notes: [],
      };
      setNoteList(dataEmpty);
    }
    setLoadingFetch(false);
  };

  const addNewNote = async () => {
    setListening(false);
    setLoading(true);
    const body: BodyNote = {
      text: note,
    };
    const data = await addNote(body);

    setNote('');
    setLoading(false);
    setDispalySnackbar({ message: 'Note Created', color: 'success', display: true });
    getNotes();
  };
  const updateNote = async () => {
    setLoading(true);
    setListening(false);
    if (noteId) {
      const data = await editNote(noteId, note);
      setLoading(false);
      setNoteId(null);
      setNote('');

      if (typeof data !== 'string') {
        setDispalySnackbar({
          message: 'Note Updated',
          color: 'success',
          display: true,
        });
        getNotes();
      } else {
        setDispalySnackbar({
          message: 'Error Update Note',
          color: 'error',
          display: true,
        });
      }
    }
  };
  const removeNote = async () => {
    setLoading(true);
    setListening(false);
    if (noteId) {
      const data = await deleteNote(noteId);
      setLoading(false);
      closeDeleteNoteDialog();

      if (typeof data !== 'string') {
        setDispalySnackbar({ display: true, message: 'Note Deleted', color: 'success' });
        getNotes();
      } else {
        setDispalySnackbar({ display: true, message: data, color: 'error' });
      }
    }
  };
  const handleCloseSnackbar = () => setDispalySnackbar({ display: false, message: '', color: '' });
  const closeDeleteNoteDialog = () => {
    setOpenDeleteNoteModal(false);
    setNoteId(null);
  };
  const openModalShowMore = (note: NoteData) => {
    setNoteContent(note.Note);
    setNoteDate(note.CreatedDateTime);
    setOpenShowMoreModal(true);
  };
  const closeModalShowMore = () => {
    setNoteContent('');
    setNoteDate('');
    setOpenShowMoreModal(false);
  };

  useEffect(() => {
    const SpeechRecognition = (window as any).SpeechRecognition || (window as any).webkitSpeechRecognition;
    if (!SpeechRecognition) {
      setSupported(false);
      return;
    }

    const recognition = new SpeechRecognition();
    recognition.continuous = true;
    recognition.interimResults = true;
    recognition.lang = 'en-US';

    recognition.onresult = (event: SpeechRecognitionEvent) => {
      let finalTranscript = '';

      for (let i = 0; i < event.results.length; i++) {
        const result = event.results[i];

        if (result.isFinal) {
          finalTranscript += result[0].transcript;
        }
      }

      setNote(finalTranscript);
    };

    recognition.onerror = (event: any) => {
      console.error(event.error);
    };

    if (listening) {
      recognition.start();
    } else {
      recognition.stop();
    }

    return () => {
      recognition.stop();
    };
  }, [listening]);
  useEffect(() => {
    getNotes();
  }, []);

  return {
    note,
    setNote,
    noteId,
    noteList,
    onEdit,
    onListening,
    listening,
    transcript,
    interimTranscript,
    deleteNoteMsg,
    items,
    onChangeLabel,
    itemSelected,
    addNewNote,
    loading,
    loadingFetch,
    displaySnackbar,
    messageResponse,
    handleCloseSnackbar,
    updateNote,
    openDeleteNoteModal,
    setOpenDeleteNoteModal,
    onDelete,
    closeDeleteNoteDialog,
    removeNote,
    noteContent,
    noteDate,
    openShowMoreModal,
    closeModalShowMore,
    openModalShowMore,
    supported,
  };
};

export default NoteServices;

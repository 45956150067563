import React, { useState, useEffect } from 'react';

interface CountdownTimerProps {
  initialSeconds: number;
  action: () => void;
}

const CountdownTimer: React.FC<CountdownTimerProps> = ({ initialSeconds, action }) => {
  const calculateTimeLeft = (seconds: number) => {
    const days = Math.floor(seconds / (3600 * 24));
    const hours = Math.floor((seconds % (3600 * 24)) / 3600);
    const minutes = Math.floor((seconds % 3600) / 60);
    const secs = Math.floor(seconds % 60);

    return {
      days,
      hours,
      minutes,
      seconds: secs,
    };
  };

  const [timeLeft, setTimeLeft] = useState(calculateTimeLeft(initialSeconds));

  useEffect(() => {
    if (initialSeconds <= 0) return;

    const timer = setTimeout(() => {
      setTimeLeft((prev) => {
        const newSeconds = prev.days * 86400 + prev.hours * 3600 + prev.minutes * 60 + prev.seconds - 1;
        if (newSeconds <= 0) {
          clearTimeout(timer);
          return { days: 0, hours: 0, minutes: 0, seconds: 0 };
        }
        return calculateTimeLeft(newSeconds);
      });
    }, 1000);

    return () => clearTimeout(timer);
  }, [timeLeft, initialSeconds]);

  const timerComponents: JSX.Element[] = [];

  Object.keys(timeLeft).forEach((interval) => {
    if (!(timeLeft as any)[interval]) {
      return;
    }

    timerComponents.push(
      <span key={interval}>
        {(timeLeft as any)[interval]} {interval}{' '}
      </span>
    );
  });

  return (
    <div className="text-center">
      {timerComponents.length ? (
        timerComponents
      ) : (
        <button className="bg-transparent border-none text-primary" onClick={action}>
          Resend Code
        </button>
      )}
    </div>
  );
};

export default CountdownTimer;

import { useCallback, useEffect, useState } from 'react';
import { useLoaderData } from 'react-router-dom';
import { useAppContext } from 'src/core/contexts/_app.context';
import { getCurrentFormattedTime } from 'src/core/utilities/general';
import { RespondFile, analyzer } from 'src/modules/Queries/api';
import { dataURLtoFile } from 'src/modules/Queries/utils/imageUtil';
interface RecentFile {
  id: number;
  name: string;
}
export interface SnackbarData {
  display: boolean;
  color: string;
  message: string;
}
export interface MessageRespond {
  id: number;
  docName: string;
  message: string;
  errorCode: number;
}
// type UploadedFiles = { id: number; name: string; files?: File | string; type: 'screenshot' | 'file' };
type UploadedFiles = { id: number; name: string; files?: File; type: 'screenshot' | 'file' };

const useAddDcoument = () => {
  const { state, dispatch } = useAppContext();

  const { screenshotMode, photos } = state || {};
  const [showSnackbar, setShowSnackbar] = useState<SnackbarData>({ display: false, message: '', color: 'error' });
  const [errorMessages, setErrorMessages] = useState<MessageRespond[]>([]);
  const [files, setFiles] = useState<UploadedFiles[]>([]);
  const [link, setLink] = useState<HTMLAnchorElement | null>(null);
  const [attachedFiles, setAttachedFiles] = useState<File[]>([]);
  const [openConfirmationModal, setOpenConfirmationModal] = useState<{ type: 'screenshot' | 'file'; open: boolean }>({
    type: 'screenshot',
    open: false,
  });
  const [openRespondModal, setOpenRespondModal] = useState<boolean>(false);

  const [note, setNote] = useState('');
  const [concatFiles, setConcatFiles] = useState(false);
  const [notifNeeded, setNotifNeeded] = useState(true);
  const [uploadedList, setUploadedList] = useState<{ id: number; name: string }[]>();
  const [isLoading, setIsLoading] = useState(false);
  const lastFileId = files[files.length - 1]?.id || 0;
  const attachedFilesNames = attachedFiles.map((file) => file.name);

  const onCloseModal = () => setOpenConfirmationModal({ ...openConfirmationModal, open: false });
  const onOpenModalRespond = () => setOpenRespondModal(true);

  const onCloseModalRespond = () => {
    setOpenRespondModal(false);
    setErrorMessages([]);
  };

  const onDrop = useCallback((files: File[]) => {
    if (files?.length) {
      setAttachedFiles(Object.values(files));
      setOpenConfirmationModal({ type: 'file', open: true });
    }
  }, []);

  const onScreenshotClick = () => dispatch({ type: 'screenshotMode', value: !screenshotMode });
  const handleCloseSnackbar = () => setShowSnackbar({ display: false, message: '', color: 'error' });
  const onTakeScreenshot = (newLink: HTMLAnchorElement) => {
    {
      setLink(newLink);
      setOpenConfirmationModal({ type: 'screenshot', open: true });
    }
  };

  const onConfirmAttachment = () => {
    {
      if (openConfirmationModal.type === 'screenshot') {
        // link?.download &&
        //   setFiles([...files, { id: lastFileId + 1, name: link?.download, files: link?.href, type: 'screenshot' }]);
      } else {
        let temp: UploadedFiles[] = [];
        for (let i = 0; i < attachedFiles.length; i++) {
          temp = [
            ...temp,
            { id: lastFileId + 1 + i, name: attachedFiles[i].name, files: attachedFiles[i], type: 'file' },
          ];
          setFiles([...files, ...temp]);
        }
      }
      onCloseModal();
      setLink(null);
      setAttachedFiles([]);
    }
  };
  const addPhotos = () => {
    let temp: UploadedFiles[] = [];

    const allPhotos: File[] = [];
    for (let i = 0; i < photos.length; i++) {
      allPhotos.push(dataURLtoFile(photos[i] ?? '', `${new Date().getTime()}.jpeg`));
    }
    for (let j = 0; j < allPhotos.length; j++) {
      temp = [...temp, { id: lastFileId + 1 + j, name: allPhotos[j].name, files: allPhotos[j], type: 'file' }];
      setFiles([...files, ...temp]);
    }
    dispatch({ type: 'openCamera', value: false });
    dispatch({ type: 'clearPhotos' });
  };
  const clearPhotos = () => {
    dispatch({ type: 'clearPhotos' });
  };

  const onDeleteAddedFile = (fileId: number) => {
    const removedFiles = files.filter((file) => file.id !== fileId);
    setFiles(removedFiles);
  };

  const onChangeNote = (note: string) => {
    setNote(note);
  };

  const onConcatFiles = (checked: boolean) => {
    setConcatFiles(checked);
  };

  const onNotifNeeded = (checked: boolean) => {
    setNotifNeeded(checked);
  };

  const onUploadFiles = async () => {
    // const uploadFiles = files.map(file => file.files) as File[] | string[];
    const uploadFiles = files.map((file) => file.files) as File[];
    const uploadFilesNames = files.map((file) => file.name) as string[];
    const localeDate = getCurrentFormattedTime()
    setIsLoading(true);

    try {
      const { result } = await analyzer({
        files: uploadFiles,
        concatenate: concatFiles,
        files_names: uploadFilesNames,
        note_by_user: note,
        notif_needed: notifNeeded,
        local_user_datetime:localeDate
      });
      const list: RecentFile[] = [];
      let errorCount = 0;
      let successCount = 0;
      await result.forEach(async (res: RespondFile, index: number) => {
        if (res.ErrorCode === 0) {
          successCount += 1;
          list.push({ id: index + 1, name: res.Document });
        } else {
          errorCount += 1;
        }
      });
      const messages: MessageRespond[] = result?.map((res: RespondFile, index: number) => ({
        id: index + 1,
        errorCode: res.ErrorCode,
        message: res.ErrorCode === 0 ? 'This file has been successfully uploaded.' : res.error_message,
        docName: res.Document,
      }));
      dispatch({
        type: 'statusUpload',
        value: `Analysis of files is finished. ${successCount ? `${successCount} files were analyzed successfully.` : ''} ${errorCount ? `${errorCount} files encountered an error.` : ''} `,
      });

      setErrorMessages(() => messages);
      onOpenModalRespond();
      setUploadedList([...list]);
      setFiles([]);
      setConcatFiles(false);
      setNotifNeeded(true);
      setNote('');
    } catch {}
    setIsLoading(false);
  };

  return {
    files,
    onDrop,
    onScreenshotClick,
    onTakeScreenshot,
    isModalOpen: openConfirmationModal.open,
    isModalRespondOpen: openRespondModal,
    onCloseModal,
    onCloseModalRespond,
    preview: link?.href || attachedFilesNames,
    onConfirmAttachment,
    onDeleteAddedFile,
    onChangeNote,
    onNotifNeeded,
    notifNeeded,
    onConcatFiles,
    onUploadFiles,
    uploadedList,
    isLoading,
    showSnackbar,
    handleCloseSnackbar,
    errorMessages,
    photos,
    clearPhotos,
    addPhotos,
  };
};

export default useAddDcoument;

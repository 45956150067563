import React, { useRef, useCallback, useState } from 'react';
import Webcam from 'react-webcam';
import CameraIcon from 'src/assets/images/Queries/camera.svg';
import TakePhotoIcon from 'src/assets/images/Queries/take-photo.svg';
import { dataURLtoFile } from '../../utils/imageUtil';
import { useAppContext } from 'src/core/contexts/_app.context';
import ChangeModeIcon from 'src/assets/images/Queries/refresh.svg';
import { useTranslation } from 'react-i18next';
import 'src/i18n';
const Camera: React.FC = () => {
  const { state, dispatch } = useAppContext();
  const { openCamera } = state || [];
  const { t } = useTranslation();
  const webcamRef = useRef<Webcam>(null);
  const [mode, setMode] = useState<string>('user');
  const [image, setImage] = useState<string | null>(null);
  const videoConstraints = {
    width: { ideal: 1280 },
    height: { ideal: 720 },
    facingMode: mode,
  };
  const openCamera_ = () => {
    dispatch({ type: 'openCamera', value: true });
  };
  const closeCamera_ = () => {
    dispatch({ type: 'openCamera', value: false });
  };
  const changeMode = () => {
    if (mode === 'user') {
      setMode('environment');
    } else {
      setMode('user');
    }
  };

  const capture = useCallback(() => {
    const imageSrc = webcamRef.current?.getScreenshot({ width: 1280, height: 720 });
    setImage(imageSrc || null);
    if (imageSrc) dispatch({ type: 'photos', value: imageSrc });
  }, [webcamRef, setImage]);

  return (
    <div className="h-200 relative w-3/12 p-1 bg-white rounded-xl border border-dashed border-Gray-CB flex-col justify-center items-center gap-1 inline-flex cursor-pointer max-sm:text-xs max-sm:p-4 max-sm:w-5/12">
      {openCamera && (
        <Webcam
          audio={false}
          ref={webcamRef}
          width={1280}
          height={720}
          screenshotFormat="image/jpeg"
          videoConstraints={videoConstraints}
          screenshotQuality={0.9}
        />
      )}
      {openCamera && (
        <button
          className="absolute bg-slate-300 opacity-55  bottom-6 w-10 h-10 rounded-full flex items-center justify-center p-1"
          onClick={capture}>
          <img src={TakePhotoIcon} className="rounded-full" alt="" />
        </button>
      )}
      {openCamera && (
        <button
          onClick={closeCamera_}
          className="absolute bg-slate-300 opacity-55  top-6 right-6 w-10 h-10 rounded-full flex items-center justify-center p-1">
          X
        </button>
      )}
      {openCamera && (
        <button
          onClick={changeMode}
          className="absolute bg-slate-300 opacity-55  top-6 left-6 w-10 h-10 rounded-full flex items-center justify-center p-1">
          <img src={ChangeModeIcon} alt="" />
        </button>
      )}

      {!openCamera && (
        <>
          <div
            onClick={openCamera_}
            className="w-10 h-10 p-2 bg-Orange-25 rounded-full justify-start items-center gap-2.5 inline-flex">
            <img src={CameraIcon} alt="" />
          </div>
          <p className="text-Gray-40 text-sm font-normal max-sm:text-xs">{t('takePhoto')}</p>
        </>
      )}
    </div>
    // <div>

    //   {/* {image && <img src={image} alt="Captured" />} */}
    // </div>
  );
};

export default Camera;

import { QueriesProvider } from 'src/core/contexts/queries.context';
import LeftColumn from './LeftColumn';
import RightColumn from './RightColumn';
import Header from 'src/modules/General/containers/Layout/Header';

const Queries: React.FC = () => {
  return (
    <QueriesProvider>
      <Header />
      <div className="w-sideBar flex h-full max-lg:h-calc-120 max-lg:w-full  bg-Gray-ED overflow-hidden">
        <div className="w-3/4 py-8 px-6 max-lg:w-full max-sm:p-2 ">
          <LeftColumn />
        </div>
        <div className="w-1/4 max-lg:hidden">
          <RightColumn />
        </div>
      </div>
    </QueriesProvider>
  );
};

export default Queries;

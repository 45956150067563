import { useTranslation } from 'react-i18next';
import bellIcon from 'src/assets/images/Notification/bell.png';
const NotificationEmpty = () => {
  const { t } = useTranslation();
  return (
    <div className="w-full h-full flex flex-col items-center justify-center p-6">
      <div className="p-6 rounded-lg bg-white flex flex-col items-center justify-center shadow-md">
        <img src={bellIcon} alt="Notification empty" className="w-36" />

        <p className="text-lg">{t('In this selected time period, you have no notifications')}.</p>
      </div>
    </div>
  );
};

export default NotificationEmpty;

import Icon from 'src/modules/General/components/Icon';
import { CatergoriesListProps, ItemProps } from './index.types';
import styles from './index.module.scss';
import cn from 'classnames';

const ChatsList = ({ list, onSelectItem, selectedItem, className = '' }: CatergoriesListProps) => {
  return (
    <div className={cn(styles['list'], className)}>
      {list.map((item: ItemProps) => (
        <div
          key={item.id}
          className={cn(styles['container'], selectedItem === item.id && styles['container--selected'])}
          onClick={() => onSelectItem?.(item.id)}>
          <div className={styles['header']}>
            <div className={styles['header__pin']}>
              {item.pin && <Icon name="pin" />}
              <span className={styles['header__title']}>{item.title}</span>
            </div>
            <span className={styles['header__time']}>{new Date(item.time).toLocaleDateString()}</span>
          </div>
          <div className={styles['content']}>{item.content}</div>
        </div>
      ))}
    </div>
  );
};

export default ChatsList;

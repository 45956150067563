import React from 'react';
import { CircularProgress, LinearProgress } from '@mui/material';
import { DotsProgressProps, LoadingProps } from './index.types';
import styles from './index.module.scss';
import cn from 'classnames';

const DotsProgress = ({ className, size, color }: DotsProgressProps) => {
  return (
    <div className={cn(styles['dots'], className)}>
      <div className={cn(styles['dot'], styles[`dot--${color}`])} style={{ width: size, height: size }} />
      <div className={cn(styles['dot'], styles[`dot--${color}`])} style={{ width: size, height: size }} />
      <div className={cn(styles['dot'], styles[`dot--${color}`])} style={{ width: size, height: size }} />
    </div>
  );
};

const Loading = ({ variant, className = '', loadingClassName = '', size, color }: LoadingProps) => {
  const renderedLoading: Record<LoadingProps['variant'], React.ReactNode> = {
    dots: <DotsProgress className={loadingClassName} size={size} color={color} />,
    circle: <CircularProgress className={loadingClassName} size={size} color={color} />,
    bar: <LinearProgress className={loadingClassName} color={color} />,
  };

  return <div className={cn(styles['container'], className)}>{renderedLoading[variant]}</div>;
};

export default Loading;

/* eslint-disable @typescript-eslint/ban-ts-comment */
import Icon from 'src/modules/General/components/Icon';
import { FoldersProps } from './index.types';
import styles from './index.module.scss';

const Folders = ({ items, onUploadFolders }: FoldersProps) => {
  return (
    <div className={styles['container']}>
      <div className={styles['container__title']}>
        <h5>Folders</h5>
        <label htmlFor="folder" className={styles['container__add']}>
          <Icon name="add" />
        </label>
        {/* @ts-expect-error */}
        <input id="folder" type="file" webkitdirectory="" onChange={onUploadFolders} />
      </div>
      <div className={styles['folders']}>
        {items.map(item => (
          <div key={item.id} className={styles['folder']}>
            <Icon name="folder" />
            <span className={styles['folder__name']}>{item.name}</span>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Folders;

import variables from 'src/styles/constants/_exports.module.scss';

export const ToggleButtonGroupCss = {
  '&.MuiToggleButtonGroup-root': {
    backgroundColor: variables.color_light_blue_2,
    width: '100%',
    padding: '0.25rem',
    borderRadius: '0.5rem',
    '.MuiToggleButtonGroup-grouped': {
      width: '100%',
      border: 0,
      borderRadius: '0.5rem',
      alignItems: 'center',
      '&:hover': {
        borderRadius: '0.5rem',
        backgroundColor: variables.color_primary_opacity,
      },
      '&:not(:last-of-type), &:not(:first-of-type) ': {
        borderRadius: '0.5rem',
      },
    },
  },
};


import { useEffect, useState } from 'react';
import { useAppContext } from 'src/core/contexts/_app.context';
import { SearchDataType, SearchMethod, downloadFile, search } from 'src/modules/Queries/api';
import { useLocation } from 'react-router-dom';
import { Dayjs } from 'dayjs';
import { fetchTags, Tag } from 'src/modules/General/api';
type UploadedFilesType = { id: number; name: string; files?: File | string; type: 'screenshot' | 'file' }[];
type LoadingType = { name: 'question' | 'upload'; loading: boolean };
export interface Item {
  label: string;
  value: string;
}

const useDocumentRetrieval = () => {
  const { hash } = useLocation();
  const [reset, setReset] = useState<boolean>(false);
  const { state, dispatch } = useAppContext();
  const { screenshotMode } = state || {};
  const [searchValue, setSearchValue] = useState('');
  const [methodSearch, setMethodSearch] = useState('');
  const [searchResultOf, setSearchResultOf] = useState('');
  const [fromDate, setFromDate] = useState<Dayjs | null>(null);
  const [toDate, setToDate] = useState<Dayjs | null>(null);
  const [itemSelected, setItemSelected] = useState<Item[]>([]);
  const [tagSelected, setTagSelected] = useState<string[]>([]);
  const [files, setFiles] = useState<UploadedFilesType>([]);
  const [items, setItems] = useState<Item[]>([]);
  const [link, setLink] = useState<HTMLAnchorElement | null>(null);
  const [attachedFiles, setAttachedFiles] = useState<File[]>([]);
  const [openConfirmationModal, setOpenConfirmationModal] = useState<{ type: 'screenshot' | 'file'; open: boolean }>({
    type: 'screenshot',
    open: false,
  });
  const [loadingDaownload, setLoadingDownload] = useState(false)
  const [fromDateSelected, setFromDateSelected] = useState<string>('');
  const [toDateSelected, setToDateSelected] = useState<string>('');
  const [tagsSelected, setTagsSelected] = useState<string[]>([]);
  const [pdfData, setPdfData] = useState<Uint8Array>(new Uint8Array());
  const [relatedDocuments, setRelatedDocuments] = useState([]);
  const [showMessage, setShowMessage] = useState('');
  const [downloadLink, setDownloadLink] = useState('');
  const [isLoading, setIsLoading] = useState<LoadingType>({ name: 'question', loading: false });
  const lastFileId = files[files.length - 1]?.id || 0;
  const screenshotTypeList = files.filter((file) => file.type === 'screenshot');
  const lastScreenshotNumber = screenshotTypeList.length || 0;
  const attachedFilesNames = attachedFiles.map((file) => file.name);
  const isLoadingUpload = isLoading.name === 'upload' && isLoading.loading;
  const isLoadingQuestion = isLoading.name === 'question' && isLoading.loading;
  useEffect(() => {
    setReset(true);
    getTags();
  }, [hash]);

  const changeTag = (value: Item[]) => {
    setItemSelected(value);
    setTagSelected(value.map((item) => item.value));
  };

  const onCloseModal = () => {
    
    setPdfData(new Uint8Array());
    setOpenConfirmationModal({ ...openConfirmationModal, open: false });
  };

  const onSearchInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchValue(e.target.value);
  };
  const changeMethodSearch = (method: string) => {
  
    setMethodSearch(method);
  };

  const getTags = async () => {
    const data = await fetchTags();
    if (typeof data !== 'string') {
      const tags: Item[] = (await data.tags_list?.length)
        ? data.tags_list.map((tag: Tag) => ({
            label: tag.tag_name,
            value: tag.tag_name,
          }))
        : [];

      setItems(tags);
    }
  };

  const onSendQuestion = async () => {
    if (searchValue.trim()) {
      const formData: SearchDataType = { question: searchValue, method: SearchMethod.DocumentRetrieval };
      if (methodSearch) {
        formData.search_source = methodSearch;
      } else {
        formData.search_source = 'all';
      }
      if (fromDate && toDate) {
        formData.start_date = fromDate.format('YYYY-MM-DD');
        formData.end_date = toDate.format('YYYY-MM-DD');
        setFromDateSelected(fromDate.format('YYYY-MM-DD'));
        setToDateSelected(toDate.format('YYYY-MM-DD'));
      }
      if (tagSelected.length > 0) {
        formData.tag = tagSelected;
        setTagsSelected(tagSelected);
      }

      setIsLoading({ name: 'question', loading: true });
      try {
        const { url_to_download, files_names } = await search(formData);
        setShowMessage('');
        const results =
          !!files_names?.length &&
          files_names.map((file: string, index: number) => ({ name: file, link: url_to_download[index] }));
        results?.length
          ? setRelatedDocuments(results)
          : setShowMessage('We could not find any data for your search. Please change your search selections.');
        setSearchResultOf(searchValue);
        setSearchValue('');
        setItemSelected([]);
        setFromDate(null);
        setToDate(null);
      } catch {}
      setIsLoading({ name: 'question', loading: false });
    }
  };

  const onInputFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const files = e.target.files || [];
    if (files?.length) {
      setAttachedFiles(Object.values(files));
      setOpenConfirmationModal({ type: 'file', open: true });
    }
  };

  const onScreenshotClick = () => dispatch({ type: 'screenshotMode', value: !screenshotMode });

  const onTakeScreenshot = (newLink: HTMLAnchorElement) => {
    {
      setLink(newLink);
      setOpenConfirmationModal({ type: 'screenshot', open: true });
    }
  };
  const onPreviewDialog = (link: string) => {
    {
      setOpenConfirmationModal({ type: 'file', open: true });
      downloadDataFile(link);
      setDownloadLink(link)
    }
  };
  const downloadDataFile = (downloadLink: string) => {
    setLoadingDownload(true)
    downloadFile(downloadLink).then((result) => {
      
      if (typeof result === 'object') {
        setLoadingDownload(false)
        const reader = new FileReader();
        reader.onload = () => {
          if (reader.result instanceof ArrayBuffer) {
            const uint8Array = new Uint8Array(reader.result);
            if (uint8Array.length === 0) {
              return;
            }
            setPdfData(new Uint8Array(uint8Array));
          }
        };
        reader.readAsArrayBuffer(result);
      }
      setLoadingDownload(false)
    });
  };
  const onRetry = () => {
    if (downloadLink) {
      downloadDataFile(downloadLink);
    }
  };

  const onConfirmAttachment = () => {
    {
      if (openConfirmationModal.type === 'screenshot') {
        link?.download &&
          setFiles([...files, { id: lastFileId + 1, name: link?.download, files: link?.href, type: 'screenshot' }]);
      } else {
        let temp: UploadedFilesType = [];
        for (let i = 0; i < attachedFiles.length; i++) {
          temp = [
            ...temp,
            { id: lastFileId + 1 + i, name: attachedFiles[i].name, files: attachedFiles[i], type: 'file' },
          ];
          setFiles([...files, ...temp]);
        }
      }
      onCloseModal();
      setLink(null);
      setAttachedFiles([]);
    }
  };

  const onDeleteAddedFile = (fileId: number) => {
    const removedFiles = files.filter((file) => file.id !== fileId);
    setFiles(removedFiles);
  };



 

  return {
    files,
    searchValue,
    onSearchInputChange,
    onSendQuestion,
    onInputFileChange,
    onScreenshotClick,
    screenshotMode,
    lastScreenshotNumber,
    onTakeScreenshot,
    preview: link?.href || attachedFilesNames,
    onConfirmAttachment,
    onDeleteAddedFile, 
    relatedDocuments,
    showMessage,
    isLoadingQuestion,
    isLoadingUpload,
    isModalOpen: openConfirmationModal.open,
    onCloseModal,
    pdfData,
    onPreviewDialog,
    searchResultOf,
    changeTag,
    itemSelected,
    changeMethodSearch,
    methodSearch,
    reset,
    fromDate,
    setFromDate,
    toDate,
    setToDate,
    items,
    fromDateSelected,
    toDateSelected,
    tagsSelected,
    loadingDaownload,
    onRetry
  };
};

export default useDocumentRetrieval;

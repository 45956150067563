import React, { useState } from 'react';
import { FolderType } from 'src/modules/Files/containers/Folders/index.types';
import { FileTableItem } from 'src/modules/Files/containers/FilesList/index.types';

const useFilesRightColumn = () => {
  const [folders, setFolders] = useState<FolderType[]>([]);
  const [files, setFiles] = useState<FileTableItem[]>([]);

  const onUploadFolders = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { files } = e.target;
    if (files?.length) {
      setFolders([
        ...folders,
        {
          id: folders.length + 1,
          name: files[0].webkitRelativePath.split('/')[0],
          files: Object.values(files as FileList),
        },
      ]);
    }
  };

  const onUploadFiles = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { files: currentFiles } = e.target;
    if (currentFiles?.length) {
      setFiles([
        ...files,
        {
          id: files.length + 1,
          name: currentFiles[0].name,
          shareDate: new Date(),
        },
      ]);
    }
  };

  return {
    folderItems: folders,
    onUploadFolders,
    fileItems: files,
    onUploadFiles,
  };
};

export default useFilesRightColumn;

import request from 'src/core/api/axios';
import { RecentDataType } from 'src/modules/Queries/api';

export async function getRecentFiles(formData: RecentDataType): Promise<any> {
  try {
    const { data } = await request.post<any>('/recent_files', formData);
    
    return data;
  } catch (error) {}
}

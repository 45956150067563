import { Navigate, RouteObject, RouterProvider, createBrowserRouter } from 'react-router-dom';
import Layout from 'src/modules/General/containers/Layout';
import DefaultErrorPage from 'src/pages/Errors';
import Queries from 'src/pages/Queries';
import Emails from 'src/pages/Emails';
import Files from 'src/pages/Files';
import Login from 'src/pages/Login';
import Register from 'src/pages/Register';
import RequireAuth from 'src/modules/General/components/RequireAuth';
import ForgetPassword from 'src/pages/ForgetPassword';
import ResetPassword from 'src/pages/ResetPassword';
import EnterpriseSearch from 'src/pages/EnterpriseSearch';
import Notifications from 'src/pages/Notifications';
import Notes from 'src/pages/Notes';
import Privacy from 'src/pages/Privacy';
import Terms from 'src/pages/Terms';
import Doucuments from 'src/pages/Documents';
import Signup from 'src/pages/Signup';
import Instruction from 'src/pages/Instruction';

export const Router = () => {
  const accessToken = localStorage.getItem('access_token');
  const blueprint: RouteObject[] = [
    {
      element: <Layout />,
      errorElement: <DefaultErrorPage />,
      children: [
        {
          index: true,
          element: accessToken !== null ? <Navigate to="/my-queries" replace /> : <Navigate to="/user-login" replace />,
        },
        {
          path: '/my-queries',
          element: (
            <RequireAuth>
              <Queries />
            </RequireAuth>
          ),
          children: [
            {
              path: ':id',
            },
          ],
        },
        {
          path: '/my-emails',
          element: (
            <RequireAuth>
              <Emails />
            </RequireAuth>
          ),
          children: [
            {
              path: ':id',
            },
          ],
        },
        {
          path: '/my-files',
          element: (
            <RequireAuth>
              <Files />
            </RequireAuth>
          ),
          children: [
            {
              path: ':id',
            },
          ],
        },
        {
          path: '/auto-Gen',
          element: (
            <RequireAuth>
              <EnterpriseSearch />
            </RequireAuth>
          ),
        },
        {
          path: '/my-notifications',
          element: (
            <RequireAuth>
              <Notifications />
            </RequireAuth>
          ),
        },
        {
          path: '/note',
          element: (
            <RequireAuth>
              <Notes />
            </RequireAuth>
          ),
        },
        {
          path: '/my-documents',
          element: (
            <RequireAuth>
              <Doucuments />
            </RequireAuth>
          ),
        },
        {
          path: '/user-login',
          element: <Login />,
        },
        {
          path: '/user-sign-up',
          element: <Signup />,
        },
        {
          path: '/user-forget-password',
          element: <ForgetPassword />,
        },
        {
          path: '/user-reset-password',
          element: <ResetPassword />,
        },
        {
          path: '/user-register',
          element: <Register />,
        },
        {
          path: '/privacy-policy',
          element: <Privacy />,
        },
        {
          path: '/instuction-add-email',
          element: <Instruction />,
        },
        {
          path: '/terms',
          element: <Terms />,
        },
      ],
    },
  ];

  const routes = createBrowserRouter(blueprint);

  return <RouterProvider router={routes} />;
};

import { useEffect, useState } from 'react';

const useClickToSelect = () => {
  const [startX, setStartX] = useState(0);
  const [startY, setStartY] = useState(0);
  const [endX, setEndX] = useState(-1);
  const [endY, setEndY] = useState(-1);
  const [isSelecting, setIsSelecting] = useState(false);

  const handleMouseDown = (e: MouseEvent) => {
    setStartX(e.clientX);
    setStartY(e.clientY);
    setIsSelecting(true);
  };

  const handleMouseMove = (e: MouseEvent) => {
    if (isSelecting) {
      setEndX(e.clientX);
      setEndY(e.clientY);
    }
  };

  const handleMouseUp = (e: MouseEvent) => {
    setEndX(e.clientX);
    setEndY(e.clientY);
    setIsSelecting(false);
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleMouseDown);
    document.addEventListener('mousemove', handleMouseMove);
    document.addEventListener('mouseup', handleMouseUp);

    return () => {
      document.removeEventListener('mousedown', handleMouseDown);
      document.removeEventListener('mousemove', handleMouseMove);
      document.removeEventListener('mouseup', handleMouseUp);
    };
  }, [isSelecting]);

  return { startX, startY, endX, endY, isSelecting };
};

export default useClickToSelect;
